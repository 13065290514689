import React, { Component } from "react";
import "./style.css";

class CoustCardItem extends Component {
  constructor(props) {
    super();

    var widthDimension;
    var borderColor;
    var backgroundColor;

    if (props.bigger === "true") {
      // Client Section

      widthDimension = "380px";
    } else {
      // Modal Card
      widthDimension = "240px";
    }

    if (props.type === "1") {
      // Custo Cativo
      borderColor = "#008D00";
      backgroundColor = "#f5f5f5";
    } else if (props.type === "2") {
      // Custo Livre
      borderColor = "#0067CC";
      backgroundColor = "#f5f5f5";
    } else if (props.type === "3") {
      // Custo Livre
      borderColor = "#B22";
      backgroundColor = "#f5f5f5";
    } else {
      // Economia Acumulada
      borderColor = "#888";
      backgroundColor = "#f5f5f5";
    }

    this.state = {
      widthDimension,
      borderColor,
      backgroundColor,
      format: false,
    };

    this.setState({ format: props.format });
  }

  removeSimbolReal = (param) => {
    var my_number = "";

    for (let index = 0; index < param.length; index++) {
      if (param[index] !== "R" && param[index] !== "$") {
        my_number = my_number + param[index];
      }
    }

    return my_number;
  };

  render() {
    const divStyle = {
      width: this.state.widthDimension,
      borderColor: this.state.borderColor,
      backgroundColor: this.state.backgroundColor,
    };

    const color = this.props.value < 0 ? "#B22" : "#008D00";

    return (
      <div className="container-CoustCardItemModal" style={divStyle}>
        <p className="coustCardItem-titleModal">{this.props.title}</p>

        {this.props.type === "1" || this.props.type === "3" ? (
          <div className="coustCardItem-contentModal">
            R$
            <p className="coutCardItem-valueModal" style={{ color: color }}>
              {this.removeSimbolReal(
                parseFloat(this.props.value).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              )}
            </p>
          </div>
        ) : (
          <div className="coustCardItem-contentModal">
            R$
            <p className="coutCardItem-valueModal">
              {this.removeSimbolReal(
                parseFloat(this.props.value).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
              )}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default CoustCardItem;
