import React from "react";
import foto1 from "../../Assets/fortlev-solar-usina-01.jpg";
import foto2 from "../../Assets/fortlev-solar-usina-02.jpg";
import foto3 from "../../Assets/fortlev-solar-usina-03.jpg";
import foto4 from "../../Assets/fortlev-solar-usina-04.png";
import foto5 from "../../Assets/fortlev-solar-usina-05.png";
import foto6 from "../../Assets/fortlev-solar-usina-06.png";
import foto7 from "../../Assets/fortlev-solar-usina-07.png";
import foto8 from "../../Assets/fortlev-solar-usina-08.png";
import foto9 from "../../Assets/fortlev-solar-usina-09.jpg";
import foto10 from "../../Assets/fortlev-solar-usina-10.png";
import { Carousel } from "react-bootstrap";

export default function CasosReais() {
  return (
    <div
      className="container"
      style={{
        paddingTop: "80px",
      }}
      id="casosReaisId"
    >
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-1"
            src={foto1}
            alt="Primeiro slide"
          />
          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Serra, ES - 2,34 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-2"
            src={foto2}
            alt="Segundo slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Serra, ES - 2,34 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto3}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Serra, ES - 2,34 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto4}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">
              Prudente de Morais, MG - 6,5 MWp.
            </h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-2"
            src={foto5}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Serra, ES - 3,4 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto7}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Serra, ES - 3,4 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto6}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Jequitibá, MG - 6,5 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto8}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">CD Fortlev Solar, ES - 380kWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto9}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Montes Claros, MG - 3,2 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block img-carousel-fortlev-3"
            src={foto10}
            alt="Terceiro slide"
          />

          <Carousel.Caption
            style={{
              textShadow: "2px 2px 10px rgba(0,0,0,1)",
            }}
          >
            <h3 className="caption-fortlev">Araquari, SC - 2,38 MWp.</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
