import React from "react";
import styles from "./style.module.css";
import Image from "../../Assets/beSelfProductorImg.png";
import ArrowSelfProduct from "../../Assets/arrowSelfProduction.svg";
export default function Revolution() {
  const questions = [
    {
      title: "Por que devo me tornar um autoprodutor de energia?",
      text:
        "Tornar-se um autoprodutor de energia permite que você tenha maior independência energética e reduza seus custos com eletricidade. Além disso, você contribui para um futuro mais sustentável ao gerar energia limpa e renovável.",
    },
    {
      title: "Como funciona o processo para se tornar um autoprodutor?",
      text:
        "O processo envolve a instalação de um sistema de geração de energia, como painéis solares, em sua propriedade. Assim, você começa a produzir sua própria energia e a economizar.",
    },
  ];
  return (
    <section className={styles.section} id="selfProductor">
      <div className={styles.content}>
        <div className={styles.img}>
          <img src={Image} alt="imagem da equipe merx e usinas" />
        </div>
        <div className={styles.texts}>
          <p className={styles.revolution}>A revolução já aconteceu!</p>
          <h2>Seja autoprodutor</h2>
          <h3>Chegou a hora de ter o controle da sua própria energia.</h3>
          <div className={styles.questions}>
            {questions.map((question) => (
              <div className={styles.question}>
                <h4>{question.title}</h4>
                <p>{question.text}</p>
              </div>
            ))}
          </div>
          <div className={styles.question}>
            <h4 className={styles.lastQuestion}>
              Quais são os principais benefícios da autoprodução?
            </h4>
            <img
              src={ArrowSelfProduct}
              alt="arrow to down"
              className={styles.arrow}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
