import React from "react";
import AnaliseTable from "../AnaliseTable/index";
import ContBalance from "../ContBalance/index";
import MonthlyCharges from "../MonthlyCharges";

export default function ConsumeLine(props) {
  // verifica tamanho da tela
  function getFlex() {
    if (window.innerWidth < 750) {
      return "column";
    } else {
      return "row";
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: getFlex(),
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <AnaliseTable info={props.info} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "10px",
        }}
      >
        <ContBalance info={props.info} />
        <MonthlyCharges info={props.info} />
      </div>
    </div>
  );
}
