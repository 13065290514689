import React, { useState } from "react"; // Importe o React corretamente
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeletePartnerDocModal({ show, onHide, setShow, error, setError }) {
  const handleClick = () => {
    setError(false);
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              {!error ? "Documento deletado!" : "Erro ao deletar documento!"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ boxShadow: "0px 0px 0px", padding: "8px" }}>
          {!error
            ? "O documento foi deletado com sucesso!"
            : "Houve algum erro ao deletar o documento, por favor, tente novamente!"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={!error ? "primary" : "danger"} onClick={handleClick}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeletePartnerDocModal;
