import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";

export function ComparativoCativoLivre({ monthData }: { monthData: any[] }) {
  const months: any[] = [];
  const costCativo: any[] = [];
  const costLivre: any[] = [];
  const economy: any[] = [];

  monthData
    ?.filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((value) => {
      months.push(`${shortMesesExt[value.month - 1]} ${value.year}`);
      costCativo.push(value.costCativo);
      costLivre.push(value.costLivre);
      economy.push(+(value.costCativo - value.costLivre).toFixed(2));
    });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    // chart: {
    //   type: "column",
    // },

    title: {
      text: "",
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      categories: months,
      title: { text: "" },
    },

    yAxis: {
      labels: {
        format: "R$ {value:,.0f}",
      },
      gridLineWidth: 1,
      title: {
        text: "",
      },
    },

    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}</b><br/>",
    },

    plotOptions: {
      column: {
        pointWidth: 40, // Ajuste o tamanho das barras aqui
      },
    },

    series: [
      {
        name: "Custo Cativo",
        color: "red",
        data: costCativo,
        tooltip: {
          valuePrefix: "R$ ",
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          symbol: "circle",
        },
      },
      {
        name: "Custo Livre",
        color: "blue",
        data: costLivre,
        tooltip: {
          valuePrefix: "R$ ",
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          symbol: "square",
        },
      },
    ],
  };

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}
