import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";

export function AnaliseConsumo({ monthData }: { monthData: any[] }) {
  const dataConsume = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.relativeConsume;
      return [x, y];
    });

  const dataContractedEnergy = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.contractedEnergy;
      return [x, y];
    });

  const dataTakeMin = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.takeMin;
      return [x, y];
    });

  const dataTakeMax = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.takeMax;
      return [x, y];
    });

  const dataBallast = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = +(e.volumeToInvoice - e.relativeConsume).toFixed(2);
      return [x, y];
    });

  const months = dataConsume.map((e) => {
    return e[0];
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: {
      type: "spline",
    },

    title: {
      text: "",
    },

    legend: {
      align: "center",
      verticalAlign: "top",
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      categories: months,
      crosshair: true,
    },

    yAxis: {
      title: {
        text: "Energia (MWh)",
      },
      labels: {
        format: "{value} MWh",
      },
    },

    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}</b><br/>",
    },

    plotOptions: {
      column: {
        pointWidth: 40, // Ajuste o tamanho das barras aqui
      },
    },

    series: [
      {
        name: "Energia consumida",
        type: "column",
        zIndex: -1,
        color: "gray",
        borderWidth: 0,
        data: dataConsume,
        tooltip: {
          valueSuffix: " MWh",
        },
      },
      {
        name: "Contrato",
        color: "green",
        zIndex: 1,
        data: dataContractedEnergy,
        tooltip: {
          valueSuffix: " MWh",
        },
        lineWidth: 2,
        marker: {
          radius: 4,
          symbol: "circle",
        },
      },
      {
        name: "Take max",
        color: "blue",
        lineWidth: 2,
        marker: {
          radius: 4,
          symbol: "square",
        },
        dashStyle: "dash",
        data: dataTakeMax,
        tooltip: {
          valueSuffix: " MWh",
        },
      },
      {
        name: "Take min",
        color: "blue",
        lineWidth: 2,
        marker: {
          radius: 4,
          symbol: "square",
        },
        dashStyle: "dash",
        data: dataTakeMin,
        tooltip: {
          valueSuffix: " MWh",
        },
      },
    ],
  };

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}
