import React, { useEffect, useState } from "react";
import ReactTypingEffect from "react-typing-effect";
import styles from "./style.module.css";
import NotebookImage from "../../Assets/mypc.png";
import CellPhoneImage from "../../Assets/mycel.png";
import Arrow from "../../Assets/arrowButton.svg";
import LineCotacoes from "../../Assets/lineCotacoes.svg";
import IconWhats from "../../Assets/wppImage.svg";
export default function Compare() {
  const typesOfAssociates = ["PARCEIRO", "CLIENTE", "INVESTIDOR"];
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [isListOpen, setIsListOpen] = useState(false);
  return (
    <section className={styles.totalInfo}>
      <section className={styles.section}>
        <div className={styles.textInfos}>
          <h2>
            SEJA UM{" "}
            <ReactTypingEffect
              text={typesOfAssociates}
              eraseDelay="2000"
              speed="100"
              infinite
            />
          </h2>
          <h2>NO MERCADO LIVRE DE ENERGIA</h2>
          <p>
            Nós criamos uma plataforma para lhe dar todo o controle sobre sua
            performance e rentabilidade no mercado livre, entre em contato
            conosco e nós cuidamos de todo o resto.
          </p>
          <span>
            <div className={styles.cotacoes}>
              <img
                src={LineCotacoes}
                alt="Linha simples"
                className={!isListOpen ? styles.inactive : styles.Line}
              />
              <button onClick={() => setIsListOpen(!isListOpen)}>
                <p> FAÇA SUAS COTAÇÕES </p>
                <img
                  src={Arrow}
                  alt="button to open menu"
                  className={!isListOpen ? styles.arrow : styles.opened}
                />
              </button>
            </div>
            <ul className={`${isListOpen ? styles.list : styles.inactive} `}>
              <a href="https://wa.me/558182136133?text=QUERO%20SER%20INVESTIDOR">
                <li>QUERO SER INVESTIDOR</li>
              </a>

              <a href="https://wa.me/558182136133?text=QUERO%20SER%20UM%20CLIENTE">
                <li>QUERO SER UM CLIENTE</li>
              </a>

              <a href="https://wa.me/558182136133?text=QUERO%20SER%20PARCEIRO">
                <li>QUERO SER PARCEIRO</li>
              </a>
            </ul>
          </span>
        </div>
        <div className={styles.notebookImage}>
          <div className={styles.imagesSection}>
            <img
              src={NotebookImage}
              alt="notebookImage"
              className={styles.notebookImg}
            />
            <img
              src={CellPhoneImage}
              alt="CellPhoneImage"
              className={styles.cellPhoneImage}
            />
          </div>
        </div>
      </section>
    </section>
  );
}
