import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt, mesesExt } from "../../utils/utilityFunctions";

export function PerfilConsumo({ monthData }: { monthData: any[] | null }) {
  const months: any[] = [];
  const consumeProfileOP: any[] = [];
  const consumeProfileP: any[] = [];

  monthData
    ?.filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((value) => {
      months.push(`${shortMesesExt[value.month - 1]} ${value.year}`);
      consumeProfileOP.push(value.consumedOffPeak);
      consumeProfileP.push(value.consumedPeak);
    });

  const maxConsumeProfileOP = Math.max.apply(null, consumeProfileOP);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: { type: "line" },
    title: {
      text: "",
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      shared: true,
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    xAxis: {
      categories: months,
      title: { text: "" },
    },

    yAxis: {
      labels: {
        format: "{value:,.0f} MWh",
      },
      gridLineWidth: 0.5,
      max: maxConsumeProfileOP + 30,
      zIndex: -100,
      gridLineColor: "#838f7f",
      title: {
        text: "",
      },
    },

    series: [
      {
        name: "Consumo fora ponta",
        color: "blue",
        data: consumeProfileOP,
        tooltip: {
          valueSuffix: " MWh",
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          symbol: "circle",
        },
        pointPlacement: 0,
        dataLabels: {
          enabled: true,
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.3f}", // Exibe o valor do ponto de dados
          y: -10,
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
      {
        name: "Consumo ponta",
        color: "#f58700",
        data: consumeProfileP,
        tooltip: {
          valueSuffix: " MWh",
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          symbol: "square",
        },
        dataLabels: {
          enabled: true,
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.3f}", // Exibe o valor do ponto de dados
          y: -10,
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
    ],
  };

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}
