import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState,useEffect } from "react";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";

export function DemandaForaPontaMeasuringPoint({
  monthData,
}: {
  monthData: any[];
}) {
  const contractedOffPeakDemand = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.contractedOffPeakDemand;
      return [x, y];
    });

  const consumedOffPeakDemand = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.consumedOffPeakDemand;
      return [x, y];
    });

  const months = contractedOffPeakDemand.map((e) => {
    return e[0];
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });


  const [pointWidth, setPointWidth] = useState(getPointWidth());
  const [barLegend, setBarLegend] = useState((getBarLegend()));
  function getPointWidth() {
    if(window.innerWidth >= 950){
      return 40;
    }
    else if(window.innerWidth <= 950 && window.innerWidth > 805 ){
      return 30;
    }
    else if (window.innerWidth <= 805 && window.innerWidth > 640){
      return 20;
    }
    else if (window.innerWidth <= 640 && window.innerWidth > 510 ){
      return 15;
    }
    else if (window.innerWidth <= 510 && window.innerWidth > 400){
      return 10;
    }
    else{
      return 5;
    }
  }

  function getBarLegend() {
    return window.innerWidth < 920 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setPointWidth(getPointWidth());
      setBarLegend(getBarLegend());
    };

    // Adiciona o ouvinte de evento de redimensionamento
    window.addEventListener('resize', handleResize);

    // Executa a função imediatamente para definir o valor inicial
    handleResize();

    // Remove o ouvinte de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 



  const chartOptions = {
    chart: { type: "line" },
    title: {
      text: "",
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    xAxis: {
      // title: {
      //   text: "Mês",
      // },
      categories: months,
    },

    // yAxis: {
    //   title: {
    //     text: "Demanda (kW)",
    //   },
    //   labels: {
    //     format: "{value} kW",
    //   },
    // },
    yAxis: {
      labels: {
        format: "{value:,.0f} kW",
      },
      gridLineWidth: 0.5,
      gridLineColor: "#838f7f",
      zIndex: -100,
      title: {
        text: "",
      },
    },

    plotOptions: {
      column: {
        pointWidth: getPointWidth(), // Ajuste este valor conforme necessário
      },
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}</b><br/>",
    },

    series: [
      {
        name: "Demanda contratada fora ponta",
        data: contractedOffPeakDemand,
        type: "column",
        color: "#007bff",
        zones: [
          {
            color: "#007bff",
          },
        ],
        tooltip: {
          valueSuffix: " kW",
        },
        borderWidth: 0,
        pointPlacement: -0.1,
        dataLabels: {
          enabled: getBarLegend(),
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.0f}", // Exibe o valor do ponto de dados
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
      {
        name: "Demanda utilizada fora ponta",
        data: consumedOffPeakDemand,
        type: "column",
        color: "#EEAD2D",
        zones: [
          {
            color: "#EEAD2D",
          },
        ],
        borderWidth: 0,
        tooltip: {
          valueSuffix: " kW",
        },
        pointPlacement: 0,
        dataLabels: {
          enabled: getBarLegend(),
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.0f}", // Exibe o valor do ponto de dados
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
    ],
  };

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}
