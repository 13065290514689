import React from "react";
import logo from "../../Assets/fortlev_solar_vetor_3.svg";
import whatsapp from "../../Assets/whatsapp.svg";
import envelope from "../../Assets/envelope-at.svg";
import "./style.css";

class Footer extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="footer-container-fortlev" id="emailId">
        <div className="container">
          <div className="container-modal">
            <div className="text-center loginTitle row-footer-fortlev">
              <div className="column-footer-fortlev">
                <img
                  alt="logo"
                  src={logo}
                  className="logo-login"
                  style={{ width: "200px" }}
                />
              </div>

              <div className="column-footer-fortlev">
                <div
                  className="text-center loginTitle"
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    color: "#FFFFFF",
                    padding: "8px 0",
                  }}
                >
                  Fale conosco!
                </div>
                <div
                  className="text-left loginTitle"
                  style={{
                    width: "100%",
                    fontSize: "18px",
                    color: "#FFFFFF",
                    padding: "0 40px",
                  }}
                >
                  <img
                    src={envelope}
                    className="icon-footer-fortlev"
                    alt="email"
                  />
                  felipe.ferraz@fortlevsolar.com.br
                </div>
                <div
                  className="text-left loginTitle"
                  style={{
                    width: "100%",
                    fontSize: "18px",
                    color: "#FFFFFF",
                    padding: "0 40px",
                  }}
                >
                  <a
                    className="footer-text"
                    target="blank"
                    href="https://wa.me/5527997503389"
                    style={{ color: "white" }}
                  >
                    <img
                      src={whatsapp}
                      alt="zap"
                      className="icon-footer-fortlev"
                    />
                    +55 27 997503389
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
