import React, { useState, useEffect } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { IMonthlyData } from "../../pages/ClientDashboard/interfaces";
import {
  ContentCard,
  CardTitle,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";

function EconomiaMensalMeasuringPoint({ monthData }: any) {
  const economy: any[] = [];
  const [pointWidth, setPointWidth] = useState(getPointWidth());
  const [barLegend, setBarLegend] = useState((getBarLegend()));
  const data = monthData
    .filter(
      (item: any) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e: any) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = +(e.costCativo - e.costLivre).toFixed(2);
      economy.push(y);
      return [x, y];
    });
  const maxEconomy = Math.max.apply(null, economy);
  const months = data.map((e: any[]) => {
    return e[0];
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });


  


  const chartOptions = {
    chart: { type: "line", marginTop: 50 },

    title: { text: "" },

    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      enabled:false,
      categories: months,
    },

    // yAxis: {
    //   title: {
    //     text: "Economia (R$)",
    //   },
    //   labels: {
    //     format: "R$ {value}",
    //   },
    // },
    yAxis: {
      labels: {
        format: "R$ {value:,.0f}",
      },
      gridLineWidth: 1,
      // max: maxEconomy + 5000,
    },

    plotOptions: {
      column: {
        pointWidth:  getPointWidth(), // Ajuste o tamanho das barras aqui
      },
    },

    tooltip: {
      shared: true,
    },

    // tooltip: {
    //   formatter: function (
    //     this: Highcharts.TooltipFormatterContextObject
    //   ): string {
    //     return `<b>${this.x}</b><br>${this.y.toLocaleString("pt-BR", {
    //       style: "currency",
    //       currency: "BRL",
    //     })}`;
    //   },
    // },

    series: [
      // {
      //   data: data,
      //   zones: [
      //     {
      //       value: 0,
      //       color: "#f91010",
      //     },
      //     {
      //       color: "#1fa824",
      //     },
      //   ],
      // },
      {
        name: "Economia",
        type: "column",
        zIndex: -1,
        color: "green",
        data: economy,
        borderWidth: 0,
        tooltip: {
          valuePrefix: "R$ ",
        },
        dataLabels: {
          enabled: getBarLegend(),
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.2f}", // Exibe o valor do ponto de dados
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
    ],



    
  };


  function getPointWidth() {
    if(window.innerWidth >= 720){
      return 40;
    }
    else if(window.innerWidth <= 720 && window.innerWidth > 580  ){
      return 30;
    }
    else if (window.innerWidth <= 580 && window.innerWidth > 510){
      return 24;
    }
    else if (window.innerWidth <= 510 && window.innerWidth > 416){
      return 15;
    }
    else{
      return 7;
    }
  }

  function getBarLegend() {
    return window.innerWidth < 920 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setPointWidth(getPointWidth());
      setBarLegend(getBarLegend());
    };

    // Adiciona o ouvinte de evento de redimensionamento
    window.addEventListener('resize', handleResize);

    // Executa a função imediatamente para definir o valor inicial
    handleResize();

    // Remove o ouvinte de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}

export default EconomiaMensalMeasuringPoint;
