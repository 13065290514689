import React, { Component } from "react";
import "./style.css";
import BlackLogo from "../../Assets/blackLogo.png";
import { Form, Button } from "react-bootstrap";

import { withRouter } from "react-router-dom";

import faradayApi from "../../Services/faradayApi";

class App extends Component {
  constructor() {
    super();

    this.state = {
      companys: [],
      months: [],
      CNPJ: "03.906.649/0001-64",
      Mes: "Janeiro",
      Ano: "2017",
      file: "",
    };
  }

  async componentDidMount() {
    const response = await faradayApi.get("company");

    const Allcompany = response.data;

    var companys = [];

    for (let i = 0; i < Allcompany.length; i++) {
      companys.push({
        Nome: Allcompany[i].Nome,
        CNPJ: Allcompany[i].CNPJ,
        _id: Allcompany[i]._id,
      });
    }

    this.setState({ companys });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { file, CNPJ, Mes, Ano } = this.state;

    // console.log(file, CNPJ, Mes, Ano)

    /*
        try{
            const response = await api.post('/relatorioDesempenho', { file: file_path, CNPJ, Mes, Ano });
            console.log(response)
            alert('cadastro realizado!')
        } catch(e){
            alert('erro ao cadastrar arquivo')
        }
        
        
        */
    alert("submit");
  };

  render() {
    const { companys } = this.state;

    return (
      <div className="cad-files-container-external">
        <div className="cad-files-container">
          <img src={BlackLogo} alt="logo" className="logo-cad-file" />
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="registerGroupOpt">
              <Form.Label>Empresa</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => this.setState({ CNPJ: e.target.value })}
                required
                style={{
                  width: "500px",
                  marginLeft: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {companys.map((company) => {
                  return (
                    <option
                      value={company.CNPJ}
                      key={company._id}
                      className="text-center"
                    >
                      {company.Nome}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="registerGroupOpt">
              <Form.Label>Mês:</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => this.setState({ Mes: e.target.value })}
                required
                style={{
                  width: "500px",
                  marginLeft: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <option value={"Janeiro"} className="text-center">
                  Janeiro
                </option>
                <option value={"Fevereiro"} className="text-center">
                  Fevereiro
                </option>
                <option value={"Março"} className="text-center">
                  Março
                </option>
                <option value={"Abril"} className="text-center">
                  Abril
                </option>
                <option value={"Maio"} className="text-center">
                  Maio
                </option>
                <option value={"Junho"} className="text-center">
                  Junho
                </option>
                <option value={"Julho"} className="text-center">
                  Julho
                </option>
                <option value={"Agosto"} className="text-center">
                  Agosto
                </option>
                <option value={"Setembro"} className="text-center">
                  Setembro
                </option>
                <option value={"Outubro"} className="text-center">
                  Outubro
                </option>
                <option value={"Novembro"} className="text-center">
                  Novembro
                </option>
                <option value={"Dezembro"} className="text-center">
                  Dezembro
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="registerGroupOpt">
              <Form.Label>Ano:</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => this.setState({ Ano: e.target.value })}
                required
                style={{
                  width: "500px",
                  marginLeft: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <option value={"2017"} className="text-center">
                  2017
                </option>
                <option value={"2018"} className="text-center">
                  2018
                </option>
                <option value={"2019"} className="text-center">
                  2019
                </option>
                <option value={"2020"} className="text-center">
                  2020
                </option>
                <option value={"2021"} className="text-center">
                  2021
                </option>
              </Form.Control>
            </Form.Group>

            <input
              type="file"
              name="file"
              onChange={(e) => this.setState({ file: e.target.value })}
            />

            <Button type="submit">Cadastrar</Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
