import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion, Card } from "react-bootstrap";
import styled from "styled-components";
import AgenteChart from "./AgenteChart";

const PerfilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 0;
  margin-top: 35px;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 35px;
`;

const PerfilNamePlusIcon = styled.span`
  display: flex;
  align-items: center;
`;

const PerfilName = styled.span`
  color: #157ffb;
  padding-left: 1em;
`;

const PerfilContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const PerfilColumn = styled.div`
  width: 50%;
`;

const PerfilDataContainer = styled.div`
  margin-bottom: 1em;
`;

const PerfilDataTitle = styled.div`
  text-transform: uppercase;
  color: #353535;
  font-size: 14px;
`;

const UsinaAccordion = ({ p, i }: { p: any; i: any }) => {
  return (
    <Accordion>
      <PerfilContainer>
        <Card style={{ width: "100%", borderRadius: "5px" }}>
          <Accordion.Toggle
            as={Card.Header}
            variant="link"
            eventKey={`perf${i}`}
            flush="true"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "80px",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "5px 5px 5px 5px",
              paddingLeft: "2em",
            }}
          >
            <PerfilNamePlusIcon>
              <img
                src={require("../../Assets/zap.svg")}
                alt="not found icon"
                style={{ width: "1.5em", height: "1.5em" }}
              ></img>
              <PerfilName>
                <strong>{p.sigla}</strong>
              </PerfilName>
            </PerfilNamePlusIcon>
            <FontAwesomeIcon
              icon={faChevronDown}
              size="lg"
              style={{ marginRight: "1.2em" }}
            />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`perf${i}`}>
            <Card.Body>
              <PerfilContent>
                <PerfilColumn>
                  <PerfilDataContainer>
                    <PerfilDataTitle>CÓDIGO</PerfilDataTitle>
                    {p.codPerfil}
                  </PerfilDataContainer>
                  <PerfilDataContainer>
                    <PerfilDataTitle>submercado</PerfilDataTitle>
                    {p.submercado}
                  </PerfilDataContainer>
                </PerfilColumn>
                <PerfilColumn>
                  <PerfilDataContainer>
                    <PerfilDataTitle>categoria</PerfilDataTitle>
                    {p.categoria}
                  </PerfilDataContainer>
                  <PerfilDataContainer>
                    <PerfilDataTitle>classe</PerfilDataTitle>
                    {p.classe}
                  </PerfilDataContainer>
                </PerfilColumn>
              </PerfilContent>
              <hr />
              {p?.usinas
                ?.sort((a: any, b: any) => a.codAtivo - b.codAtivo)
                .map((u: any, i: number) => {
                  return (
                    <>
                      <PerfilContent>
                        <PerfilColumn>
                          <PerfilDataContainer>
                            <PerfilDataTitle>CÓDIGO DO ATIVO</PerfilDataTitle>
                            {u.codAtivo}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>CEG</PerfilDataTitle>
                            {u.ceg}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>Submercado</PerfilDataTitle>
                            {u.submercado}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>Fonte</PerfilDataTitle>
                            {u.fonte}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>Tipo de Despacho</PerfilDataTitle>
                            {u.tipoDespacho}
                          </PerfilDataContainer>
                        </PerfilColumn>
                        <PerfilColumn>
                          <PerfilDataContainer>
                            <PerfilDataTitle>Sigla do Ativo</PerfilDataTitle>
                            {u.sigla}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>Estado</PerfilDataTitle>
                            {u.uf}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>
                              Capacidade da Usina(MW)
                            </PerfilDataTitle>
                            {u.capacidadeUsina}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>Combustivel</PerfilDataTitle>
                            {u.combustivel}
                          </PerfilDataContainer>
                          <PerfilDataContainer>
                            <PerfilDataTitle>
                              Característica da Parcela
                            </PerfilDataTitle>
                            {u.caracteristicaParcela}
                          </PerfilDataContainer>
                        </PerfilColumn>
                      </PerfilContent>
                      <AgenteChart
                        data={u.generation}
                        options={{ title: "Geração" }}
                      />
                    </>
                  );
                })}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </PerfilContainer>
    </Accordion>
  );
};
export default UsinaAccordion;
