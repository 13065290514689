import React, { Component } from "react";
import "./style.css";
import BlackLogo from "../../Assets/blackLogo.png";
import { Form, Button, Row } from "react-bootstrap";

import { withRouter } from "react-router-dom";

import faradayApi from "../../Services/faradayApi";

class App extends Component {
  constructor() {
    super();

    this.state = {
      companys: [],
      months: [],
      CNPJ: "03.906.649/0001-64",
      Mes: "Janeiro",
      Ano: "2017",
      CustoCativo: "0.00",
      CustoLivre: "0.00",
      EnergiaContratada: "0.00",
      EnergiaMedida: "0.00",
      Flexibilidade: "10",
      Status: "1",
      Choose: true,
    };
  }

  async componentDidMount() {
    const response = await faradayApi.get("company");

    const Allcompany = response.data;

    var companys = [];

    for (let i = 0; i < Allcompany.length; i++) {
      companys.push({
        Nome: Allcompany[i].Nome,
        CNPJ: Allcompany[i].CNPJ,
        _id: Allcompany[i]._id,
      });
    }

    this.setState({ companys });
  }

  handleSignUp = async (e) => {
    e.preventDefault();

    const {
      CNPJ,
      Mes,
      Ano,
      CustoCativo,
      CustoLivre,
      EnergiaContratada,
      EnergiaMedida,
      Status,
    } = this.state;
    var Flexibilidade = String(this.state.Flexibilidade / 100);

    try {
      const response = await faradayApi.post("monthData", {
        CNPJ,
        Mes,
        Ano,
        CustoCativo,
        CustoLivre,
        EnergiaContratada,
        Flexibilidade,
        EnergiaMedida,
        Status,
      });

      // console.log(response)

      alert("Cadastro Realizado!");
      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Ocorreu um erro ao registrar sua conta.");
    }
  };

  handleInput = (value) => {
    var correct_input = "";

    for (let i = 0; i < value.length; i++) {
      if (value[i] === "," || value[i] === ".") {
        correct_input = correct_input + ".";
      } else if (value[i] >= "0" && value[i] <= "9") {
        correct_input = correct_input + value[i];
      }
    }

    return correct_input;
  };

  handleChooseCad = () => {
    this.setState({ Choose: true });
  };
  handleChooseDel = () => {
    this.setState({ Choose: false });
  };

  handleClickDelete = async (id) => {
    await faradayApi.delete("monthData/" + id);

    const { CNPJ } = this.state;

    try {
      const response = await faradayApi.post("showMonthData", { CNPJ });
      const months = response.data.companyData;
      this.setState({ months });
    } catch (err) {
      // console.log("rlx");
    }

    alert("Mês apagado!");
  };

  handleDelete = async () => {
    const { CNPJ } = this.state;

    try {
      const response = await faradayApi.post("showMonthData", { CNPJ });
      const months = response.data.companyData;
      this.setState({ months });
    } catch (err) {
      //   console.log("rlx");
    }
  };

  render() {
    const { companys, Choose, months } = this.state;

    return (
      <div className="container-register-dadosMes">
        <div className="buttonsChooseDadosMes">
          <Button onClick={this.handleChooseCad}>Cadastrar</Button>
          <Button onClick={this.handleChooseDel}>Deletar</Button>
        </div>
        <img src={BlackLogo} alt="logo" style={{ width: "100px" }} />

        {Choose && (
          <div>
            <h1 className="title-cadMes">Cadastrar dados mensais</h1>
            <div className="register-box-dadosMes">
              <Form onSubmit={this.handleSignUp}>
                <Form.Group controlId="registerGroupOpt">
                  <Form.Label>Empresa</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => this.setState({ CNPJ: e.target.value })}
                    required
                  >
                    {companys.map((company) => {
                      return (
                        <option
                          value={company.CNPJ}
                          key={company._id}
                          className="text-center"
                        >
                          {company.Nome}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="registerGroupOpt">
                  <Form.Label>Mês:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => this.setState({ Mes: e.target.value })}
                    required
                  >
                    <option value={"Janeiro"} className="text-center">
                      Janeiro
                    </option>
                    <option value={"Fevereiro"} className="text-center">
                      Fevereiro
                    </option>
                    <option value={"Março"} className="text-center">
                      Março
                    </option>
                    <option value={"Abril"} className="text-center">
                      Abril
                    </option>
                    <option value={"Maio"} className="text-center">
                      Maio
                    </option>
                    <option value={"Junho"} className="text-center">
                      Junho
                    </option>
                    <option value={"Julho"} className="text-center">
                      Julho
                    </option>
                    <option value={"Agosto"} className="text-center">
                      Agosto
                    </option>
                    <option value={"Setembro"} className="text-center">
                      Setembro
                    </option>
                    <option value={"Outubro"} className="text-center">
                      Outubro
                    </option>
                    <option value={"Novembro"} className="text-center">
                      Novembro
                    </option>
                    <option value={"Dezembro"} className="text-center">
                      Dezembro
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="registerGroupOpt">
                  <Form.Label>Ano:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => this.setState({ Ano: e.target.value })}
                    required
                  >
                    <option value={"2017"} className="text-center">
                      2017
                    </option>
                    <option value={"2018"} className="text-center">
                      2018
                    </option>
                    <option value={"2019"} className="text-center">
                      2019
                    </option>
                    <option value={"2020"} className="text-center">
                      2020
                    </option>
                    <option value={"2021"} className="text-center">
                      2021
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="registerGroupTel">
                  <Form.Label className="">Custo cativo (R$): </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      this.setState({ CustoCativo: e.target.value })
                    }
                    value={this.handleInput(this.state.CustoCativo)}
                  />
                </Form.Group>

                <Form.Group controlId="registerGroupTel">
                  <Form.Label className="">Custo Livre (R$): </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      this.setState({ CustoLivre: e.target.value })
                    }
                    value={this.handleInput(this.state.CustoLivre)}
                  />
                </Form.Group>

                <Form.Group controlId="registerGroupTel">
                  <Form.Label className="">Contrato (MWh): </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      this.setState({ EnergiaContratada: e.target.value })
                    }
                    value={this.handleInput(this.state.EnergiaContratada)}
                  />
                </Form.Group>

                <Form.Group controlId="registerGroupTel">
                  <Form.Label className="">Consumo (MWh): </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      this.setState({ EnergiaMedida: e.target.value })
                    }
                    value={this.handleInput(this.state.EnergiaMedida)}
                  />
                </Form.Group>

                <Form.Group controlId="registerGroupTel">
                  <Form.Label className="">Flexibilidade (%): </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    onChange={(e) =>
                      this.setState({ Flexibilidade: e.target.value })
                    }
                    value={this.handleInput(this.state.Flexibilidade)}
                  />
                </Form.Group>

                <fieldset className="lineCkeck">
                  <Form.Group as={Row}>
                    <Form.Check
                      type="radio"
                      label="Mês Finalizado"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                      required
                      onChange={(e) => this.setState({ Status: "1" })}
                    />
                    <Form.Check
                      type="radio"
                      label="Mẽs Incompleto"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios2"
                      required
                      onChange={(e) => this.setState({ Status: "2" })}
                    />
                  </Form.Group>
                </fieldset>

                <Button type="submit">Cadastrar</Button>
              </Form>
            </div>
          </div>
        )}

        {!Choose && (
          <div>
            <h1 className="title-cadMes">Deletar dados</h1>
            <div className="register-box-dadosMes">
              <Form onSubmit={this.handleDelete}>
                <Form.Group controlId="registerGroupOpt">
                  <Form.Label>Empresa</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(e) => this.setState({ CNPJ: e.target.value })}
                    required
                  >
                    {companys.map((company) => {
                      return (
                        <option
                          value={company.CNPJ}
                          key={company._id}
                          className="text-center"
                        >
                          {company.Nome}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>

                {months.map((month) => {
                  return (
                    <div className="mesCadastrado" key={month._id}>
                      <div className="merCadastradoLine">
                        <p className="monthData">{month.Mes}</p>
                        <p className="monthData">{month.Ano}</p>
                      </div>
                      <div className="merCadastradoLineContent">
                        <p className="monthDataContent">Custo cativo:</p>
                        <p className="monthDataContent">
                          {parseFloat(month.CustoCativo).toLocaleString(
                            "pt-BR",
                            { style: "currency", currency: "BRL" }
                          )}
                        </p>
                      </div>
                      <div className="merCadastradoLineContent">
                        <p className="monthDataContent">Custo Livre:</p>
                        <p className="monthDataContent">
                          {parseFloat(month.CustoLivre).toLocaleString(
                            "pt-BR",
                            { style: "currency", currency: "BRL" }
                          )}
                        </p>
                      </div>
                      <div className="merCadastradoLineContent">
                        <p className="monthDataContent">Energia Contratada:</p>
                        <p className="monthDataContent">
                          {parseFloat(
                            month.EnergiaContratada
                          ).toLocaleString("pt-BR", { style: "decimal" })}
                        </p>
                      </div>
                      <div className="merCadastradoLineContent">
                        <p className="monthDataContent">Energia Medida:</p>
                        <p className="monthDataContent">
                          {parseFloat(
                            month.EnergiaMedida
                          ).toLocaleString("pt-BR", { style: "decimal" })}
                        </p>
                      </div>
                      <div className="merCadastradoLineContent">
                        <p className="monthDataContent">Flexibilidade:</p>
                        <p className="monthDataContent">
                          {month.Flexibilidade * 100 + "%"}
                        </p>
                      </div>
                      <div className="merCadastradoLineContent">
                        <p className="monthDataContent">Status:</p>
                        <p className="monthDataContent">{month.Status}</p>
                      </div>

                      <Button
                        variant="danger"
                        onClick={() => this.handleClickDelete(month._id)}
                      >
                        Deletar Mês
                      </Button>
                    </div>
                  );
                })}

                <Button onClick={this.handleDelete}>
                  Procurar Meses Cadastrados
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(App);
