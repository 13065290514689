import React from "react";
import logo from "../../Assets/fortlev_solar_vetor.svg";
import foto1 from "../../Assets/fortlev-solar-usina-01.jpg";
import foto2 from "../../Assets/fortlev-solar-usina-02.jpg";
import foto3 from "../../Assets/fortlev-solar-usina-03.jpg";

export default function Header() {
  const scrollToSimulador = () => {
    const element = document.getElementById("simuladorId");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToEmail = () => {
    const element = document.getElementById("emailId");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="container">
      <div
        className="container-formSimulacao"
        style={{ marginTop: "60px" }}
        id="headerId"
      >
        <div className="row-header-fortlev">
          <div className="first-col-header-fortlev">
            <img
              src={foto3}
              alt="Usina da fortlev solar"
              className="img-fortlev-header"
            />
          </div>

          <div className="second-col-header-fortlev">
            <img src={logo} alt="Teste" className="img-fortlev" />

            <h2 className="text-header-fortlev">
              Venha se tornar Autoprodutor{" "}
              <span className="second-text-header-fortlev">
                e tenha grandes descontos na sua fatura de energia.
              </span>
            </h2>

            <button className="button-header-fortlev" onClick={scrollToEmail}>
              Tenho interesse
            </button>
          </div>
        </div>

        <div className="veja-mais">
          <button className="veja-mais-button" onClick={scrollToSimulador}>
            Veja mais
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-down-short"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
