import React from "react";
import "./style.css";
import NavBar from "../../Components/Navbar/index";
import HeaderDefault from "../../Components/HeaderDefault/index";

import Footer from "../../Components/Footer/index";
import Fade from "react-reveal/Fade";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";

const benefitsArray = [
  {
    title: "Comissões Atraentes",
    body:
      "Para cada indicação de um cliente que migre para o mercado livre o parceiro irá ganhar 30% de comissão, até o final do contrato.",
  },
  {
    title: "Liberdade de tempo",
    body: "Nós tomamos conta de todo o pós-venda e suporte do seu cliente.",
  },
  {
    title: "Equipe de suporte qualificada",
    body:
      "Estamos prontos para lhe oferecer um atendimento exclusivo e personalizado.",
  },
  {
    title: "Torne-se um expert no mercado de energia",
    body: "Usufrua de todos os nossos cursos de capacitação exclusivos.",
  },
  {
    title: "Acompanhar os clientes atravês da nossa Plataforma",
    body:
      "Oferecemos uma plataforma web para que todos os clientes vindo do parceiro sejam acompanhados.",
  },
];

const Parceiros = () => {
  return (
    <div className="container-mercadoLivre">
      <NavBar changeColor="false" />
      <HeaderDefault screen="parceiros" />
      <div className="container-modernizacao">
        <div className="titleBox-modernizacao">
          <h1 className="title-modenizacao">Por que ser um parceiro MERX?</h1>
        </div>

        <div className="textBox-modernizacao">
          <div className="container-table">
            <CardColumns>
              {benefitsArray.map((b, idx) => (
                <Card>
                  <Card.Body>
                    <Card.Title>{b.title}</Card.Title>
                    <Card.Text>{b.body}</Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </CardColumns>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Parceiros;
