import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
//import logo from "../../Assets/merx-logo.png"
import logo from "../../Assets/logo-pdf.png";
import checkicon from "../../Assets/checkicon.png";
import faturapdf from "../../Assets/fatura-pdf.png";
import elegivelpdf from "../../Assets/elegivel-pdf.png";
import compraenergia from "../../Assets/energia-pdf.png";
import contratopdf from "../../Assets/contrato-pdf.png";
import encerramentopdf from "../../Assets/encerramento-pdf.png";
import painelsolarpdf from "../../Assets/painel-solar-pdf.png";
import livrepdf from "../../Assets/livre-pdf.png";
import viabilidadepdf from "../../Assets/viabilidade-pdf.png";
import erroricon from "../../Assets/erroricon.png";
import { Font } from "@react-pdf/renderer";
import RobotoRegular from "../../Fonts/Roboto-Regular.ttf";
import RobotoBold from "../../Fonts/Roboto-Bold.ttf";
import RobotoBlack from "../../Fonts/Roboto-Black.ttf";

Font.register({
  family: "RobotoRegular",
  src: RobotoRegular,
});

Font.register({
  family: "RobotoBold",
  src: RobotoBold,
});

Font.register({
  family: "RobotoBlack",
  src: RobotoBlack,
});

const formatNumberToBrazilCurrency = (number) => {
  const formattedNumber = new Intl.NumberFormat("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return formattedNumber;
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 70,
  },
  textSimulation: {
    margin: 12,
    fontSize: 20,
    textAlign: "justify",
    fontFamily: "RobotoBold",
  },
  textCliente: {
    fontSize: 12,
    margin: "10px 0",
    textAlign: "justify",
    fontFamily: "RobotoBlack",
  },
  textPremissas: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "RobotoBold",
  },
  resume: {
    fontSize: 20,
    textAlign: "center",
    fontFamily: "RobotoBold",
    marginBottom: 10,
  },
  resumeText: {
    fontSize: 12,
    fontFamily: "RobotoRegular",
    marginBottom: 7,
  },
  strongText: {
    fontFamily: "RobotoBold",
  },
  logoArea: {
    margin: "0px 48px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-between",
    alignItems: "center",
  },
  logoArea2: {
    margin: "15px 48px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-between",
    alignItems: "center",
  },
  putCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 40px",
  },
  textsClientArea: {
    display: "flex",
    flexDirection: "column",
    fontSize: "8px",
    marginRight: "20px",
  },
  textsDataArea: {
    display: "flex",
    flexDirection: "column",
    fontSize: "8px",
    marginRight: "20px",
  },
  textsPriceArea: {
    display: "flex",
    flexDirection: "column",
    fontSize: "8px",
  },
  textsUsinaData: {
    display: "flex",
    flexDirection: "column",
    fontSize: "8px",
    marginLeft: "40px",
  },
  timeLapse: {
    position: "relative",
    display: "flex",
    left: "10px",
  },
  iconsTimeLapse: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  styleIcon: {
    width: "24px",
    height: "auto",
  },
  timeLapseText: {
    fontSize: "8px",
    fontFamily: "RobotoBlack",
  },
  badgeLapseFatura: {
    backgroundColor: "#000",
    borderRadius: "9px",
    width: "48px",
    height: "30px",
    textAlign: "center",
    color: "#FFF",
    fontFamily: "RobotoBlack",
    fontSize: "8px",
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  badgeLapseElegibilidade: {
    position: "relative",
    marginRight: "5px",
    backgroundColor: "#000",
    fontSize: "8px",
    color: "#FFF",
    fontFamily: "RobotoBlack",
    height: "30px",
    borderRadius: "9px",
    width: "75px",
    textAlign: "center",
  },
  badgeLapseViabilidade: {
    backgroundColor: "#000",
    marginRight: "5px",
    fontSize: "8px",
    position: "relative",
    color: "#FFF",
    padding: "0px 5px",
    fontFamily: "RobotoBlack",
    borderRadius: "9px",
    width: "60px",
    textAlign: "center",
    display: "flex",
  },
  barCativo: {
    width: "60px",
    height: "150px",
    backgroundColor: "#BCBCBC",
    border: "1px solid #000",
  },
  barLivre: {
    width: "60px",
    height: "21px",
    backgroundColor: "#EAC52E",
    position: "relative",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
    borderTop: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  barEnergia: {
    width: "60px",
    height: "33px",
    backgroundColor: "#249B28",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  barDistribuidora: {
    width: "60px",
    height: "49px",
    backgroundColor: "#95461A",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  barMerx: {
    width: "60px",
    height: "3px",
    backgroundColor: "#0072ce",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
    borderBottom: "1px solid #000",
  },
  textCativo: {
    fontFamily: "RobotoBlack",
    textDecoration: "underline",
    fontSize: "10px",
    marginBottom: "3px",
    position: "relative",
    top: "-3px",
  },
  textLivre: {
    fontFamily: "RobotoBlack",
    fontSize: "10px",
    position: "relative",
    top: "-3px",
  },
  detailsText: {
    fontFamily: "RobotoBlack",
    fontSize: "12px",
    textDecoration: "underline",
    textAlign: "center",
    marginBottom: "9px",
  },
  fluxoCaixaText: {
    fontFamily: "RobotoBlack",
    fontSize: "12px",
    textDecoration: "underline",
    textAlign: "center",
    marginBottom: "9px",
  },
  detailsArea: {
    position: "relative",
    top: "10px",
    left: "50px",
    width: "181px",
  },
  detailsHeader: {
    width: "100%",
    backgroundColor: "#8F8F8F",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    height: "18px",
    display: "flex",
    flexDirection: "row",
    fontSize: "8px",
    fontFamily: "RobotoBlack",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 12px",
  },
  detailsDistribuidora: {
    margin: "3px 0px",
    width: "181px",
    height: "15px",
    padding: "0px 12px",
    backgroundColor: "#C5C5C5",
    fontSize: "7px",
    fontFamily: "RobotoRegular",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsEnergia: {
    marginBottom: "3px",
    width: "181px",
    height: "15px",
    padding: "0px 12px",
    backgroundColor: "#C5C5C5",
    fontSize: "7px",
    fontFamily: "RobotoRegular",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsOperacional: {
    marginBottom: "3px",
    width: "181px",
    height: "15px",
    padding: "0px 12px",
    backgroundColor: "#C5C5C5",
    fontSize: "7px",
    fontFamily: "RobotoRegular",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsEncargos: {
    marginBottom: "3px",
    width: "181px",
    height: "15px",
    padding: "0px 12px",
    backgroundColor: "#C5C5C5",
    fontSize: "7px",
    fontFamily: "RobotoRegular",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsTotal: {
    width: "100%",
    height: "18px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    backgroundColor: "#8F8F8F",
    display: "flex",
    flexDirection: "row",
    fontSize: "8px",
    fontFamily: "RobotoBlack",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 12px",
  },
  chartArea: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "RobotoRegular",
    position: "relative",
    left: "-20px",
    top: "5px",
    fontSize: 8,
  },
  chartCard: {
    height: "30px", // Aumente a altura para acomodar o texto
    width: "30px",
    padding: "0px 10px",
    textAlign: "center",
    marginRight: "10px",
    backgroundColor: "#1FA824",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  textLivre2: {
    fontFamily: "RobotoBlack",
    fontSize: "10px",
    marginBottom: "3px",
    position: "relative",
    textDecoration: "underline",
  },
  textMerx: {
    fontFamily: "RobotoRegular",
    fontSize: "10px",
    position: "relative",
    left: "14px",
    marginBottom: "4px 0px",
  },
  priceCativo: {
    fontFamily: "RobotoRegular",
    fontSize: "10px",
    marginBottom: "3px 0px",
    position: "relative",
    top: "-2px",
  },
  priceLivre: {
    fontFamily: "RobotoRegular",
    fontSize: "10px",
    position: "relative",
    top: "-2px",
  },
  bandeiraVerde: {
    backgroundColor: "#1fa824",
    padding: "9px",
    margin: "3px",
    fontSize: "9px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  bandeiraCard: {
    backgroundColor: "#DADADA",
    fontSize: "9px",
    fontFamily: "RobotoBlack",
    width: "75px",
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    marginRight: "6px",
  },
  bandeiraAmarela: {
    backgroundColor: "#EAC52E",
    padding: "9px",
    margin: "3px",
    fontSize: "9px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  bandeiraVermelhaP1: {
    backgroundColor: "#DF2D2D",
    padding: "9px",
    margin: "3px",
    fontSize: "9px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  bandeiraVermelhaP2: {
    backgroundColor: "#AA1818",
    padding: "9px",
    margin: "3px",
    fontSize: "9px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  bandeiraArea: {
    position: "relative",
    top: "10px",
    left: "30px",
  },
  bandeiraMerx: {
    backgroundColor: "#0072ce",
    padding: "13px",
    margin: "3px",
    fontSize: "9px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  porcentagemVerde: {
    backgroundColor: "#1fa824",
    padding: "13px 20px 13px 13px",
    margin: "3px",
    fontSize: "10px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  porcentagemAmarela: {
    backgroundColor: "#EAC52E",
    padding: "13px 18px 13px 13px",
    margin: "3px",
    fontSize: "10px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  porcentagemVermelhaP1: {
    backgroundColor: "#DF2D2D",
    padding: "13px 18px 13px 13px",
    margin: "3px",
    fontSize: "10px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  porcentagemVermelhaP2: {
    backgroundColor: "#AA1818",
    padding: "13px 20px 13px 13px",
    margin: "3px",
    fontSize: "10px",
    fontFamily: "RobotoBlack",
    color: "#FFF",
  },
  badgeLapseContrato: {
    position: "relative",
    marginRight: "5px",
    backgroundColor: "#000",
    fontSize: "8px",
    padding: "0px 5px",
    color: "#FFF",
    fontFamily: "RobotoBlack",
    borderRadius: "9px",
    width: "70px",
    textAlign: "center",
  },
  badgeLapseCompra: {
    position: "relative",
    marginRight: "5px",
    backgroundColor: "#000",
    fontSize: "8px",
    color: "#FFF",
    fontFamily: "RobotoBlack",
    borderRadius: "9px",
    width: "80px",
    textAlign: "center",
  },
  badgeLapseEncerramento: {
    position: "relative",
    marginRight: "5px",
    backgroundColor: "#000",
    fontSize: "8px",
    color: "#FFF",
    fontFamily: "RobotoBlack",
    borderRadius: "9px",
    width: "85px",
    textAlign: "center",
  },
  badgeLapseLivre: {
    backgroundColor: "#000",
    position: "relative",
    fontSize: "8px",
    color: "#FFF",
    fontFamily: "RobotoBlack",
    borderRadius: "9px",
    width: "45px",
    textAlign: "center",
  },
  labelText: {
    fontFamily: "RobotoBlack",
    marginBottom: "5px",
    fontSize: "14px",
    textDecoration: "underline",
  },
  dataText: {
    margin: "3px 0px",
  },
  boldText: {
    fontFamily: "RobotoBold",
  },
  dataInfo: {
    display: "flex",
    position: "relative",
    top: "-10px",
    margin: "0 35px",
    flexDirection: "row",
  },
  economyGrandArea: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    left: "10px",
  },
  economyArea: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
  },
  borderEconomy: {
    backgroundColor: "#249B28",
    padding: "5px",
    width: "120px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    marginBottom: "3px",
  },
  borderEconomyBottom: {
    backgroundColor: "#EEEEEE",
    padding: "5px",
    width: "120px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    marginBottom: "5px",
  },
  textValueEconomy: {
    color: "#222",
    fontFamily: "RobotoRegular",
    fontSize: "12px",
    position: "relative",
    left: "45px",
  },
  textValueEconomyPeriod: {
    color: "#222",
    width: "120px",
    fontFamily: "RobotoRegular",
    fontSize: "12px",
    position: "relative",
    left: "18px",
  },
  textEconomy: {
    color: "#FFF",
    fontFamily: "RobotoBold",
    fontSize: "8px",
  },
  textEconomyPeriod: {
    position: "relative",
    left: "8px",
    color: "#FFF",
    fontFamily: "RobotoBold",
    fontSize: "8px",
  },
  dataArea: {
    color: "red",
  },
  preAnalise: {
    display: "flex",
    position: "relative",
    top: "10px",
    margin: "0px 40px",
  },
  viabilidadeArea: {
    display: "flex",
    position: "relative",
    top: "25px",
    margin: "0px 40px",
  },
  borderOne: {
    border: "1px solid black",
    width: "100px",
    padding: "3px",
    position: "relative",
    left: "-90px",
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
  borderOneEconomia: {
    border: "2px solid black",
    width: "100px",
    padding: "3px",
    position: "relative",
    left: "-20px",
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
  countOne: {
    fontFamily: "RobotoBlack",
    position: "relative",
    top: "3px",
    left: "-110px",
  },
  preAnaliseText: {
    position: "relative",
    top: "6px",
    fontSize: "14px",
    left: "-90px",
    fontFamily: "RobotoBlack",
  },
  countOneEconomia: {
    fontFamily: "RobotoBlack",
    position: "relative",
    top: "3px",
    left: "-41px",
  },
  preAnaliseTextEconomia: {
    position: "relative",
    top: "5px",
    left: "-22px",
    fontFamily: "RobotoBlack",
  },
  premissas: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 40px",
    marginTop: 10,
    backgroundColor: "#DDD",
    borderRadius: 10,
    padding: 15,
  },
  infoArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  infoAreaLast: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  infoAreaResume: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "RobotoRegular",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  alertInfo: {
    display: "flex",
    marginTop: 300,
  },
  alertText: {
    fontFamily: "RobotoRegular",
    fontSize: 8,
  },
  image: {
    position: "relative",
    left: "-20px",
    width: "210px",
    height: "auto",
    margin: "0 auto",
  },
  image2: {
    position: "relative",
    left: "210px",
    width: "130px",
    height: "auto",
    margin: "0 auto",
  },
  image3: {
    position: "relative",
    left: "260px",
    width: "130px",
    height: "auto",
    margin: "0 auto",
  },
  image4: {
    position: "relative",
    left: "300px",
    width: "130px",
    height: "auto",
    margin: "0 auto",
  },
  fortlevImage: {
    position: "relative",
    left: "0px",
    width: "120px",
    height: "auto",
    margin: "0 auto",
  },
  studioImage: {
    position: "relative",
    left: "-20px",
    width: "180px",
    height: "auto",
    margin: "0 auto",
  },
  squareImage: {
    position: "relative",
    left: "-10px",
    width: "80px",
    height: "auto",
    margin: "0 auto",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "RobotoRegular",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "RobotoRegular",
  },
  textEconomia: {
    fontSize: 8,
    fontFamily: "RobotoBold",
    marginBottom: 5,
  },
  textEconomiaSubtitle: {
    fontSize: 12,
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 15,
    border: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  textUp: {
    fontFamily: "RobotoBold",
    fontSize: 12,
  },
  pisCorrection: {
    position: "relative",
    right: 23,
  },
  icmsCorrection: {
    position: "relative",
    right: 35,
  },
  cofinsCorrection: {
    position: "relative",
    right: 18,
  },
  clienteCorrection: {
    position: "relative",
    right: 15,
  },
  consumoCorrection: {
    position: "relative",
    right: -3,
  },
  textDown: {
    fontSize: 12,
  },
  tableCell: {
    flex: 1,
    fontSize: 8,
    margin: "auto",
    textAlign: "center",
    borderWidth: 1,
    marginTop: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    padding: "2px 5px",
  },
  tableHeader: {
    backgroundColor: "#0072CE",
    textAlign: "center",
    fontWeight: "RobotoBold",
    color: "#FFF",
    paddingBottom: 10,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  headerText: {
    fontWeight: "RobotoBold",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  screenShot: {
    position: "absolute",
    top: "40px",
    height: "200px",
    zIndex: 1,
    left: "100px",
  },
});

const PDFFileApeXMl = ({
  economyData,
  simulatorData,
  screenShotChart,
  cativoData,
  livreData,
  apeData,
  desconto,
  infoUsina,
  hasCompanyLogo,
}) => {
  const tipo = "autoprodutor";
  const totalLivreOuApe =
    tipo === "livre" ? livreData.custoTotal : apeData.custoTotal;
  const economia = livreData.custoTotal - apeData.custoTotal;
  const economiaPct = (economia / livreData.custoTotal).toFixed(2);
  const tir = economyData.tir;
  const paybackAnual = economyData.payBackAnual;

  const vpl30anos = economyData.vplAnual;
  const vpl10anos = economyData.vplAnual.slice(0, 11);
  const totalVpl30anos = vpl30anos[vpl30anos.length - 1];
  const totalVpl10anos = vpl10anos[vpl10anos.length - 1];

  return (
    <Document>
      <Page style={{ backgroundColor: "#fafafa" }}>
        <View
          style={hasCompanyLogo.hasImage ? styles.logoArea2 : styles.logoArea}
        >
          {hasCompanyLogo.hasImage && (
            <Image
              style={
                hasCompanyLogo.styleType === "STUDIO"
                  ? styles.studioImage
                  : hasCompanyLogo.styleType === "FORTLEV"
                  ? styles.fortlevImage
                  : styles.squareImage
              }
              src={hasCompanyLogo.logo}
            />
          )}
          <Image
            style={
              !hasCompanyLogo.hasImage
                ? styles.image
                : hasCompanyLogo.styleType === "STUDIO"
                ? styles.image2
                : hasCompanyLogo.styleType === "FORTLEV"
                ? styles.image3
                : styles.image4
            }
            src={logo}
          />
        </View>

        <View style={styles.dataInfo}>
          <View style={styles.textsClientArea}>
            <Text style={styles.labelText}>CLIENTE</Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Nome:</Text>{" "}
              {simulatorData.cliente.length > 0
                ? simulatorData.cliente
                : "Não informado"}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Distribuidora:</Text>{" "}
              {simulatorData.distribuidora}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Classificação:</Text>{" "}
              {simulatorData.grupo} - {simulatorData.ths}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Demanda fora ponta:</Text>{" "}
              {`${simulatorData.demandaForaPonta + " kW"}`}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Demanda ponta:</Text>{" "}
              {`${simulatorData.demandaPonta + " kW"}`}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Consumo ponta:</Text>{" "}
              {`${simulatorData.consumoPonta + " kWh"}`}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Consumo fora ponta:</Text>{" "}
              {`${simulatorData.consumoForaPonta + " kWh"}`}
            </Text>
          </View>

          <View style={styles.textsDataArea}>
            <Text style={styles.labelText}>DADOS</Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>IPCA:</Text> 5%
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Reajuste:</Text> 7%
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Operacional:</Text> Desconsiderado
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>PIS:</Text> {simulatorData.pis}%
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>COFINS:</Text>{" "}
              {simulatorData.cofins}%
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>ICMS:</Text>{" "}
              {(economyData.icms * 100).toFixed(2).replace(".", ",")}%
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Adequação:</Text> A definir
            </Text>
          </View>

          <View style={styles.textsPriceArea}>
            <Text style={styles.labelText}>PREÇO ENERGIA</Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>2024:</Text> R${" "}
              {formatNumberToBrazilCurrency(
                simulatorData.precoEnergia1.replace(".", "").replace(",", ".")
              )}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>2025:</Text> R${" "}
              {formatNumberToBrazilCurrency(
                simulatorData.precoEnergia2.replace(".", "").replace(",", ".")
              )}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>2026:</Text> R${" "}
              {formatNumberToBrazilCurrency(
                simulatorData.precoEnergia3.replace(".", "").replace(",", ".")
              )}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>2027:</Text> R${" "}
              {formatNumberToBrazilCurrency(
                simulatorData.precoEnergia4.replace(".", "").replace(",", ".")
              )}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>2028:</Text> R${" "}
              {formatNumberToBrazilCurrency(
                simulatorData.precoEnergia5.replace(".", "").replace(",", ".")
              )}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Tipo da fonte: </Text>
              {(desconto === 0 && "Convencional") ||
                (desconto === 0.5 && "I5") ||
                (desconto === 1 && "I1")}
            </Text>
          </View>

          <View style={styles.textsUsinaData}>
            <Text style={styles.labelText}>DADOS DA USINA</Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Usina local:</Text>{" "}
              {infoUsina.usinaLocal ? "Sim" : "Não"}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Simultaneidade:</Text>{" "}
              {infoUsina.simultaneidade}%
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Geração:</Text>{" "}
              {formatNumberToBrazilCurrency(infoUsina.geracao)} kWh
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Demanda:</Text>{" "}
              {formatNumberToBrazilCurrency(infoUsina.demandaUsina)} kW /{" "}
              {formatNumberToBrazilCurrency(infoUsina.potenciaUsina)} kWp
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Investimento:</Text> R${" "}
              {formatNumberToBrazilCurrency(infoUsina.custoProjeto)}
            </Text>
            <Text style={styles.dataText}>
              <Text style={styles.boldText}>Taxa de atratividade: </Text>
              {String(economyData.selic * 100).replace(".", ",")}%
            </Text>
          </View>

          {/* 
            <View style={styles.economyArea}>

              <View style={styles.borderEconomy}>

                <Text style={styles.textEconomyPeriod}>ECONOMIA NO PERÍODO</Text>
              </View>

              <View style={[styles.borderEconomyBottom, {marginBottom: "10px"}]}>
                <Text style={styles.textValueEconomyPeriod}>R$ {`${formatNumberToBrazilCurrency(economia)}`}</Text>
              </View>

              <View style={styles.borderEconomy}>
                <Text style={styles.textEconomy}>PERCENTUAL DE ECONOMIA</Text>
              </View>
              <View style={styles.borderEconomyBottom}>
                <Text style={styles.textValueEconomy}>{`${(economiaPct * 100).toFixed(0)}%`}</Text>
              </View>

            </View> */}
        </View>

        <div style={styles.viabilidadeArea}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <Text style={styles.borderOne}>.</Text>
                <Text style={styles.countOne}>1</Text>
                <Text style={styles.preAnaliseText}>VIABILIDADE</Text>
              </View>

              <View style={{ position: "relative", top: "-10px" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    left: "20px",
                    top: "19px",
                  }}
                >
                  <View style={{ display: "flex", flexDirection: "column" }}>
                    <Text style={styles.textCativo}>CUSTO LIVRE</Text>
                    <Text style={styles.priceCativo}>
                      R$ {formatNumberToBrazilCurrency(livreData.custoTotal)}
                    </Text>
                    <div style={styles.barCativo}></div>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      top: "30px",
                      left: "10px",
                    }}
                  >
                    <Text style={styles.textLivre2}>{`${"CUSTO APE"}`}</Text>
                    <Text style={styles.textMerx}>(MERX)</Text>

                    <Text
                      style={styles.priceLivre}
                    >{`R$ ${formatNumberToBrazilCurrency(
                      apeData.custoTotal
                    )}`}</Text>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={styles.barLivre}></View>
                        <View
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              fontFamily: "RobotoRegular",
                              position: "relative",
                              left: "3px",
                            }}
                          >
                            ENCARGOS
                          </Text>
                          <View
                            style={{
                              width: "55px",
                              height: "1px",
                              backgroundColor: "#EAC52E",
                            }}
                          ></View>
                        </View>
                      </View>

                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={styles.barEnergia}></View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              fontFamily: "RobotoRegular",
                              position: "relative",
                              left: "3px",
                            }}
                          >
                            ENERGIA
                          </Text>
                          <View
                            style={{
                              width: "55px",
                              height: "1px",
                              backgroundColor: "#249B28",
                            }}
                          ></View>
                        </View>
                      </View>

                      <View style={{ display: "flex", flexDirection: "row" }}>
                        <View style={styles.barDistribuidora}></View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              fontFamily: "RobotoRegular",
                              position: "relative",
                              left: "3px",
                            }}
                          >
                            DISTRIBUIDORA
                          </Text>
                          <View
                            style={{
                              width: "73px",
                              height: "1px",
                              backgroundColor: "#95461A",
                            }}
                          ></View>
                        </View>
                      </View>

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          position: "relative",
                        }}
                      >
                        <View style={styles.barMerx}></View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            top: "-10px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              fontFamily: "RobotoRegular",
                              position: "relative",
                              left: "3px",
                            }}
                          >
                            MERX
                          </Text>
                          <View
                            style={{
                              width: "40px",
                              height: "1px",
                              backgroundColor: "#0E61A5",
                            }}
                          ></View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.bandeiraArea}>
              <View style={styles.economyGrandArea}>
                <View style={[styles.economyArea, { marginRight: "10px" }]}>
                  <View style={styles.borderEconomy}>
                    <Text style={styles.textEconomyPeriod}>
                      ECONOMIA NO PERÍODO
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.borderEconomyBottom,
                      { marginBottom: "10px" },
                    ]}
                  >
                    <Text style={styles.textValueEconomyPeriod}>
                      R$ {`${formatNumberToBrazilCurrency(economia)}`}
                    </Text>
                  </View>
                </View>

                <View style={styles.economyArea}>
                  <View style={styles.borderEconomy}>
                    <Text style={styles.textEconomy}>
                      PERCENTUAL DE ECONOMIA
                    </Text>
                  </View>
                  <View style={styles.borderEconomyBottom}>
                    <Text style={styles.textValueEconomy}>{`${(
                      economiaPct * 100
                    ).toFixed(0)}%`}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.detailsArea}>
                <Text style={styles.detailsText}>DETALHES DA FATURA</Text>
                <View style={styles.detailsHeader}>
                  <Text>CUSTOS</Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text>CUSTO</Text>
                    <Text>LIVRE</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Text>{"CUSTO APE"}</Text>
                    <Text style={{ fontSize: 7, fontFamily: "RobotoRegular" }}>
                      (MERX)
                    </Text>
                  </View>
                </View>

                <View style={styles.detailsDistribuidora}>
                  <Text>Distribuidora</Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(livreData.custoConexao)}
                  </Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(apeData.custoConexao)}
                  </Text>
                </View>

                <View style={styles.detailsEnergia}>
                  <Text>Energia</Text>
                  <Text style={{ position: "relative", left: "9px" }}>
                    R$ {formatNumberToBrazilCurrency(livreData.custoEnergia)}
                  </Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(apeData.custoEnergia)}
                  </Text>
                </View>

                <View style={styles.detailsOperacional}>
                  <Text>Operacional</Text>
                  <Text style={{ position: "relative", left: "3px" }}>
                    Desconsiderado
                  </Text>
                  <Text style={{ position: "relative", left: "3px" }}>
                    Desconsiderado
                  </Text>
                </View>

                <View style={styles.detailsEncargos}>
                  <Text>Encargos</Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(livreData.encargos)}
                  </Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(apeData.encargos)}
                  </Text>
                </View>

                <View style={styles.detailsTotal}>
                  <Text>TOTAL</Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(livreData.custoTotal)}
                  </Text>
                  <Text>
                    R$ {formatNumberToBrazilCurrency(apeData.custoTotal)}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
          >
            <Text style={styles.borderOne}>.</Text>
            <Text style={styles.countOne}>2</Text>
            <Text style={styles.preAnaliseText}>ESTUDOS DA USINA</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              top: "0px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: "8px",
                    position: "relative",
                    left: "10px",
                    marginBottom: "4px",
                  }}
                >
                  VPL (10 ANOS)
                </Text>
                <View style={styles.bandeiraCard}>
                  <Text style={{ marginBottom: "4px" }}>
                    R$ {formatNumberToBrazilCurrency(totalVpl10anos)}
                  </Text>
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: "8px",
                    position: "relative",
                    left: "10px",
                    marginBottom: "4px",
                  }}
                >
                  VPL (25 ANOS)
                </Text>
                <View style={styles.bandeiraCard}>
                  <Text style={{ marginBottom: "4px" }}>
                    R$ {formatNumberToBrazilCurrency(totalVpl30anos)}
                  </Text>
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: "8px",
                    position: "relative",
                    left: "10px",
                    marginBottom: "4px",
                  }}
                >
                  TIR (25 ANOS)
                </Text>
                <View style={styles.bandeiraCard}>
                  <Text style={{ marginBottom: "4px" }}>
                    {formatNumberToBrazilCurrency(tir)}%
                  </Text>
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: "8px",
                    position: "relative",
                    left: "20px",
                    marginBottom: "2px",
                  }}
                >
                  PAYBACK
                </Text>
                <View style={styles.bandeiraCard}>
                  <Text style={{ marginBottom: "4px" }}>
                    {formatNumberToBrazilCurrency(paybackAnual)} ano(s)
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                top: "10px",
                left: "80px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  left: "-160px",
                }}
              >
                <Text style={[styles.fluxoCaixaText]}>
                  FLUXO DE CAIXA UTILIZANDO VPL
                </Text>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: "9px",
                    position: "relative",
                    left: "165px",
                    top: "2px",
                  }}
                >
                  (25 anos)
                </Text>
              </View>

              <View style={styles.chartArea}>
                <Image
                  style={{
                    width: "420px",
                    height: "auto",
                    position: "relative",
                    left: "-70px",
                    top: "0px",
                  }}
                  src={screenShotChart}
                />
              </View>
            </View>
          </View>

          <View style={{ position: "relative", top: "20px" }}>
            <Text style={{ fontSize: "7px", fontFamily: "RobotoRegular" }}>
              <Text style={{ fontFamily: "RobotoBlack" }}>VPL:</Text> Valor
              presente líquido - Soma dos valores corrigidos pela taxa de
              atratividade ao longo do tempo.
            </Text>
            <Text style={{ fontSize: "7px", fontFamily: "RobotoRegular" }}>
              <Text style={{ fontFamily: "RobotoBlack" }}>TIR:</Text> Taxa
              interna de retorno - É a taxa que quando é utilizada no VPL, zera
              o valor do VPL. Ela não indica o retorno do investimento.
            </Text>
            <Text style={{ fontSize: "7px", fontFamily: "RobotoRegular" }}>
              <Text style={{ fontFamily: "RobotoBlack" }}>Payback:</Text> O
              tempo decorrido desde o investimento inicial até o momento em que
              o VPL zera.
            </Text>
          </View>

          <View style={{ position: "relative", top: "30px" }}>
            <View
              style={{
                width: "510px",
                height: "1px",
                backgroundColor: "#000",
                position: "absolute",
                left: "-10px",
              }}
            ></View>

            <Text
              style={{
                fontSize: "6px",
                textAlign: "justify",
                margin: "0 40px",
                position: "relative",
                top: "5px",
                fontFamily: "RobotoRegular",
              }}
            >
              O presente estudo tem caráter indicativo e reflete a situação
              operacional vigente do dia{" "}
              <Text style={{ fontFamily: "RobotoBlack" }}>
                {new Date(Date.now()).toLocaleString("pt-br").split(",")[0]}.
              </Text>{" "}
              Os valores de energia podem variar de acordo com os preços de
              mercado em constante flutuação. Ademais, os preços dos
              equipamentos estão sujeitos a alterações sem aviso prévio.{" "}
              <Text style={{ fontFamily: "RobotoBold" }}>
                É importante ressaltar que este estudo não constitui uma
                proposta definitiva.
              </Text>{" "}
              Cabe ressaltar que os custos de operação e manutenção relacionados
              a usina fotovoltaica não estão contemplados neste estudo. Além
              disso, determinados custos adicionais não estão incluídos, como os
              relacionados a ultrapassagem de demanda, energia reativa e
              eventuais impactos decorrentes de redução ou aumento de demanda.
              Também não estão consideradas possíveis mudanças extraordinárias
              que poderiam resultar na criação de novos tributos, encargos ou
              subsídios. Outros custos excluídos são os relacionados a
              iluminação pública, bem como parcelamentos ou financiamentos de
              faturas referentes a meses anteriores.
            </Text>
          </View>
        </div>
      </Page>
    </Document>
  );
};

export default PDFFileApeXMl;
