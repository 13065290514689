import React from "react";
import "./style.css";
import Schema from "../../Assets/schema.png";

export default function Main() {
  return (
    <div className="container-schema">
      <div className="schemaContainer">
        <img className="imgSchema" src={Schema} alt="Data" />
      </div>
    </div>
  );
}
