import React from "react";
import styles from "./style.module.css";
import Image from "../../Assets/whatWeOffer.png";
import Image1 from "../../Assets/whatWeOffer1.png";
import Image2 from "../../Assets/whatWeOffer2.png";
import Image3 from "../../Assets/whatWeOffer3.png";
export default function About() {
  const infosCard = [
    {
      img: Image,
      title: "Descubra o poder de decidir sua própria fonte de energia",
    },
    {
      img: Image1,
      title: "Parcerias para Resultados Eficazes",
    },
    {
      img: Image2,
      title: "Transformamos projetos em realidade ",
    },
    {
      img: Image3,
      title: "Navegue no universo da energia com confiança  ",
    },
  ];
  return (
    <section className={styles.section} id="whatIsMerx">
      <div className={styles.totalContent}>
        <div className={styles.titleAndLogo}>
          <h2>O Que a Merx Oferece?</h2>
        </div>
        <div className={styles.whoWeAre}>
          {infosCard &&
            infosCard.map((card, index) => (
              <div key={index} className={styles.cardOffer}>
                <img src={card.img} alt="Imagem de uma usina" loading="lazy" />
                <p>{card.title}</p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
