import React from "react";
import "./style.css";
import StepConclude from "../../Assets/stepConclude.svg";
import dot from "../../Assets/dot.svg";
import dotWaiting from "../../Assets/dotWaiting.svg";
import { useState, useEffect } from "react";
interface Check {
  aprovacaoCheck: true;
  cceeCheck: true;
  distribuidoraCheck: true;
  energiaCheck: true;
  financeiroCheck: true;
  medicaoCheck: true;
}

interface Affiliate {
  name: string;
  check: Check;
  logo: string;
  cnpj: string;
  start_acl: string;
  initials: string;
}

interface MainProps {
  Affiliates: Affiliate[];
}

export default function Main({ Affiliates }: MainProps) {
  const [selectedAffiliate, setSelectedAffiliate] = useState<string>("");
  const [
    empresaSelecionadaInformacoes,
    setEmpresaSelecionadaInformacoes,
  ] = useState<Affiliate | undefined>(undefined);
  const steps = [
    "Consulta  a distribuidora",
    "Negociação e Contratação",
    "Rescisão Contratual",
    "Abertura do processo na CCEE",
    "Aprovação do SMF",
    "Início da operação",
  ];

  

  
  const newSteps = empresaSelecionadaInformacoes?.check;

  const handleSelectChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedAffiliate(event.target.value);
    filtroInformacoes(event.target.value);
  };
  const filtroInformacoes = (empresa: string) => {
    const resultadoFinal = Affiliates.find(
      (affiliate) => affiliate.initials === empresa
    );

    if (resultadoFinal) {
      setEmpresaSelecionadaInformacoes(resultadoFinal);
    } else {
      setEmpresaSelecionadaInformacoes(undefined);
    }
  };

  const AffiliatesName: string[] = [];

  Affiliates &&
    Affiliates.map((Affiliate) => {
      AffiliatesName.push(Affiliate.initials);
    });

  return (
    <div className="migrationStatus">
      <h2>Acompanhe a Migração da unidade Indicada</h2>
      <select
        id="opcoes"
        name="opcoes"
        placeholder="Selecione a opção desejada"
        onChange={handleSelectChange}
      >
        <option value="" disabled selected className="placeholder">
          Selecione a Unidade Consumidora Cliente
        </option>
        {AffiliatesName &&
          AffiliatesName.map((Affiliate, index) => (
            <option value={Affiliate}>{Affiliate}</option>
          ))}
      </select>
      {empresaSelecionadaInformacoes ? (
        <div className="migrationSteps">
          {newSteps &&
            Object.entries(newSteps).map(([checkName, checkValue], index) => {
              return (
                <div className="step" key={index}>
                  {checkValue ? (
                    <>
                      <img src={StepConclude} alt="Icone de passo concluido" />
                      <img src={dot} alt="icone de um ponto" />
                      <h3>Concluída</h3>
                      <p>{steps[index]}</p>
                    </>
                  ) : (
                    <>
                      <h4 className="stepNumber">X</h4>
                      <img src={dotWaiting} alt="icone de um ponto" />
                      <h3 className="waiting">Aguardando</h3>
                      <p className="waiting">{steps[index]}</p>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      ) : empresaSelecionadaInformacoes != undefined &&(
        "Hello"
      )}
    </div>
  );
}
