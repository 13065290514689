import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class LoginModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      error: "",
      waiting: false,
      valor: "",
    };
  }

  handleShow() {
    // console.log(this.state)
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogClassName="custom-dialog"
          centered
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Modal.Body className="modal-body">
            <div className="container-modal">
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={this.handleClose}
              />
              <Form onSubmit={this.handleSignIn}>
                <Form.Group controlId="formGroupHeader">
                  <Form.Label
                    className="text-center loginTitle"
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "5px",
                    }}
                  >
                    Faça a sua oferta:{" "}
                  </Form.Label>
                </Form.Group>

                <Form.Group controlId="formGroupEmail">
                  <Form.Control
                    className="text-center center-input"
                    type="text"
                    placeholder="R$/MWh"
                    style={{ width: "268px" }}
                    onChange={(e) => this.setState({ valor: e.target.value })}
                  />
                </Form.Group>

                <button
                  className="fb-button color-change buffer-space-login-2"
                  type="submit"
                >
                  Ofertar
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default LoginModal;
