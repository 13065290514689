import React, { Component, useEffect, useState } from "react";
import "./style.css";

import {
  isAuthenticated,
  logout,
  getToken,
  getRefreshToken,
  updateAccessToken,
  getUserId,
  getRole,
} from "../../Services/auth";

import Logo from "../../Assets/logoMerx.png";
import LineNavbar from "../../Assets/lineNavbarTeste.svg";
import MenuIcon from "../../Assets/menuIcon.svg";
import CloseMenuIcon from "../../Assets/closeMenuIcon.svg";

import faradayApi from "../../Services/faradayApi";

const Navbar = ({ changeColor = "false" }) => {
  const onLogoutClick = () => {
    logout();
    window.location.href = "/";
  };

  const [role, setRole] = useState();

  const fetchUserData = async () => {
    if (isAuthenticated()) {
      const id = getUserId();
      const role = getRole();

      setRole(role);

      try {
        var response = await faradayApi.get(`users/${id}`);

        setRole(response.data.role);
      } catch (err) {
        if (err.response && err.response.status >= 400) {
          const refreshToken = getRefreshToken();
          try {
            const responseRefreshToken = await updateAccessToken(
              refreshToken,
              id
            );

            if (responseRefreshToken.status === 201) {
              window.location.reload();
            }
          } catch (refreshErr) {
            logout();
            window.location.reload();
          }
        }
      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    fetchUserData();
  });
  //On click scrool to bottom bar

  const [isMenuOpen, setIsMenuOpen] = useState();
  const changeMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="totalDiv" id="navbar">
      <nav collapseOnSelect expand="lg" className="bg-blue" variant="light">
        <a href="/" className="logo">
          <img src={Logo} alt="Logo da merx" />
        </a>

        <div className="totalNavbar">
          <div className="responsiveMenu">
            <img
              src={MenuIcon}
              alt="Icon to open menu"
              className="menuAndCloseIcon"
              onClick={changeMenuOpen}
            />
          </div>
          <ul className={`navItens menuOpen${isMenuOpen}`}>
            <img
              src={CloseMenuIcon}
              alt="Icon to open menu"
              className="closeMenuIcon"
              onClick={changeMenuOpen}
            />

            <li>
              <a id="navItem-color" className="" href="/pesquisa-mercado-livre">
                Pesquisa Mercado Livre
              </a>
            </li>
            <li>
              <a id="navItem-color" className="" href="/simulacao">
                Simulação
              </a>
            </li>

            {/* {isAuthenticated() && (
                <a
                  id="navItem-color"
                  
                  className=""
                  href="/marketplace"
                >
                  Marketplace
                </a>
              )} */}

            <li>
              <a id="navItem-color" className="" href="/mercado-livre">
                Mercado Livre de Energia
              </a>
            </li>

            {/* <a
                id="navItem-color"
                
                className=""
                href="parceiros"
              >
                Parceiro
              </a> */}

            <li>
              <a id="navItem-color" className="" href="/autoproducao">
                Autoprodução de Energia
              </a>
            </li>

            <li>
              <a
                id="navItem-color"
                className=""
                href="https://wa.me/558182136133?text=Tenho%20d%C3%BAvidas%20sobre%20a%20Merx"
              >
                Entre em Contato
              </a>
            </li>

            {!isAuthenticated() && (
              <li>
                <a id="navItem-color" className="" href="/login">
                  Login
                </a>
              </li>
            )}

            {isAuthenticated() && (
              <>
                <li>
                  <a
                    className=""
                    id="navItem-color"
                    href={
                      role === "CLIENTE"
                        ? "/client"
                        : role === "PARCEIRO"
                        ? "/parceiro"
                        : (role === "ADMIN" || role === "SUPER_ADMIN") &&
                          "/areaAdm"
                    }
                  >
                    {role === "CLIENTE"
                      ? "Área do cliente"
                      : role === "PARCEIRO"
                      ? "Área do Integrador"
                      : (role === "ADMIN" || role === "SUPER_ADMIN") &&
                        "Área do Admin"}
                  </a>
                </li>
                <li onClick={onLogoutClick} className="logout">
                  <p> Sair</p>
                </li>
              </>
            )}
          </ul>
          <img src={LineNavbar} alt="Line " className="lineNavbar" />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
