import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from "./pages/Home/index";
import Courses from "./pages/Courses/index";
import Autoproducao from "./pages/Autoproducao/index";
import MercadoLivre from "./pages/MercadoLivre/index";
import Register from "./pages/Register/index";
import Simulacao from "./pages/Simulacao/index";
import ConsumoInput from "./pages/ConsumoInput/index";
import Login from "./pages/LoginPage/index";
import MarketPlace from "./pages/MarketPlace/index";

import { useState, useEffect } from "react";

import Parceiro from "./pages/Parceiro/index";
import Perfil from "./pages/Perfil/index";

import CadastrarDadosMes from "./pages/CadastrarDadosMes/index";
import CadFilesMes from "./pages/CadFilesMes/index";

import NotFound from "./pages/NotFound/index";

import { isAuthenticated } from "./Services/auth";

import "./App.css";
import CompanyDashboard from "./pages/CompanyDashboard";

import AgentSearch from "./pages/agentSearch.tsx";
import AgentPage from "./pages/agentPage.tsx";
import ClientDashboard from "./pages/ClientDashboard";
import Parceiros from "./pages/Parceiros/index";
import PolicyPrivacyPage from "./pages/PolicyPrivacyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PerfilPage from "./pages/PerfilPage";
import FortlevSolar from "./pages/FortlevSolar/index";
import RecuperarSenha from "./pages/RecuperarSenha/index";
import AreaAdm from "./pages/AreaAdm";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

const AntiPrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/courses" component={Courses} />
      <Route path="/autoproducao" component={Autoproducao} />
      <Route path="/mercado-livre" component={MercadoLivre} />
      <Route path="/parceiros" component={Parceiros} />
      <Route path="/register" component={Register} />
      <PrivateRoute path="/marketplace" component={Login} />
      <PrivateRoute path="/pesquisa-mercado-livre" component={AgentSearch} />
      <PrivateRoute path="/agente/:agentId" component={AgentPage} />
      <PrivateRoute path="/client" component={ClientDashboard} />
      <PrivateRoute path="/profile/:profileId" component={PerfilPage} />
      <PrivateRoute path="/simulacao" component={Simulacao} />
      <PrivateRoute path="/ConsumoInput" component={ConsumoInput} />
      <PrivateRoute path="/parceiro" component={Parceiro} />
      <PrivateRoute path="/empresa" component={CompanyDashboard} />
      <PrivateRoute path="/perfil" component={Perfil} />
      <PrivateRoute path="/dadosMes" component={CadastrarDadosMes} />
      <PrivateRoute path="/cadFilesMes" component={CadFilesMes} />
      <AntiPrivateRoute path="/login" component={Login} />
      <Route path="/policy-privacy" component={PolicyPrivacyPage} />
      <Route path="/terms-of-use" component={TermsOfUsePage} />
      <Route path="/fortlev-solar/autoprodutor" component={FortlevSolar} />
      <AntiPrivateRoute path="/login" component={Login} />
      <AntiPrivateRoute path="/recuperarSenha" component={RecuperarSenha} />
      <PrivateRoute path="/areaAdm" component={AreaAdm} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
