import React from "react";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useHighchartsDefault from "../../Hooks/useHighchartsDefault";
import { shortMesesExt } from "../../utils/utilityFunctions";

const GraphContainer = styled.div`
  width: 100%;
  padding-top: 2em;
`;

const AgenteChartPurchaseSale = ({
  purchaseContracts,
  salesContracts,
  consume,
}: {
  purchaseContracts: {
    id: number;
    loggedAt: string;
    value: number;
    codPerfil: number;
  }[];
  salesContracts: {
    id: number;
    loggedAt: string;
    value: number;
    codPerfil: number;
  }[];
  consume: { id: number; loggedAt: string; value: number; codPerfil: number }[];
}) => {
  useHighchartsDefault(Highcharts);

  const months =
    consume.length === 12
      ? consume.slice(-12).map((e) => {
          const date = new Date(e.loggedAt);
          return shortMesesExt[date.getMonth()] + " " + date.getFullYear();
        })
      : purchaseContracts.slice(-12).map((e) => {
          const date = new Date(e.loggedAt);
          return shortMesesExt[date.getMonth()] + " " + date.getFullYear();
        });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: { type: "line" },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    title: {
      text: "Análise de compra, venda e consumo",
    },

    subtitle: { text: "Fonte: CCEE" },

    credits: {
      enabled: false,
    },

    xAxis: {
      categories: months,
    },

    yAxis: {
      title: {
        text: "Valor (MWh)",
      },
      labels: {
        format: "{value} MWh",
      },
    },

    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        return `${this.x}<br><b>${this.y.toFixed(2)} MWh</b>`;
      },
    },

    series: [
      {
        name: "Consumo",
        borderRadius: "3px",
        type: "column",
        data: [...consume.map((c) => c.value).slice(-12)],
        color: "#1898E0",
        zones: [
          {
            color: "#1898E0",
          },
        ],
      },
      {
        name: "Contratos de compra",
        data: [
          ...purchaseContracts.map((contract) => contract.value).slice(-12),
        ],
        color: "#444",
        zones: [
          {
            color: "#444",
          },
        ],
      },
      {
        name: "Contratos de venda",
        data: [...salesContracts.map((contract) => contract.value).slice(-12)],
        color: "#02C028",
        zones: [
          {
            color: "#02C028",
          },
        ],
      },
    ],
  };

  return (
    <GraphContainer>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </GraphContainer>
  );
};

export default AgenteChartPurchaseSale;
