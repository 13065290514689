import React from "react";
import styles from "./style.module.css";
import LogoMerxFooter from "../../Assets/logoMerxFooter.svg";
import InstaIcon from "../../Assets/instaIcon.png";
export default function Footer() {
  const menu = [
    {
      title: "Mercado Livre de Energia",
      link: "/mercado-livre",
    },
    {
      title: "Pesquisa Mercado Livre",
      link: "/pesquisa-mercado-livre",
    },
    {
      title: "Autoprodução de Energia",
      link: "/autoproducao",
    },
    {
      title: "Simulador",
      link: "simulacao",
    },
    {
      title: "Contato",
      link:
        "https://wa.me/558182136133?text=Tenho%20d%C3%BAvidas%20sobre%20a%20Merx",
    },
  ];
  const initialPage = [
    {
      title: "O Que é a Merx?",
      link: "/#whatIsMerx",
    },
    /* {
      title: "Manifesto",
      link: "/#manifest",
    }, */
    {
      title: "Vantagens",
      link: "/#advantages",
    },
    {
      title: "Parceiro",
      link: "/#partner",
    },
    {
      title: "Autoprodutor",
      link: "/#selfProductor",
    },
  ];
  const contact = [
    {
      title: "+55 81 3204-8980",
      link: "tel:+558132048980",
    },
    {
      title: "+55 81 98213-6133",
      link: "#",
    },
    {
      title: "comercial@merxenergia.com.br",
      link: "mailto:comercial@merxenergia.com.br",
    },
    {
      title:
        "Empresarial Cícero Dias - R. Padre Carapuceiro, 858 - Boa Viagem, Recife - PE",
      link:
        "https://www.google.com/maps/place/Merx+Energia/@-8.1164748,-34.9028171,17z/data=!3m1!4b1!4m6!3m5!1s0x7ab1fefc79793e9:0xe29dde20180cbed0!8m2!3d-8.1164748!4d-34.9028171!16s%2Fg%2F11k3h3kxyq?entry=ttu&g_ep=EgoyMDI0MTExOC4wIKXMDSoASAFQAw%3D%3D",
    },
  ];
  return (
    <footer className={styles.footer}>
      <div className={styles.totalContent}>
        <div className={styles.logo}>
          <a href="#navbar">
            <img
              src={LogoMerxFooter}
              alt="Logo da merx"
              className={styles.imgLogo}
            />
          </a>
          <p>
            Criamos uma plataforma para dar a você todo o controle sobre sua
            energia no Mercado Livre.
          </p>
        </div>
        <div className={styles.menus}>
          <div className={styles.menu}>
            <h2>Menu</h2>
            {menu.map((item, index) => (
              <>
                <a href={item.link}>{item.title}</a>
              </>
            ))}
          </div>

          <div className={styles.menu}>
            <h2>Página inicial</h2>
            {initialPage.map((item, index) => (
              <>
                <a href={item.link}>{item.title}</a>
              </>
            ))}
          </div>

          <div className={styles.menu}>
            <h2>Contato</h2>
            {contact.map((item, index) => (
              <>
                <a href={item.link}>{item.title}</a>
              </>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
