import { Page, Text, Image, Document, View, Link } from "@react-pdf/renderer";
import React from "react";
import LogoFortlevSolar from "../../Assets/fortlev-solar-logo.png";
import {
  formatCellPhoneNumberToBrazilCurrency,
  formatNumberToBrazilCurrency,
} from "../../utils/utilityFunctions";
import barTerm from "../../Assets/barTerm.png";
import TextFortlevSolar from "../../Assets/equipamentosFortlev.png";
import imageFelipeFerraz from "../../Assets/imgFelipeFerraz.png";
import {
  firstPageStyles,
  TermPageStyles,
  SecondPageStyles,
  ContactPagesStyle,
  ViabilityPagesStyle,
  FeasibilityAnalysis,
  EstimatedCapex,
} from "./styles";

export default function Main({
  proposalData,
  economyData,
  screenShotChart,
  simulacaoSelected,
  cativoLivreData,
  apeData,
}) {
  const usersContact = [
    {
      name: "Felipe Ferraz",
      office: "Gerente de Grandes Negócios",
      email: "felipe.ferraz@fortlevsolar.com.br",
      number: "5527997403389",
      image: imageFelipeFerraz,
    },
  ];

  const getUserContact = usersContact.find(
    (user) => user.email === proposalData.userEmail
  );

  const getTecnicalData = [
    {
      name: "Localização",
      values: [proposalData.localization],
    },
    {
      name: "Módulos",
      values: [proposalData.modules],
    },
    { name: "Inversores", values: [proposalData.inverter] },
    { name: "Estrutura de montagem", values: [proposalData.structure] },
  ];

  const getDataTec = {
    "Geração Média Anual": ` ${proposalData.geracaoMediaAnual} MWh/ano`,

    "Percentual de Consumo Atendido": ` ${proposalData.percentualConsumoAtendido}%`,
    "Economia Média Anual": ` R$ ${formatNumberToBrazilCurrency(
      economyData.dadosApeXml
        ? economyData.dadosApeXml[0].economiaAnual
        : economyData.economyData[0].economiaAnual
    )}`,
    "Payback Simples": economyData.paybackMensalExtenso,
  };

  const flagValues = {
    custoTotal: cativoLivreData.custoTotal,
    bandeiras:
      simulacaoSelected === "APE"
        ? {
            custoCativoAmarela:
              cativoLivreData.bandeiras?.custoCativoAmarela ?? "",
            custoCativoVermelhaP1:
              cativoLivreData.bandeiras?.custoCativoVermelhaP1 ?? "",
            custoCativoVermelhaP2:
              cativoLivreData.bandeiras?.custoCativoVermelhaP2 ?? "",
          }
        : "",
  };

  const cativoLivreInfos = {
    distribuidora: cativoLivreData.custoConexao,
    energia: cativoLivreData.custoEnergia,
    operacional: cativoLivreData.gestao,
    encargos: cativoLivreData.encargos,
    gestao: cativoLivreData.gestao,
    custoTotal: cativoLivreData.custoTotal,
  };

  const APEInfos = {
    distribuidora: apeData.custoConexao,
    energia: apeData.custoEnergia,
    operacional: apeData.gestao,
    encargos: apeData.encargos,
    gestao: apeData.gestao,
    custoTotal: apeData.custoTotal,
  };
  const flagColors = ["#1FA824", "#EAC52E", "#DF2D2D", "#AA1818"];
  const repeatedValues = Array(3).fill(APEInfos.custoTotal);

  const capex = proposalData.items.map((item) => ({
    Mwp: item.geradorFotovoltaico,
    ProjetoHomologacaoWp: item.projetoHomologacao,
    EPCWP: item.epc,

    GeradorFotoVoltaicoWpValorTotal: item.geradorFotovoltaicoCost,
    ProjetoHomologacaoWpValorTotal: item.projetoHomologacaoCost,
    EPCWPValorTotal: item.epcCost,

    GeradorFotoVoltaicoWpCapex: item.geradorFotovoltaicoCapex,
    ProjetoHomologacaoWpCapex: item.projetoHomologacaoCapex,
    EPCWPCapex: item.epcCapex,

    PotenciaTotal: item.totalValue,
    ValorTotal: item.totalCost,
    CapexTotal: 100,
    Nome: item.nome,
    PotenciaNome: item.potencia,
  }));

  const calcDescontPercent = (cativo, livre) => {
    return (100 - (100 * livre) / cativo).toFixed(0);
  };

  return (
    <Document>
      <Page orientation="landscape">
        <View style={firstPageStyles.FirstPageTexts}>
          <Text style={firstPageStyles.TitleFirstPage}>
            PROJETOS DE AUTOPRODUÇÃO
          </Text>
          <Text style={firstPageStyles.SubtitleFirstPage}>
            UFV{" "}
            {proposalData?.clientName
              ? proposalData.clientName
              : "Nome do cliente não informado"}
          </Text>
        </View>
        <View style={firstPageStyles.ViewLogos}>
          {proposalData.clientLogo && (
            <Image
              src={URL.createObjectURL(proposalData.clientLogo)}
              style={firstPageStyles.ImgLogo}
            />
          )}
          {proposalData.partnerLogo && (
            <Image
              src={URL.createObjectURL(proposalData.partnerLogo)}
              style={firstPageStyles.ImgLogo}
            />
          )}
          <View style={firstPageStyles.SolarFortlev}>
            <Image
              src={TextFortlevSolar}
              style={firstPageStyles.SolarFortlevText}
            />
            <Image
              src={LogoFortlevSolar}
              style={firstPageStyles.ImgLogoFortlev}
            />
          </View>
        </View>
        <Text style={firstPageStyles.textSimple}>{proposalData.todayDate}</Text>
      </Page>
      <Page orientation="landscape">
        <View style={SecondPageStyles.TotalPage}>
          <View style={SecondPageStyles.PageT}>
            <Text style={SecondPageStyles.ValueMWp}>
              {proposalData.clientName} -{" "}
              {formatNumberToBrazilCurrency(proposalData.potenciaUsina)} MWp
            </Text>
            <Image src={barTerm} style={SecondPageStyles.Bar} />
            <View>
              <Text style={SecondPageStyles.TitleTable}>Dados técnicos</Text>
              <View style={SecondPageStyles.Table}>
                {getTecnicalData.map((row, index) => (
                  <View key={index} style={SecondPageStyles.Row}>
                    <Text style={SecondPageStyles.Column}>{row.name}</Text>
                    <View style={SecondPageStyles.ColumnTwo}>
                      {row.values.map((value, index) => (
                        <Text key={index}> {value}</Text>
                      ))}
                    </View>
                  </View>
                ))}
              </View>

              <View>
                <View>
                  {Object.keys(getDataTec).map((key, index) => (
                    <View key={index} style={SecondPageStyles.OrangeRow}>
                      <Text style={SecondPageStyles.DataTitle}>{key}: </Text>

                      <Text style={SecondPageStyles.DataValue}>
                        {getDataTec[key]}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
          <View style={SecondPageStyles.UsinPhoto}>
            <View>
              {proposalData.projectImage1 && (
                <Image
                  src={URL.createObjectURL(proposalData.projectImage1)}
                  style={SecondPageStyles.ImageSolarOne}
                />
              )}
              {/*  <Text style={SecondPageStyles.MwpPhoto}>2,4MWp</Text> */}
            </View>
            <View>
              {proposalData.projectImage2 && (
                <Image
                  src={URL.createObjectURL(proposalData.projectImage2)}
                  style={SecondPageStyles.ImageSolarTwo}
                />
              )}
              {/*  <Text style={SecondPageStyles.MwpPhotoTwo}>2,4Mwp</Text> */}
            </View>
          </View>
        </View>
      </Page>
      <Page orientation="landscape">
        <View style={ViabilityPagesStyle.Page}>
          <View style={ViabilityPagesStyle.Side}>
            <View style={ViabilityPagesStyle.TitleBar}>
              <Text style={ViabilityPagesStyle.TitlePage}>
                Estudo de viabilidade{" "}
              </Text>
              <Image src={barTerm} style={ViabilityPagesStyle.Bar} />
            </View>
            <View style={ViabilityPagesStyle.Graphs}>
              <View style={ViabilityPagesStyle.GraphCativMarket}>
                <Text style={ViabilityPagesStyle.TitleGraph}>
                  R$ {formatNumberToBrazilCurrency(cativoLivreInfos.custoTotal)}
                </Text>
                <View style={ViabilityPagesStyle.GraphCativ}></View>
                <Text style={ViabilityPagesStyle.TitleCativConsumer}>
                  Custo {simulacaoSelected === "MLXAPE" ? "Livre" : "Cativo"}
                </Text>
              </View>
              <View style={ViabilityPagesStyle.GraphFreeMarket}>
                <Text style={ViabilityPagesStyle.TitleGraphFreeMarket}>
                  R$ {formatNumberToBrazilCurrency(APEInfos.custoTotal)}
                </Text>
                <View style={ViabilityPagesStyle.GraphFreeMarketTotalGraph}>
                  <View style={ViabilityPagesStyle.GraphPartAndTitleGraph}>
                    <View style={ViabilityPagesStyle.Charges}></View>
                    <Text style={ViabilityPagesStyle.TitleCharges}>
                      ENCARGOS
                    </Text>
                  </View>
                  <View style={ViabilityPagesStyle.GraphPartAndTitleGraph}>
                    <View style={ViabilityPagesStyle.Energy}></View>
                    <Text style={ViabilityPagesStyle.TitleEnergy}>ENERGIA</Text>
                  </View>
                  <View style={ViabilityPagesStyle.GraphPartAndTitleGraph}>
                    <View style={ViabilityPagesStyle.Distributor}></View>
                    <Text style={ViabilityPagesStyle.TitleDistributor}>
                      DISTRIBUIDORA
                    </Text>
                  </View>
                  <View style={ViabilityPagesStyle.GraphPartAndTitleGraph}>
                    <View style={ViabilityPagesStyle.Merx}></View>
                    <Text style={ViabilityPagesStyle.TitleMerx}>
                      OPERACIONAL
                    </Text>
                  </View>
                </View>
                <Text style={ViabilityPagesStyle.TitleFreeConsumer}>
                  Custo APE
                </Text>
              </View>
            </View>
          </View>
          <View style={ViabilityPagesStyle.Side}>
            <View style={ViabilityPagesStyle.Comparative}>
              <Text style={ViabilityPagesStyle.ComparativeTitle}>
                {simulacaoSelected === "APE" &&
                  "COMPARAÇÃO DE PREÇOS PARA CADA BANDEIRA DO MERCADO CATIVO"}
              </Text>
              <View style={ViabilityPagesStyle.TotalCost}>
                <View style={ViabilityPagesStyle.CativeCost}>
                  <Text style={ViabilityPagesStyle.CativeCostTitle}>
                    Custo {simulacaoSelected === "MLXAPE" ? "Livre" : "Cativo "}
                  </Text>
                  <View style={ViabilityPagesStyle.Flag}>
                    <Text style={ViabilityPagesStyle.TextFlag}>
                      R$ {formatNumberToBrazilCurrency(flagValues.custoTotal)}
                    </Text>
                    <View style={ViabilityPagesStyle.FlagGreen}></View>
                  </View>
                  {Object.entries(flagValues.bandeiras).map(
                    ([bandeira, valor], index) =>
                      valor ? (
                        <View key={bandeira} style={ViabilityPagesStyle.Flag}>
                          <Text style={ViabilityPagesStyle.TextFlag}>
                            R$ {formatNumberToBrazilCurrency(valor)}
                          </Text>
                          <View style={ViabilityPagesStyle[`Flag${index}`]} />
                        </View>
                      ) : null
                  )}
                </View>
                <View style={ViabilityPagesStyle.FreeCost}>
                  <Text style={ViabilityPagesStyle.CativeCostTitle}>
                    Custo APE
                  </Text>
                  <View style={ViabilityPagesStyle.Flag}>
                    <Text style={ViabilityPagesStyle.TextFlag}>
                      R$ {formatNumberToBrazilCurrency(APEInfos.custoTotal)}
                    </Text>
                    <View style={ViabilityPagesStyle[`FlagFree`]}></View>
                  </View>
                  {flagValues.bandeiras.custoCativoVermelhaP1 &&
                    Object.entries(repeatedValues).map(
                      ([bandeira, valor], index) => (
                        <View style={ViabilityPagesStyle.Flag}>
                          <Text style={ViabilityPagesStyle.TextFlag}>
                            R$ {formatNumberToBrazilCurrency(valor)}
                          </Text>
                          <View style={ViabilityPagesStyle[`FlagFree`]}></View>
                        </View>
                      )
                    )}
                </View>
                <View style={ViabilityPagesStyle.MonthlyEconomy}>
                  <Text style={ViabilityPagesStyle.CativeCostTitle}>
                    Economia Total
                  </Text>
                  <View style={ViabilityPagesStyle.Flag}>
                    <View style={ViabilityPagesStyle.TextFlagPercent}>
                      <Text>
                        R${" "}
                        {formatNumberToBrazilCurrency(
                          flagValues.custoTotal - APEInfos.custoTotal
                        )}
                      </Text>
                      <Text>
                        {calcDescontPercent(
                          flagValues.custoTotal,
                          APEInfos.custoTotal
                        )}
                        %
                      </Text>
                    </View>
                    <View style={ViabilityPagesStyle.FlagGreen}></View>
                  </View>
                  {flagValues.bandeiras.custoCativoVermelhaP1 &&
                    Object.entries(flagValues.bandeiras).map(
                      ([bandeira, valor], index) => (
                        <View style={ViabilityPagesStyle.Flag}>
                          <View style={ViabilityPagesStyle.TextFlagPercent}>
                            <Text>
                              R${" "}
                              {formatNumberToBrazilCurrency(
                                valor - APEInfos.custoTotal
                              )}
                            </Text>
                            <Text>
                              {calcDescontPercent(valor, APEInfos.custoTotal)}%
                            </Text>
                          </View>
                          <View
                            style={ViabilityPagesStyle[`Flag${index}`]}
                          ></View>
                        </View>
                      )
                    )}
                </View>
              </View>
            </View>
            <View style={ViabilityPagesStyle.InvoiceDetails}>
              <Text style={ViabilityPagesStyle.TitleInvoice}>
                Detalhes da fatura
              </Text>
              <View style={ViabilityPagesStyle.InvoiceContent}>
                <View style={ViabilityPagesStyle.HeadInvoice}>
                  <Text style={ViabilityPagesStyle.TextHeadInvoice}>
                    Custos{" "}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextHeadInvoice}>
                    Custo {simulacaoSelected === "MLXAPE" ? "Livre" : "Cativo "}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextHeadInvoice}>
                    Custo APE{" "}
                  </Text>
                </View>
                <View style={ViabilityPagesStyle.RowImparInvoice}>
                  <Text style={ViabilityPagesStyle.TextTitleInvoice}>
                    Distribuidora
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowImparInvoice}>
                    R${" "}
                    {formatNumberToBrazilCurrency(
                      cativoLivreInfos.distribuidora
                    )}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowImparInvoice}>
                    R$ {formatNumberToBrazilCurrency(APEInfos.distribuidora)}
                  </Text>
                </View>
                <View style={ViabilityPagesStyle.RowParInvoice}>
                  <Text style={ViabilityPagesStyle.TextTitleInvoice}>
                    Energia
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowParInvoice}>
                    R$ {formatNumberToBrazilCurrency(cativoLivreInfos.energia)}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowParInvoice}>
                    R$ {formatNumberToBrazilCurrency(APEInfos.energia)}
                  </Text>
                </View>
                <View style={ViabilityPagesStyle.RowImparInvoice}>
                  <Text style={ViabilityPagesStyle.TextTitleInvoice}>
                    Operacional
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowImparInvoice}>
                    {simulacaoSelected === "MLXAPE" ? "Desconsiderado" : "-"}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowImparInvoice}>
                    {simulacaoSelected === "MLXAPE"
                      ? "Desconsiderado"
                      : `R$ ${formatNumberToBrazilCurrency(
                          APEInfos.gestao.toFixed(2)
                        )}`}
                  </Text>
                </View>
                <View style={ViabilityPagesStyle.RowParInvoice}>
                  <Text style={ViabilityPagesStyle.TextTitleInvoice}>
                    Encargos
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowParInvoice}>
                    {simulacaoSelected === "MLXAPE"
                      ? `R$ ${formatNumberToBrazilCurrency(
                          cativoLivreInfos.encargos.toFixed(2)
                        )}`
                      : "-"}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextRowParInvoice}>
                    R${" "}
                    {formatNumberToBrazilCurrency(APEInfos.encargos.toFixed(2))}
                  </Text>
                </View>
                <View style={ViabilityPagesStyle.BottomInvoice}>
                  <Text style={ViabilityPagesStyle.TextHeadInvoice}>
                    Valor total
                  </Text>
                  <Text style={ViabilityPagesStyle.TextHeadInvoice}>
                    R$
                    {formatNumberToBrazilCurrency(cativoLivreInfos.custoTotal)}
                  </Text>
                  <Text style={ViabilityPagesStyle.TextHeadInvoice}>
                    R${formatNumberToBrazilCurrency(APEInfos.custoTotal)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page orientation="landscape">
        <View>
          <View style={FeasibilityAnalysis.TitleBar}>
            <Text style={FeasibilityAnalysis.TitlePage}>
              Análise de Viabilidade Econômica
            </Text>
            <Image src={barTerm} style={FeasibilityAnalysis.Bar} />
          </View>
          <View style={FeasibilityAnalysis.TableViability}>
            <Text style={FeasibilityAnalysis.TableHead}>
              MÉTRICAS DE ANÁLISE
            </Text>
            <View style={FeasibilityAnalysis.TableRow}>
              <Text style={FeasibilityAnalysis.TextTableTitle}>TIR</Text>
              <Text style={FeasibilityAnalysis.TextTable}>
                {economyData.tir.toLocaleString("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </Text>
            </View>
            {/* <View style={FeasibilityAnalysis.TableFooter}>
              <Text style={FeasibilityAnalysis.TextTableTitle}>
                Taxa de Atratividade
              </Text>
              <Text style={FeasibilityAnalysis.TextTable}>
                {economyData.taxaAtratividade.toLocaleString("pt-BR", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </Text>
            </View> */}
            <View style={FeasibilityAnalysis.TableRow}>
              <Text style={FeasibilityAnalysis.TextTableTitle}>
                Payback {economyData.taxaAtratividade === 0 && "simples "}(anos)
              </Text>
              <Text style={FeasibilityAnalysis.TextTable}>
                {formatNumberToBrazilCurrency(economyData.payBackAnual)}{" "}
              </Text>
            </View>
            <View style={FeasibilityAnalysis.TableRow}>
              <Text style={FeasibilityAnalysis.TextTableTitle}>
                LCOE (R$/MWh)
              </Text>
              <Text style={FeasibilityAnalysis.TextTable}>
                R$ {formatNumberToBrazilCurrency(proposalData.lcoe)}
              </Text>
            </View>
            <View style={FeasibilityAnalysis.TableFooter}>
              <Text style={FeasibilityAnalysis.TextTableTitle}>
                FC Total - 25 anos
              </Text>
              <Text style={FeasibilityAnalysis.TextTable}>
                R$ {formatNumberToBrazilCurrency(economyData.vplAnual[25])}
              </Text>
            </View>
          </View>
        </View>
        <View style={ViabilityPagesStyle.ViewImageGraph}>
          {screenShotChart && (
            <Image
              src={screenShotChart}
              style={FeasibilityAnalysis.ImageGraph}
            ></Image>
          )}
        </View>
        <Text style={FeasibilityAnalysis.GraphDescription}>
          Fluxo de caixa descontado acumulado
        </Text>
      </Page>
      <Page orientation="landscape">
        <View style={EstimatedCapex.Page}>
          <View style={FeasibilityAnalysis.TitleBar}>
            <Text style={FeasibilityAnalysis.TitlePage}>Capex Estimado</Text>
            <Image src={barTerm} style={FeasibilityAnalysis.Bar} />
          </View>
          <View style={EstimatedCapex.FullText}>
            <Text style={EstimatedCapex.MainText}>
              Fornecimento, instalação e comissionamento de usinas solar
              fotovoltaica com capacidade total de{" "}
            </Text>
            <Text style={EstimatedCapex.Bold}>
              {formatNumberToBrazilCurrency(proposalData.potenciaUsina)}MWp /{" "}
              {formatNumberToBrazilCurrency(proposalData.demandaUsina)}MW
            </Text>

            <Text style={EstimatedCapex.MainText}>
              , no formato de parceria com um integrador para entrega turn-key
              FULL EPC.
            </Text>
          </View>

          {capex.slice(0, 2).map((Parte, index) => (
            <View style={EstimatedCapex.TableViability}>
              <View style={EstimatedCapex.TableHead}>
                <Text style={EstimatedCapex.TextTableMainInfos}>
                  UFV 0{index + 1} - {Parte.Nome}
                </Text>
                <Text style={EstimatedCapex.TextTableHead}>Potência</Text>
                <Text style={EstimatedCapex.TextTableHead}>
                  {formatNumberToBrazilCurrency(Parte.PotenciaNome)}MWp
                </Text>
              </View>
              <View style={EstimatedCapex.TableRow}>
                <Text style={EstimatedCapex.TextTableTitle}> </Text>
                <Text style={EstimatedCapex.TextTableHead}>R$/Wp</Text>
                <Text style={EstimatedCapex.TextTableHead}>R$</Text>
                <Text style={EstimatedCapex.TextTableHead}>% CAPEX</Text>
              </View>
              <View style={EstimatedCapex.TableRow}>
                <Text style={EstimatedCapex.TextTableTitle}>
                  Gerador Fotovoltaico - Fortlev Solar
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(Parte.Mwp)}
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(
                    Parte.GeradorFotoVoltaicoWpValorTotal
                  )}
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(
                    Parte.GeradorFotoVoltaicoWpCapex
                  )}
                  %
                </Text>
              </View>
              <View style={EstimatedCapex.TableRow}>
                <Text style={EstimatedCapex.TextTableTitle}>
                  Projeto e Homologações - Merx
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(Parte.ProjetoHomologacaoWp)}
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(
                    Parte.ProjetoHomologacaoWpValorTotal
                  )}
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(
                    Parte.ProjetoHomologacaoWpCapex
                  )}
                  %
                </Text>
              </View>
              <View style={EstimatedCapex.TableRow}>
                <Text style={EstimatedCapex.TextTableTitle}>
                  EPC - Engenharia, Suprimentos e Construção (Estimado)
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(Parte.EPCWP)}
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(Parte.EPCWPValorTotal)}
                </Text>
                <Text style={EstimatedCapex.TextTable}>
                  {formatNumberToBrazilCurrency(Parte.EPCWPCapex)}%
                </Text>
              </View>

              <View style={EstimatedCapex.TableFooter}>
                <Text style={EstimatedCapex.TextTableTitle}>
                  CAPEX turn-key
                </Text>
                <Text style={EstimatedCapex.TextTableHead}>
                  {formatNumberToBrazilCurrency(Parte.PotenciaTotal)}
                </Text>
                <Text style={EstimatedCapex.TextTableHead}>
                  {" "}
                  {formatNumberToBrazilCurrency(Parte.ValorTotal)}
                </Text>
                <Text style={EstimatedCapex.TextTableHead}>
                  {" "}
                  {formatNumberToBrazilCurrency(Parte.CapexTotal)}%
                </Text>
              </View>
            </View>
          ))}
          <Text style={EstimatedCapex.OBS}>
            Obs: CAPEX de EPC estimado, a ser confirmado com integrador parceiro
            após visita técnica ao local
          </Text>
        </View>
      </Page>

      {capex.length > 2 && (
        <Page orientation="landscape">
          <View style={EstimatedCapex.Page}>
            <View style={FeasibilityAnalysis.TitleBar}>
              <Text style={FeasibilityAnalysis.TitlePage}>Capex Estimado</Text>
              <Image src={barTerm} style={FeasibilityAnalysis.Bar} />
            </View>
            <View style={EstimatedCapex.FullText}>
              <Text style={EstimatedCapex.MainText}>
                Fornecimento, instalação e comissionamento de usinas solar
                fotovoltaica com capacidade total de{" "}
              </Text>
              <Text style={EstimatedCapex.Bold}>
                {formatNumberToBrazilCurrency(proposalData.potenciaUsina)}MWp /{" "}
                {formatNumberToBrazilCurrency(proposalData.demandaUsina)}MW
              </Text>

              <Text style={EstimatedCapex.MainText}>
                , no formato de parceria com um integrador para entrega turn-key
                FULL EPC.
              </Text>
            </View>

            {capex.slice(2, 4).map((Parte, index) => (
              <View style={EstimatedCapex.TableViability}>
                <View style={EstimatedCapex.TableHead}>
                  <Text style={EstimatedCapex.TextTableMainInfos}>
                    UFV 0{index + 3} - {Parte.Nome}
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>Potência</Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {formatNumberToBrazilCurrency(Parte.PotenciaNome)}MWp
                  </Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}> </Text>
                  <Text style={EstimatedCapex.TextTableHead}>R$/Wp</Text>
                  <Text style={EstimatedCapex.TextTableHead}>R$</Text>
                  <Text style={EstimatedCapex.TextTableHead}>% CAPEX</Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    Gerador Fotovoltaico - Fortlev Solar
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.Mwp)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.GeradorFotoVoltaicoWpValorTotal
                    )}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.GeradorFotoVoltaicoWpCapex
                    )}
                    %
                  </Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    Projeto e Homologações - Merx
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.ProjetoHomologacaoWp)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.ProjetoHomologacaoWpValorTotal
                    )}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.ProjetoHomologacaoWpCapex
                    )}
                    %
                  </Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    EPC - Engenharia, Suprimentos e Construção (Estimado)
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.EPCWP)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.EPCWPValorTotal)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.EPCWPCapex)}%
                  </Text>
                </View>

                <View style={EstimatedCapex.TableFooter}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    CAPEX turn-key
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {formatNumberToBrazilCurrency(Parte.PotenciaTotal)}
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {" "}
                    {formatNumberToBrazilCurrency(Parte.ValorTotal)}
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {" "}
                    {formatNumberToBrazilCurrency(Parte.CapexTotal)}%
                  </Text>
                </View>
              </View>
            ))}
            <Text style={EstimatedCapex.OBS}>
              Obs: CAPEX de EPC estimado, a ser confirmado com integrador
              parceiro após visita técnica ao local
            </Text>
          </View>
        </Page>
      )}

      {capex.length > 4 && (
        <Page orientation="landscape">
          <View style={EstimatedCapex.Page}>
            <View style={FeasibilityAnalysis.TitleBar}>
              <Text style={FeasibilityAnalysis.TitlePage}>Capex Estimado</Text>
              <Image src={barTerm} style={FeasibilityAnalysis.Bar} />
            </View>
            <View style={EstimatedCapex.FullText}>
              <Text style={EstimatedCapex.MainText}>
                Fornecimento, instalação e comissionamento de usinas solar
                fotovoltaica com capacidade total de{" "}
              </Text>
              <Text style={EstimatedCapex.Bold}>
                {formatNumberToBrazilCurrency(proposalData.potenciaUsina)}MWp /{" "}
                {formatNumberToBrazilCurrency(proposalData.demandaUsina)}MW
              </Text>

              <Text style={EstimatedCapex.MainText}>
                , no formato de parceria com um integrador para entrega turn-key
                FULL EPC.
              </Text>
            </View>

            {capex.slice(4, 6).map((Parte, index) => (
              <View style={EstimatedCapex.TableViability}>
                <View style={EstimatedCapex.TableHead}>
                  <Text style={EstimatedCapex.TextTableMainInfos}>
                    UFV 0{index + 5} - {Parte.Nome}
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>Potência</Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {formatNumberToBrazilCurrency(Parte.PotenciaNome)}MWp
                  </Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}> </Text>
                  <Text style={EstimatedCapex.TextTableHead}>R$/Wp</Text>
                  <Text style={EstimatedCapex.TextTableHead}>R$</Text>
                  <Text style={EstimatedCapex.TextTableHead}>% CAPEX</Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    Gerador Fotovoltaico - Fortlev Solar
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.Mwp)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.GeradorFotoVoltaicoWpValorTotal
                    )}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.GeradorFotoVoltaicoWpCapex
                    )}
                    %
                  </Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    Projeto e Homologações - Merx
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.ProjetoHomologacaoWp)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.ProjetoHomologacaoWpValorTotal
                    )}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(
                      Parte.ProjetoHomologacaoWpCapex
                    )}
                    %
                  </Text>
                </View>
                <View style={EstimatedCapex.TableRow}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    EPC - Engenharia, Suprimentos e Construção (Estimado)
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.EPCWP)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.EPCWPValorTotal)}
                  </Text>
                  <Text style={EstimatedCapex.TextTable}>
                    {formatNumberToBrazilCurrency(Parte.EPCWPCapex)}%
                  </Text>
                </View>

                <View style={EstimatedCapex.TableFooter}>
                  <Text style={EstimatedCapex.TextTableTitle}>
                    CAPEX turn-key
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {formatNumberToBrazilCurrency(Parte.PotenciaTotal)}
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {" "}
                    {formatNumberToBrazilCurrency(Parte.ValorTotal)}
                  </Text>
                  <Text style={EstimatedCapex.TextTableHead}>
                    {" "}
                    {formatNumberToBrazilCurrency(Parte.CapexTotal)}%
                  </Text>
                </View>
              </View>
            ))}
            <Text style={EstimatedCapex.OBS}>
              Obs: CAPEX de EPC estimado, a ser confirmado com integrador
              parceiro após visita técnica ao local
            </Text>
          </View>
        </Page>
      )}

      <Page orientation="landscape">
        <View style={TermPageStyles.TermPage}>
          <View style={TermPageStyles.TermAndLogo}>
            <View>
              <Text style={TermPageStyles.Accept}>Termo de aceite</Text>
              <Image src={barTerm} style={TermPageStyles.FortlevLogo} />
            </View>
            <Image
              src={LogoFortlevSolar}
              style={TermPageStyles.FortlevLogo}
            ></Image>
          </View>

          <Text style={TermPageStyles.TextsAccept}>
            A presente proposta comercial contempla a implementação do sistema
            fotovoltaico.{" "}
          </Text>
          <Text style={TermPageStyles.TextsAccept}>
            O valor total apresentado na proposta comercial é em moeda nacional
            (Real) de produtos nacionalizados, já incluídos todos os custos
            diretos e indiretos do investidor.
          </Text>
          <Text style={TermPageStyles.TextsAcceptBold}>
            Esta proposta passa a ter caráter de compromisso para assinatura do
            contrato e implantação do empreendimento.
          </Text>
          <Text style={TermPageStyles.TextsAccept}>
            O cliente e a FORTLEV ENERGIA SOLAR aceitam as condições
            mencionadas.
          </Text>
          <Text style={TermPageStyles.TextsAcceptBold}>
            {" "}
            APROVAÇÃO DA PROPOSTA
          </Text>
          <Text style={TermPageStyles.TextData}>
            {" "}
            Serra (ES), _____ de ________________ de 20____.
          </Text>
          <View style={TermPageStyles.DocSign}>
            <View>
              <Text style={TermPageStyles.AssignCamp}>
                ___________________________
              </Text>
              <Text>
                CLIENTE: {proposalData.clientName && proposalData.clientName}
              </Text>
              <Text>CNPJ/CPF: </Text>
            </View>
            <View style={TermPageStyles.SignFortlev}>
              <Text style={TermPageStyles.AssignCamp}>
                ________________________
              </Text>
              <Text>FORTLEV ENERGIA SOLAR </Text>
              <Text style={TermPageStyles.TextCenter}>Maykow Torres</Text>
            </View>
          </View>
        </View>
      </Page>
      {getUserContact && (
        <Page orientation="landscape">
          <View style={ContactPagesStyle.AllContent}>
            <View style={ContactPagesStyle.TermAndLogo}>
              <View>
                <Text style={ContactPagesStyle.Contact}>Contato(s)</Text>
                <Image src={barTerm} style={ContactPagesStyle.Bar} />
              </View>
              <Image
                src={LogoFortlevSolar}
                style={ContactPagesStyle.Bar}
              ></Image>
            </View>
            <View style={ContactPagesStyle.ContactBox}>
              <Image
                src={getUserContact.image}
                style={ContactPagesStyle.ImageContact}
              />
              <View style={ContactPagesStyle.BoxInfosContact}>
                <Text style={ContactPagesStyle.BlueFortlevText}>
                  {getUserContact.name}
                </Text>
                <Text style={ContactPagesStyle.BlueFortlevText}>
                  {getUserContact.office}
                </Text>
                <Link style={ContactPagesStyle.EmailLink}>
                  {getUserContact.email}
                </Link>
                <Text style={ContactPagesStyle.ContactNumber}>
                  {formatCellPhoneNumberToBrazilCurrency(getUserContact.number)}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      )}
    </Document>
  );
}
