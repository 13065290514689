import React from "react";
import styles from "./style.module.css";
import Image from "../../Assets/partnerAdvantage.svg";
import Image1 from "../../Assets/partnerAdvantage1.svg";
import Image2 from "../../Assets/partnerAdvantage2.svg";
import Image3 from "../../Assets/partnerAdvantage3.svg";

export default function PartnerAdvantages() {
  const advantages = [
    {
      image: Image,
      alt: "Imagem de um cofrinho",
      title: "Comissão Recorrente",
      text:
        "Ganhe mais com nosso plano de comissão recorrente. Receba recompensas mensais pelo sucesso das suas vendas.",
    },
    {
      image: Image1,
      alt: "Imagem de um computador e um celular",
      title: "Acesso ao simulador",
      text:
        "Descubra quanto pode economizar com nosso simulador exclusivo. Tenha insights precisos e otimize seus investimentos.",
    },
    {
      image: Image2,
      alt: "Imagem de uma lampada",
      title: "Mentoria de Vendas",
      text:
        "Receba orientações exclusivas. Melhore suas estratégias de vendas com insights de um líder de mercado.",
    },
    {
      image: Image3,
      alt: "Imagem de um chapéu de formatura",
      title: "Curso de Vendas",
      text:
        "Aprimore suas habilidades de vendas com nosso curso especializado. Domine as melhores práticas para vender usinas.",
    },
  ];
  return (
    <section className={styles.allContent} id="partner">
      <div className={styles.content}>
        <h2>Conheça as vantagens de ser nosso parceiro</h2>
        <p className={styles.advantageP}>
          Uma Parceria que Faz a Diferença: Benefícios, Crescimento e Suporte.
        </p>
        <div className={styles.partnerAdvantages}>
          {advantages.map((advantage) => [
            <div className={styles.advantage}>
              <span>
                {" "}
                <img src={advantage.image} alt={advantage.alt} />
              </span>
              <h3>{advantage.title}</h3>
              <p>{advantage.text}</p>
            </div>,
          ])}
        </div>
        <a
          href="https://prime.merxenergia.com.br/"
          target="blank"
          className={styles.knowMore}
        >
          Saiba mais
        </a>
      </div>
    </section>
  );
}
