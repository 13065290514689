import React, { Component } from "react";
import "./style.css";
import NavBar from "../../Components/Navbar/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import sunPower from "../../Assets/sunPower.png";
import locPower from "../../Assets/locPower.png";
import datePower from "../../Assets/datePower.png";
import moneyPower from "../../Assets/moneyPower.png";

import TermsModal from "../../Components/CreateNewMarket/index";

import LoginModal from "../../Components/MarketModal/index";

import faradayApi from "../../Services/faradayApi";

import Footer from "../../Components/Footer/index";

class App extends Component {
  constructor() {
    super();
    this.state = {
      cardMarketArray: [],
    };
  }

  loginModalRef = (obj) => {
    this.showModal = obj && obj.handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  termsModalRef = (obj) => {
    this.showTermsModal = obj && obj.handleShow;
  };

  onTermsClick = () => {
    this.showTermsModal();
  };

  async componentDidMount() {
    const response = await faradayApi.get("cardMarket");
    this.setState({ cardMarketArray: response.data });
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <div className="master-containerMarket">
        <LoginModal ref={this.loginModalRef}></LoginModal>
        <TermsModal ref={this.termsModalRef}></TermsModal>
        <NavBar changeColor="true" />
        <div className="masterUniqueButton">
          <div className="headerButtons">
            <div className="uniqueButton activeMarketButton">Todos</div>
            <div className="uniqueButton">Compra</div>
            <div className="uniqueButton">Venda</div>
          </div>
        </div>

        <div style={{ justifyContent: "center", display: "flex" }}>
          <p className="titleMarketPlace">Energia</p>
        </div>

        <Carousel responsive={responsive} className="CarouselCardMarket">
          {this.state.cardMarketArray.map((cardMarket) => (
            <div className="cardMarket" onClick={this.onLoginClick}>
              <p className="titleCardMarket">{cardMarket.Titulo}</p>

              <div className="CardMarketContent">
                <FontAwesomeIcon
                  icon={faBolt}
                  className="whiteIcon"
                  style={{ marginLeft: "3px" }}
                />
                <p style={{ marginLeft: "16px" }}>{cardMarket.Valor}</p>
              </div>

              <div className="CardMarketContent">
                <img src={locPower} className="iconMarket" alt="sun" />
                <p style={{ marginLeft: "15.67px" }}>{cardMarket.Regiao}</p>
              </div>

              <div className="CardMarketContent">
                <img src={datePower} className="iconMarket" alt="sun" />
                <p style={{ marginLeft: "13px" }}>
                  {cardMarket.DataInicio} - {cardMarket.DataFim}
                </p>
              </div>

              <div className="CardMarketContent">
                <img
                  src={moneyPower}
                  style={{ marginLeft: "3px" }}
                  className="iconMarket"
                  alt="sun"
                />
                <p style={{ marginLeft: "16px" }}>{cardMarket.Preco}</p>
              </div>
            </div>
          ))}
        </Carousel>

        <button onClick={this.onTermsClick} className="cadMarket">
          Criar nova oferta
        </button>

        <div style={{ justifyContent: "center", display: "flex" }}>
          <p className="titleMarketPlace">Usina</p>
        </div>

        <Carousel responsive={responsive} className="CarouselCardMarket">
          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2020</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>

          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>0,5 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2021</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>
          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>2 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/06/2021</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>
          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1,5 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2022</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>

          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1,5 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2022</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>
        </Carousel>

        <div style={{ justifyContent: "center", display: "flex" }}>
          <p className="titleMarketPlace">Investidor</p>
        </div>

        <Carousel responsive={responsive} className="CarouselCardMarket">
          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2020</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>

          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2020</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>
          <div className="cardMarket">
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2020</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>
          <div className="cardMarket" style={{ marginBottom: "15px" }}>
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2020</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>

          <div className="cardMarket" style={{ marginBottom: "15px" }}>
            <p className="titleCardMarket">Usina FV</p>

            <div className="CardMarketContent">
              <img
                src={sunPower}
                className="iconMarket"
                alt="sun"
                style={{ marginLeft: "-1.8px" }}
              />
              <p style={{ marginLeft: "13px" }}>1 MWP</p>
            </div>

            <div className="CardMarketContent">
              <img src={locPower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "15.67px" }}>NE</p>
            </div>

            <div className="CardMarketContent">
              <img src={datePower} className="iconMarket" alt="sun" />
              <p style={{ marginLeft: "13px" }}>01/01/2020</p>
            </div>

            <div className="CardMarketContent">
              <img
                src={moneyPower}
                style={{ marginLeft: "3px" }}
                className="iconMarket"
                alt="sun"
              />
              <p style={{ marginLeft: "16px" }}>A combinar</p>
            </div>
          </div>
        </Carousel>

        <Footer />
      </div>
    );
  }
}

export default App;
