import React from "react";
import { Modal } from "react-bootstrap";

import CardContent from "../CardContentInside/index";
import CoustCardRowModal from "../CoustCardRowModal/index";
import AnaliseLine from "../AnaliseLine/index";
import DownFiles from "../DownFiles/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

class cardContentModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    var myDate = props.date;
    var myContent = props.content;
    var myStatus = props.status;

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      myDate,
      myContent,
      myStatus,
      info: this.props.info,
    };
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const info = this.state.info;

    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogClassName="custom-dialog-Info"
          className="custom-dialog-info"
          centered
          size="lg"
        >
          <Modal.Body>
            <div className="container-modal-info-master">
              <div className="container-modal-Info">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="close-icon-card"
                  onClick={this.handleClose}
                  style={{ position: "relative", left: "-20px", top: "5px" }}
                />
                <CardContent
                  date={this.state.myDate}
                  content={this.state.myContent}
                  status={this.state.myStatus}
                  info={info}
                  fullWidth="true"
                />

                {info !== undefined && (
                  <CoustCardRowModal
                    bigger="false"
                    CustoCativo={info.costCativo}
                    CustoLivre={info.costLivre}
                    Economia={info.costCativo - info.costLivre}
                    format="true"
                  />
                )}
                {info !== undefined && <AnaliseLine info={info} />}
                {info !== undefined && <DownFiles info={info} />}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default cardContentModal;
