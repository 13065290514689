import React from "react";
import styles from "./style.module.css";
import ImageSelfProduction from "../../Assets/imgSelfProductionBenefits.png";
import ImageSelfProduction1 from "../../Assets/imgSelfProductionBenefits1.png";
import ImageSelfProduction2 from "../../Assets/imgSelfProductionBenefits2.png";
import ImageSelfProduction3 from "../../Assets/imgSelfProductionBenefits3.png";
export default function SelfProductionBenefits() {
  const benefits = [
    {
      title: "Redução de Custos de Energia",
      text:
        "A autoprodução permite reduzir a dependência das tarifas de energia do mercado, o que pode resultar em economia significativa na conta de luz. Com o tempo, a economia gerada pode cobrir o custo inicial dos equipamentos e da instalação.",
      image: ImageSelfProduction,
    },
    {
      title: "Proteção contra Variações de Preço no Mercado",
      text:
        "Ao produzir sua própria energia, o consumidor se protege contra aumentos nas tarifas de eletricidade e flutuações de preços, garantindo previsibilidade e controle sobre seus gastos com energia.",
      image: ImageSelfProduction1,
    },
    {
      title: "Benefícios Ambientais e Sustentabilidade",
      text:
        "Produzir energia a partir de fontes renováveis, como solar e eólica, reduz a pegada de carbono e contribui para um ambiente mais limpo. Isso é cada vez mais valorizado tanto por empresas quanto por consumidores.",
      image: ImageSelfProduction2,
    },
    {
      title: "Independência Energética e Confiabilidade",
      text:
        "A autoprodução aumenta a independência em relação às redes de distribuição, oferecendo maior segurança contra falhas e interrupções de fornecimento. Isso é especialmente vantajoso para empresas e residências em áreas com infraestrutura instável ou sujeitas a apagões.",
      image: ImageSelfProduction3,
    },
  ];

  return (
    <section className={styles.allContent}>
      <div className={styles.content}>
        {benefits &&
          benefits.map((benefit, index) => (
            <div
              key={index}
              className={`${styles.benefit} ${
                index % 2 !== 0 ? styles.benefitOdd : ""
              }`}
            >
              <div>
                <h3>{benefit.title}</h3>
                <p>{benefit.text}</p>{" "}
              </div>
              <img
                src={benefit.image}
                alt="Imagem de uma usina solar de energia"
              />
            </div>
          ))}
      </div>
    </section>
  );
}
