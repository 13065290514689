import React, { useEffect, useState } from "react";

import "./style.css";

export default function ConsumeTable({ monthlyData }) {
  const [monthData, setMonthData] = useState(monthlyData);

  useEffect(() => {
    setMonthData(monthlyData);
  }, [monthlyData]);

  function getMonthName(month, year) {
    switch (month) {
      case 1:
        return "Janeiro " + year;
      case 2:
        return "Fevereiro " + year;
      case 3:
        return "Março " + year;
      case 4:
        return "Abril " + year;
      case 5:
        return "Maio " + year;
      case 6:
        return "Junho " + year;
      case 7:
        return "Julho " + year;
      case 8:
        return "Agosto " + year;
      case 9:
        return "Setembro " + year;
      case 10:
        return "Outubro " + year;
      case 11:
        return "Novembro " + year;
      case 12:
        return "Dezembro " + year;
      default:
        return "Mês inválido";
    }
  }

  const monthFilteredData = monthlyData
    .filter(
      (value) =>
        value.status === "FINALIZADO" && value.isMonthRetroactive === false
    )
    .slice(-12);

  return (
    <div className="consumeTable-container">
      <div className="consume-content-table" style={{ width: "100%" }}>
        <div className="mytable-row">
          <p
            className="mytable-item-title"
            style={{ display: "flex", justifyContent: "center" }}
          >
            Mês/Ano
          </p>
          <p className="mytable-item-title">Contrato</p>
          <p className="mytable-item-title">Consumo</p>
          <p className="mytable-item-title">Ajuste</p>
        </div>

        {monthFilteredData?.map(
          (item, i) =>
            (parseFloat(item.consume) === 0 || item.month !== "-") && (
              <div className="mytable-row" key={i}>
                <p
                  className="mytable-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {getMonthName(item.month, item.year)}
                </p>
                <p
                  className="mytable-item"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {parseFloat(item.volumeToInvoice).toLocaleString("pt-BR", {
                    style: "decimal",
                    useGrouping: false,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  {" MWh"}
                </p>
                <p
                  className="mytable-item"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {parseFloat(item.relativeConsume).toLocaleString("pt-BR", {
                    style: "decimal",
                    useGrouping: false,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  {" MWh"}
                </p>
                <p
                  className="mytable-item"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {(item.volumeToInvoice - item.relativeConsume).toLocaleString(
                    "pt-BR",
                    {
                      style: "decimal",
                      useGrouping: false,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  {" MWh"}
                </p>
              </div>
            )
        )}
      </div>
    </div>
  );
}
