import React, { useState, useEffect } from "react";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Upload from "../../Assets/DownIcon.png";

export const DocumentLink = ({ title, url }) => {
  // Estado para monitorar a largura da janela
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  // Atualiza o estado sempre que a janela for redimensionada
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 750);
    };

    window.addEventListener("resize", handleResize);

    // Remove o listener quando o componente desmontar
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDownload = async (report) => {
    const response = await fetch(report.url);
    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = report.name + "." + response.url.split(".")[4];
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
  };

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: isMobile ? "center" : "space-between",
        gap: isMobile ? "10px" : "50px",
        width: "70%",
        padding: "10px 20px",
        fontSize: "14px",
        marginTop: "10px",
        border: "2px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "3px",
        flexWrap: "wrap",
      }}
    >
      <p
        style={{
          color: "#000",
          fontSize: "15px",
          fontWeight: "400",
          width: "300px",
          textAlign: isMobile ? "center" : "left",
          margin: "0",
        }}
      >
        {title}
      </p>
      {url ? (
        <button
          style={{
            color: "#0067CC",
            fontSize: "1em",
            mbuttonrgin: 0,
            border: "none",
            outline: "0",
            backgroundColor: "transparent",
          }}
          onClick={() => handleDownload(url)}
          download="Garantia-Contrato"
          target="_blank"
          rel="noopener noreferrer"
          className="clientInfo-box-content-subtitle"
        >
          <img
            src={Upload}
            alt="upload"
            icon={faUpload}
            style={{
              color: "#0067CC",
              fontSize: "15px",
              fontWeight: "normal",
              cursor: "pointer",
              height: "17px",
              position: "relative",
              left: "3px",
              top: "-1px",
              backgroundColor: "transparent",
            }}
          />
        </button>
      ) : (
        <p
          className="clientInfo-box-content-subtitle"
          style={{
            color: "#0067CC",
            fontSize: "10px",
            height: "17px",
            margin: isMobile ? "auto" : "0",
          }}
        >
          Indisponível
        </p>
      )}
    </div>
  );
};
