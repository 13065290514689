import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useHighchartsDefault from "../../Hooks/useHighchartsDefault";
import { shortMesesExt } from "../../utils/utilityFunctions";

const GraphContainer = styled.div`
  width: 100%;
  padding-top: 2em;
`;

const AgenteChart = ({
  options,
  data,
}: {
  options: { title: string };
  data: { id: number; loggedAt: string; value: number; codPerfil: number }[];
}) => {
  const months = data.slice(-12).map((e) => {
    const date = new Date(e.loggedAt);
    return shortMesesExt[date.getMonth()] + " " + date.getFullYear();
  });

  useHighchartsDefault(Highcharts);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: { type: "column" },

    legend: { enabled: false },

    title: {
      text: options.title,
    },

    subtitle: { text: "Fonte: CCEE" },

    credits: {
      enabled: false,
    },

    xAxis: {
      showEmpty: true,
      categories: months,
    },

    yAxis: {
      title: {
        text: "Valor (MWh)",
      },
      labels: {
        format: "{value} MWh",
      },
    },

    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        return `${this.x}<br/><b>${this.y.toFixed(2)} MWh</b>`;
      },
    },

    series: {
      label: { enabled: true },
      data: data.map((e) => e.value).slice(-12),
      zones: [
        {
          value: 0,
          color: "#f91010",
        },
        {
          color: "#1fa824",
        },
      ],
    },
  };

  return (
    <GraphContainer>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </GraphContainer>
  );
};

export default AgenteChart;
