import React from "react";
import { StatusMigrationArea } from "./styled";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import iconpending from "../../Assets/iconpending.png";
import iconchecked from "../../Assets/iconcheck.png";

import iconapproved from "../../Assets/iconapproved.svg";
import iconClientApproved from "../../Assets/client-approved.png";
import iconClientBankAccount from "../../Assets/client-bank-account.png";
import iconClientBolt from "../../Assets/client-bolt.png";
import iconClientElectricVehicle from "../../Assets/client-electric-vehicle.png";
import iconClientHint from "../../Assets/client-hint.png";
import iconClientSocket from "../../Assets/client-socket.png";

export const StatusMigrationAreaComponent = ({
  companyDataCheck,
  type,
}: {
  companyDataCheck: any;
  type: string;
}) => {
  return (
    <StatusMigrationArea
      style={{ gap: type === "measuring-point" ? "60px" : "80px" }}
    >
      <div className="current-status">
        <div className="migrations-hint">
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                Na fase inicial, os clientes adquirem energia no Ambiente de
                Contratação Livre (ACL) através da compra direta.
              </Tooltip>
            }
          >
            <img src={iconClientHint} alt="Ícone" />
          </OverlayTrigger>
        </div>
        {companyDataCheck?.energiaCheck ? (
          <img src={iconchecked} width={24} alt="approved" />
        ) : (
          <img src={iconpending} width={24} alt="pending" />
        )}
        <div className="migration-header acl">
          <span className="step-name">ENERGIA ACL</span>
        </div>
        <img src={iconClientBolt} alt="Ícone" />
        <p className="step-description">Compra de energia</p>
        <div className="bar-divider" />
      </div>

      <div className="current-status">
        {companyDataCheck?.distribuidoraCheck ? (
          <img src={iconchecked} width={24} alt="approved" />
        ) : (
          <img src={iconpending} width={24} alt="pending" />
        )}
        <div className="migration-header">
          <div>
            <div className="migrations-hint">
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    A distribuidora realiza ajustes nos contratos e gerencia as
                    transições para garantir a conformidade regulatória.
                  </Tooltip>
                }
              >
                <img src={iconClientHint} alt="Ícone" />
              </OverlayTrigger>
            </div>
            <span className="step-name">DISTRIBUIDORA</span>
          </div>
        </div>

        <img src={iconClientElectricVehicle} alt="Ícone" />
        <p className="step-description">Ajuste e gestão de contratos</p>
        <div className="bar-divider" />
      </div>

      <div className="current-status">
        {companyDataCheck?.financeiroCheck ? (
          <img src={iconchecked} width={24} alt="approved" />
        ) : (
          <img src={iconpending} width={24} alt="pending" />
        )}
        <div className="migration-header">
          <div>
            <div className="migrations-hint">
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Os clientes abrem uma conta específica na Conta Trianon para
                    gerenciar as informações financeiras relacionadas ao consumo
                    de energia.
                  </Tooltip>
                }
              >
                <img src={iconClientHint} alt="Ícone" />
              </OverlayTrigger>
            </div>
            <span className="step-name">INFORMAÇÕES FINANCEIRAS</span>
          </div>
        </div>

        <img src={iconClientBankAccount} alt="Ícone" />
        <p className="step-description">Abertura da Conta Trianon</p>
        <div className="bar-divider" />
      </div>

      <div className="current-status">
        {companyDataCheck?.cceeCheck ? (
          <img src={iconchecked} width={24} alt="approved" />
        ) : (
          <img src={iconpending} width={24} alt="pending" />
        )}
        <div className="migration-header">
          <span className="step-name">CCEE</span>
          <div className="migrations-hint">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  O processo de migração inclui a submissão e aprovação dos
                  contratos pela Câmara de Comercialização de Energia Elétrica
                  (CCEE).
                </Tooltip>
              }
            >
              <img src={iconClientHint} alt="Ícone" />
            </OverlayTrigger>
          </div>
        </div>
        <img src={iconClientApproved} alt="Ícone" />
        <p className="step-description">Processo de aprovação na CCEE</p>
        <div className="bar-divider" />
      </div>

      <div className="current-status">
        {companyDataCheck?.medicaoCheck ? (
          <img src={iconchecked} width={24} alt="approved" />
        ) : (
          <img src={iconpending} width={24} alt="pending" />
        )}
        <div className="migration-header">
          <div>
            <div className="migrations-hint">
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    O processo de migração inclui a submissão e aprovação dos
                    contratos pela Câmara de Comercialização de Energia Elétrica
                    (CCEE).
                  </Tooltip>
                }
              >
                <img src={iconClientHint} alt="Ícone" />
              </OverlayTrigger>
            </div>
            <span className="step-name">ADEQUAÇÃO DA MEDIÇÃO</span>
          </div>
        </div>
        <img src={iconClientSocket} alt="Ícone" />
        <p className="step-description">Ajuste físico da medição</p>
        <div className="bar-divider" />
      </div>

      <div className="current-status">
        {companyDataCheck?.aprovacaoCheck ? (
          <img src={iconchecked} width={24} alt="approved" />
        ) : (
          <img src={iconpending} width={24} alt="pending" />
        )}
        <span className="step-name"> APROVAÇÃO</span>
        <img src={iconapproved} alt="Ícone" />
        <p className="step-description">Migração completa</p>
      </div>
    </StatusMigrationArea>
  );
};
