import React from "react";
import "./style.css";

export default function ConsumeGraph(props) {
  const info = props.info;

  return (
    <div className="AnaliseTable-container">
      <div
        className="consume-header"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p className="consume-title-analise">Análise de contratação</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "14px",
          marginTop: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Energia contratada
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.contractedEnergy && !isNaN(info.contractedEnergy)
              ? parseFloat(info.contractedEnergy)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Take mínimo
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.takeMin && !isNaN(info.takeMin)
              ? parseFloat(info.takeMin)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Take máximo
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.takeMax && !isNaN(info.takeMax)
              ? parseFloat(info.takeMax)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Energia medida
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.measuredEnergy && !isNaN(info.measuredEnergy)
              ? parseFloat(info.measuredEnergy)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Proinfa
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.proinfa && !isNaN(info.proinfa)
              ? parseFloat(info.proinfa)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Perdas (3%)
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.looses && !isNaN(info.looses)
              ? parseFloat(info.looses)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start ",
            }}
          >
            Energia Total
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.relativeConsume && !isNaN(info.relativeConsume)
              ? parseFloat(info.relativeConsume)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>
      </div>
    </div>
  );
}
