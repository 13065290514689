import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";

export function ConsumoReativoMeasuringPoint({
  monthData,
}: {
  monthData: any[];
}) {
  const monthsData = monthData;

  const consumePeakReactive = monthsData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.consumePeakReactive;
      return [x, y];
    });

  const consumeOffPeakReactive = monthsData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.consumeOffPeakReactive;
      return [x, y];
    });

  const months = consumePeakReactive.map((e) => {
    return e[0];
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: { type: "line" },

    title: {
      text: "",
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      // title: {
      //   text: "Mês",
      // },
      categories: months,
    },

    // yAxis: {
    //   title: {
    //     text: "Consumo (kVARh)",
    //   },
    //   labels: {
    //     format: "{value} kVARh",
    //   },
    // },
    yAxis: {
      labels: {
        format: "{value:,.0f} kVARh",
      },
      gridLineWidth: 0.5,
      zIndex: -100,
      gridLineColor: "#838f7f",
      title: {
        text: "",
      },
    },

    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}</b><br/>",
    },

    series: [
      {
        name: "Consumo reativo fora ponta",
        data: consumeOffPeakReactive,
        // color: "#444",
        color: "blue",
        // zones: [
        //   {
        //     color: "#444",
        //   },
        // ],
        tooltip: {
          valueSuffix: " kVARh",
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          symbol: "circle",
        },
        pointPlacement: 0,
        dataLabels: {
          enabled: true,
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.0f}", // Exibe o valor do ponto de dados
          y: -10,
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
      {
        name: "Consumo reativo ponta",
        data: consumePeakReactive,
        // color: "#007bff",

        color: "#f58700",
        // zones: [
        //   {
        //     color: "#007bff",
        //   },
        // ],
        tooltip: {
          valueSuffix: " kVARh",
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          symbol: "square",
        },
      },
    ],
  };

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}
