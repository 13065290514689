import React from 'react';
import { Link } from 'react-router-dom';
import BlackLogo from '../../Assets/blackLogo.png';


import './style.css';

export default function NotFound(){
    return(
        <div className="pageNotFound-container-master">
            <div className="pageNotFound-container">
                <img src={BlackLogo} alt="logo" className="logo-size-404" />
                <p className="title-404" >Ooops...</p>
                <p className="title-404-2" >Error 404 - Page Not Found</p>
                <p className="subtitle-404">The requested URL was not found on our server :(</p>
                <p className="subtitle-404">Do you think this is a mistake?</p>
                <p className="subtitle-404">Please, </p>
                <Link  to="/"> <button className="Button-notFound">Contact Us</button> </Link>
                <p className="subtitle-404">or </p>
                <Link  to="/"> <button className="Button-notFound">Go to Home Page</button> </Link>
            </div>
        </div>
    );
}