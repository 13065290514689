import React, { useState, useEffect } from "react";
import {
  ContentContainer,
  CardCost,
  TitleCardCost,
  ContainerCardCostValue,
  CardCostRs,
  CardCostValue,
} from "../../pages/ClientDashboard/styled";
import { IMonthlyData } from "../../pages/ClientDashboard/interfaces";

function formatCurrency(n: number | undefined) {
  if (n) {
    return new Intl.NumberFormat("pt-BR", {}).format(n);
  }
}

function CustoEconomia({ companyData }: any) {
  return (
    <div className="economia-container">
      <div className="economia-card">
        <h1>Custo cativo</h1>
        <h2>
          {companyData?.totalCativo?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
      </div>
      <div className="economia-card">
        <h1>Custo livre</h1>
        <h2>
          {companyData?.totalLivre?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
      </div>
      <div className="economia-card">
        <h1>Economia</h1>
        <div className="economia-value">
          <h2>
            {companyData?.economy?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </h2>
          <h3
            className={
              companyData?.economy >= 0
                ? "economia-positivo"
                : "economia-negativo"
            }
          >
            {(Math.abs(companyData?.totalCativo) > 0
              ? companyData?.economy / companyData?.totalCativo
              : 0
            )?.toLocaleString("pt-BR", { style: "percent" })}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default CustoEconomia;
