import React, { useState, useEffect } from "react";
import NavBar from "../../Components/Navbar/index";

import TrilhaDeConhecimento from "../../Components/TrilhaDeConhecimento/index";
import Footer from "../../Components/Footer/index";

import "./style.css";

import faradayApi from "../../Services/faradayApi";
import ParterInfos from "../../Components/ParterInfos";
import { getToken, getUserId } from "../../Services/auth";
import fileText from "../../Assets/fileText.svg";
import sendFile from "../../Assets/sendFile.svg";
import { Button, Modal, Spinner } from "react-bootstrap";
import SendModal from "../../SendModal";
import ClientesIndicados from "../../Components/ClientesIndicados/index";
import { FiDownload, FiTrash2 } from "react-icons/fi";
import DeletePartnerDocModal from "../../Components/DeletePartnerDocModal";
import MigrationParter from "../../Components/MigrationPartner/index";
import { useHistory } from "react-router-dom";
import deleteIcon from "../../Assets/deleteIcon.svg";

export default function Main() {
  const history = useHistory();
  const [partnerCompanyName, setPartnerCompanyName] = useState("");
  const [partnerCompanyCnpj, setPartnerCompanyCnpj] = useState("");
  const [partnerCompanyId, setPartnerCompanyId] = useState("");
  const [partnerDocuments, setPartnerDocuments] = useState([]);
  const [partnerName, setPartnerName] = useState("");
  const [userCpf, setUserCpf] = useState("");
  const [economiaTotal, setEconomiaTotal] = useState(0);
  const [valorRecebido, setValorRecebido] = useState(0);
  const [valorAReceber, setValorAReceber] = useState(0);
  const [logo, setLogo] = useState("");
  const [Affiliates, setAffiliates] = useState([]);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [inicioLivre, setInicioLivre] = useState([]);

  const [Sigla, setSigla] = useState("");
  const [role, setRole] = useState("");

  //For invoice
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [office, setOffice] = useState("");
  const [email, setEmail] = useState("");

  // const [companyCNPJ, setCompCNPJ] = useState("");
  // const [Submercado, setSubmercado] = useState("");
  // const [Classe, setClasse] = useState("");
  // const [Categoria, setCategoria] = useState("");
  // const [userData, setUserData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const my_token = getToken();
        const id = getUserId();

        var response = await faradayApi.get(`users/${id}`);

        const permissions = response?.data?.permissions?.split(",") ?? [];

        const isBlack = permissions.includes("prime_black");
        const isBasic = permissions.includes("prime_basic");

        if (response?.data?.role !== "PARCEIRO") {
          history.push("/");
        }

        setRole(isBlack ? "PRIME_BLACK" : isBasic ? "PRIME_BASIC" : "PARCEIRO");
        setInicioLivre(response.data.created_at);
        setPartnerInfo(response.data);
        setPartnerName(response.data.name);
        setPartnerDocuments(response.data.partner_company.documents);
        setPartnerCompanyId(response.data.partner_company.id);
        setPartnerCompanyName(response.data.partner_company.name);
        setPartnerCompanyCnpj(response.data.partner_company.cnpj);
        setValorRecebido(response.data.partner_company.total_received);
        setValorAReceber(response.data.partner_company.total_to_be_received);
        setSigla(response.data.partner_company.initials);
        setAffiliates(response.data.partner_company.affiliates);
        setLogo(response.data.partner_company.logo);

        var partnerResponse = await faradayApi.get(
          `partner-companies/${response.data.partner_company.id}`
        );
        setEconomiaTotal(partnerResponse.data.clients_economy_accumulated);
      } catch (e) {
        console.log("ERRO");
        //logout();
        //window.location.reload();
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatCurrency(n) {
    if (n != 0) {
      return new Intl.NumberFormat("pt-BR", {}).format(n);
    } else {
      return "R$ 0,00";
    }
  }

  // Função para formatar CPF
  function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  const qtdAfiliates = Affiliates.length;
  // Função para formatar CNPJ
  function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showDeleteDoc, setShowDeleteDoc] = useState(false);
  const [loadingDeleteDoc, setLoadingDeleteDoc] = useState(false);

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const handleCloseDoc = () => {
    setShowDeleteDoc(false);
    window.location.reload();
  };

  const handleShow = () => setShow(true);
  const [error, setError] = useState(false);
  const [errorDeleteDoc, setErrorDeleteDoc] = useState(false);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleUpload = async () => {
    setFileLoading(true);

    const token = getToken();
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("name", name);
    formData.append("office", office);
    formData.append("phone", phone.toString());
    formData.append("email", email);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      if (selectedFiles.length <= 0) {
        window.alert("Selecione algum documento para enviar!");
        setFileLoading(false);
        return;
      }
      if (!name || !office || !phone || !email) {
        window.alert("Envie todos os dados do contato!");
        setFileLoading(false);
        return;
      }
      if (!validateEmail(email) || !validatePhone(phone)) {
        window.alert("Existe campos inválidos!");
        setFileLoading(false);
        return;
      }
      await faradayApi.patch(
        `partner-companies/sent/documents/partner/${partnerCompanyId}`,
        formData,
        {
          headers,
        }
      );
      setFileLoading(false);
      setSelectedFiles([]);
      setShow(true);
    } catch (error) {
      setShow(true);
      setError(true);
      setFileLoading(false);
      setSelectedFiles([]);
    }
  };

  const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  function handleDeleteConfirmation(item) {
    setModalDeleteConfirmation(true);
    setDeleteItem(item);
  }

  const handleCloseDeleteDoc = () => setModalDeleteConfirmation(false);

  async function handleDeleteDoc(file) {
    setModalDeleteConfirmation(false);
    setLoadingDeleteDoc(true);

    try {
      await faradayApi.delete(`uploads/${file}?folder=documents`);

      setShowDeleteDoc(true);
      setLoadingDeleteDoc(false);
    } catch (e) {
      setErrorDeleteDoc(true);
      setShowDeleteDoc(true);
      setLoadingDeleteDoc(false);
    }
  }

  function convertDate(date) {
    return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
  }

  const handleDownload = async (report) => {
    const response = await fetch(report.url);
    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = report.name;
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
  };

  const validatePhone = (phone) => {
    const re = /^(\(?\d{2}\)?\s?)(\d{4,5}\-?\d{4}|\d{8,9})$/;
    console.log(re.test(String(phone)), phone);
    return re.test(String(phone));
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  const deleteAllFilesSelected = () => {
    setSelectedFiles("");
  };
  return (
    <>
      <NavBar changeColor="true" />
      <div className="container">
        <div className="partner-area" style={{ marginTop: "60px" }}></div>
        <div className="container container-Parceiro">
          <h2>
            Área do Integrador <b>MERX</b> {role !== "PARCEIRO" && "| prime"}{" "}
            {role === "PRIME_BLACK" && "Black"}
          </h2>
          <div className="buttonsLink">
            <a href="/simulacao">
              <button
                className={`btnSimulationEconomy  ${
                  role === "PRIME_BLACK"
                }Black`}
                id="btnEconomy"
                disabled={role !== "PRIME_BLACK"}
              >
                Simule a economia no mercado livre
              </button>
            </a>

            <a href="/pesquisa-mercado-livre">
              <button
                className={`btnResearchFreeMarket ${
                  role === "PRIME_BLACK"
                }Black`}
                id="btnSearch"
                disabled={role !== "PRIME_BLACK"}
              >
                Pesquisa no Mercado Livre
              </button>
            </a>
          </div>

          {!!partnerCompanyName && (
            <>
              <ParterInfos
                nome={partnerName}
                sigla={partnerCompanyName}
                cnpj={formatCNPJ(partnerCompanyCnpj)}
                inicioLivre={inicioLivre}
                logo={logo}
                parterInfosTotal={partnerInfo}
              />
              <span className="line"></span>

              {role === "PRIME_BLACK" && (
                <>
                  <TrilhaDeConhecimento isCCEE={false} />
                  <TrilhaDeConhecimento isCCEE={true} />
                </>
              )}

              {role !== "PARCEIRO" && (
                <div className="clientesIndicacao">
                  <div className="clientesIndicados infoClientesCompleta">
                    <div className="texts">
                      <h2>Clientes indicados</h2>
                      <p>
                        Clientes indicados por você que estão ativos na nossa
                        base
                      </p>
                      <p className="unidades">{qtdAfiliates} Unidades</p>
                    </div>
                    <ClientesIndicados
                      Affiliates={Affiliates}
                      economiaTotal={economiaTotal}
                      qtdAffiliates={qtdAfiliates}
                    />
                  </div>
                  <div className="clientesIndicados clientesIndicadosEconomy">
                    <h2>Economia dos Clientes Indicados</h2>
                    <p className="valueEconomy">
                      {formatCurrency(economiaTotal)}
                    </p>
                  </div>
                </div>
              )}

              <div className="docs">
                <div className="clientesIndicados documentosRecebidos">
                  <h2>Documentos recebidos</h2>
                  <p>
                    Visualize todos os documentos em PDF que a MERX enviou para
                    você
                  </p>
                  {partnerDocuments.length > 0 ? (
                    <div className="documentosEnviadosPelaMerx">
                      {partnerDocuments.map((item, index) => {
                        if (item.situation === "RECEBIDO") {
                          return (
                            <div key={index} className="pdf-material">
                              <div className="pdf-image">
                                <img
                                  className="pdf-icon"
                                  src={fileText}
                                  alt="pdf"
                                />
                              </div>
                              <div className="pdf-info">
                                <span className="pdf-name-new">
                                  {item.name}
                                </span>
                                <span className="pdf-size  ">
                                  Tamanho {formatCurrency(+item.size)} kB
                                </span>
                                <span className="pdf-size mb-pdf">
                                  Recebido em {convertDate(item.created_at)}
                                </span>
                              </div>
                              <div className="button-area">
                                <FiDownload
                                  style={{ cursor: "pointer", color: "#000" }}
                                  onClick={() => handleDownload(item)}
                                />
                                {!loadingDeleteDoc && (
                                  <FiTrash2
                                    style={{
                                      color: "#CF0E0E",
                                      marginLeft: "8px",
                                      cursor: loadingDeleteDoc
                                        ? "none"
                                        : "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteConfirmation(item)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <span className="no-content">
                      Não há nenhum documento recebido.
                    </span>
                  )}
                </div>
                <div className="clientesIndicados documentosEnviados">
                  <h2>Documentos Enviados</h2>
                  <p className="envieDoc">Envie documentos para MERX</p>
                  <div className="documentosEnviadosParaMerx">
                    {partnerDocuments ? (
                      partnerDocuments.map((item, index) => (
                        <div
                          key={index}
                          className="pdf-material pdf-material-sended"
                        >
                          <div className="pdf-image">
                            <img
                              className="pdf-icon"
                              src={fileText}
                              alt="pdf"
                            />
                          </div>
                          <div className="pdf-info">
                            <span className="pdf-name-new">{item.name}</span>
                            <span className="pdf-size  ">
                              Tamanho {formatCurrency(+item.size)} kB
                            </span>
                            <span className="pdf-size mb-pdf">
                              Recebido em {convertDate(item.created_at)}
                            </span>
                          </div>
                          <div className="button-area">
                            <FiDownload
                              style={{ cursor: "pointer", color: "#000" }}
                              onClick={() => handleDownload(item)}
                            />
                            {!loadingDeleteDoc && (
                              <FiTrash2
                                style={{
                                  color: "#CF0E0E",
                                  marginLeft: "8px",
                                  cursor: loadingDeleteDoc ? "none" : "pointer",
                                }}
                                onClick={() => handleDeleteConfirmation(item)}
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>Nenhum documento enviado</p>
                    )}
                  </div>
                  {selectedFiles.length === 0 && (
                    <p style={{ fontSize: 13.5 }}>Nenhum arquivo selecionado</p>
                  )}
                  {selectedFiles.length > 0 && (
                    <p style={{ fontSize: 13.5 }}>
                      {selectedFiles.length} arquivo(s) selecionado(s)
                    </p>
                  )}

                  <div className={`btnsSend${selectedFiles.length > 0}`}>
                    <span
                      className={`send-btn send-btn-new fileSelected${
                        selectedFiles.length > 0
                      }`}
                    >
                      <img
                        src={sendFile}
                        alt="imagem-envio"
                        className="sendFile"
                      />
                      Anexar arquivo
                      <input
                        className="input-file"
                        type="file"
                        multiple
                        onChange={handleFileSelect}
                        accept="application/pdf"
                      />
                      <img
                        src={deleteIcon}
                        alt="imagem-envio"
                        className="deleteFile"
                        onClick={deleteAllFilesSelected}
                      />
                    </span>
                    {selectedFiles.length > 0 && (
                      <div className="inputsInvoices">
                        <label className="inputInvoice">
                          <h3>Nome para contato</h3>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Ex: João"
                          />
                        </label>{" "}
                        <label className="inputInvoice">
                          <h3>Cargo do contato</h3>
                          <input
                            type="text"
                            placeholder="Ex: Gerente"
                            value={office}
                            onChange={(e) => setOffice(e.target.value)}
                          />
                        </label>{" "}
                        <label className="inputInvoice">
                          <h3>Número do contato</h3>
                          <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Ex: 811234567891"
                          />
                          {!validatePhone(phone) && phone.length > 0 && (
                            <p className="error">Formato inválido</p>
                          )}
                        </label>{" "}
                        <label className="inputInvoice">
                          <h3>Email para contato</h3>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Ex: merx@merxenergia.com.br"
                          />
                          {!validateEmail(email) && email.length > 0 && (
                            <p className="error">Email inválido</p>
                          )}
                        </label>
                      </div>
                    )}
                    <button
                      disabled={fileLoading}
                      className={
                        !fileLoading
                          ? "send-btn-button  send-btn-button-new"
                          : "button-loading"
                      }
                      onClick={handleUpload}
                      id="sendBtn"
                    >
                      {!fileLoading ? (
                        "ENVIAR"
                      ) : (
                        <Spinner animation="border" variant="primary"></Spinner>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {role !== "PARCEIRO" && (
                <div className="migrationParter">
                  <MigrationParter Affiliates={Affiliates} />
                </div>
              )}

              <a
                href="https://wa.me/5581982136133?text=Ol%C3%A1%2C%20gostaria%20de%20falar%20com%20o%20grupo%20de%20especialistas"
                className="especialistasButton"
              >
                <Button>Falar com Grupo de Especialistas</Button>
              </a>
              <p className="direitos">
                © 2024 MERX energia. All Rights Reserved.
              </p>
            </>
          )}
        </div>

        <SendModal
          error={error}
          show={show}
          onHide={handleClose}
          setShow={setShow}
          setError={setError}
        />
        <DeletePartnerDocModal
          error={errorDeleteDoc}
          onHide={handleCloseDoc}
          show={showDeleteDoc}
          setShow={setShowDeleteDoc}
          setError={setErrorDeleteDoc}
        />

        <Modal
          show={modalDeleteConfirmation}
          onHide={() => setModalDeleteConfirmation(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>Deletar {deleteItem.name}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "8px" }}>
            Você tem certeza que você deseja deletar o documento{" "}
            {deleteItem.name}? Esta ação é irreversível.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" onClick={handleCloseDeleteDoc}>
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDeleteDoc(deleteItem?.storage_id)}
            >
              Deletar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Footer />
    </>
  );
}
