import styled from "styled-components";
import { Modal } from "react-bootstrap";
import {
  highlightTextColor,
  secondaryHighlightColor,
} from "../../utils/styleElements";

type ClientProps = {
  evenly?: boolean;
};

export const Main = styled.div`
  width: 100%;
`;

export const ContainerMainContent = styled.div`
  // background-color: #e2e2e2;
`;

export const ClientText = styled.h1`
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
  margin-bottom: 1.5rem;
  font-weight: 300;
`;

export const ContainerArea = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 50px;
`;

export const LogoArea = styled.div`
  margin-top: 36px;

  width: 100%;
  display: flex;
  align-items: center;

  .back {
    width: 20px;
    height: auto;
    cursor: pointer;
  }

  .back-area {
    cursor: pointer;
  }

  .logo {
    height: 100px;
  }

  span {
    font-size: 16px;
    color: #0072ce;
    text-transform: uppercase;
    margin: 0 40px 0 5px;
  }
`;

export const ClientNameInfo = styled.div`
  // border: 1px solid #ececec;
  padding: 10px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  flex-direction: row;
  margin-bottom: 10px;

  .inicio-livre-info {
    min-width: 92px;
  }

  .client-info-texts {
    display: flex;
    gap: 25px 50px;
    justify-content: space-evenly;
  }
  .client-logo {
    display: flex;
    justify-content: left;
    align-items: start;
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }

  .logo {
    width: 100%;
  }

  .logo img {
    width: 100%;
  }

  @media (max-width: 768px) {
    .client-info-texts {
      flex-direction: column;
    }
    .client-info {
      flex-direction: column-reverse;
    }
    .client-logo {
      margin-left: 0;
    }
  }
`;

export const ContractInfo = styled.div<ClientProps>`
  display: flex;
  align-items: center;
  gap: 4rem;

  span {
    color: #7e7e82;
    margin-bottom: 8px;
  }

  p {
    color: #212322;
  }
`;

export const StatusMigrationArea = styled.div`
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem 0;
  gap: 80px;
  flex-wrap: wrap;

  p {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .line-status {
    width: 80px;
    height: 1px;
    background: #d9d9d9;
  }
  .current-status {
    justify-content: space-between;
  }

  @media (max-width: 1269px) {
    .bar-divider {
      display: none;
    }
  }

  @media (max-width: ) span, p {
    text-align: center;
    font-weight: 300;
    color: #212322;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 2em;
`;

export const ContentCard = styled.div`
  background-color: white;
  padding: 0 1rem;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: clip;
`;

export const PageTitleContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: #0367c7;
  padding-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
  height: 156px;
  border-radius: 5px;
`;

interface AvatarContainerProps {
  companyLogo: string;
}

export const AvatarContainer = styled.div<AvatarContainerProps>`
  position: absolute;
  width: 128px;
  height: 128px;
  border-color: #ffffff;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  vertical-align: text-top;
  background-size: contain;
  background-color: white;
  background-image: url(${(props) => props.companyLogo});
`;

export const PageTitle = styled.div`
height: 80px;
margin-left: 128px;
  font-weight: bold;
  color: white;
  font-size: 32px;
  line-height: 40px;
  padding-left 1em;
  text-overflow: clip;
`;

export const ContainerDadoBasico = styled.div`
  padding: 2em;
  height: 100%;
`;

export const TitleDadoBasico = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
`;

export const DadoBasico = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  height: 100%;
`;

export const ImgRegiao = styled.img`
  height: 250px;
  margin: auto;
  display: block;
`;

export const DummyImg = styled.div`
  height: 250px;
  margin: auto;
  display: block;
`;

export const ContainerDadosRegiao = styled.div`
  justify-content: space-between;
  width: 250px;
  height: 100%;
`;
export const ContainerDadoValor = styled.div`
  border-left: 1em;
`;

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin-bottom: 1em;
`;

export const CardCost = styled(ContentCard)`
  width: 32%;
  height: 211px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
`;

export const TitleCardCost = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  width: 100%;
`;

export const ContainerCardCostValue = styled.div`
  margin-top: 5em;
`;

export const CardCostRs = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  vertical-align: baseline;
  padding-right: 0.5em;
`;

export const CardCostValue = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.02em;
  vertical-align: baseline;
`;

export const GraphContainer = styled.div`
  width: 100%;
`;

export const ContainerChecklist = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  margin-top: 20px;
  height: 280px;
`;

export const ChecklistItem = styled.div`
  text-align: center;
  width: 120px;
  height: 100%;
`;

export const ChecklistCircle = styled.div`
  positon: absolute;
  left: 50%;
  top: 50%;
  transform: translate(0px, 38px);
  background-color: ${secondaryHighlightColor};
  margin: auto;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: solid ${highlightTextColor} 2px;
  line-height: 1em;
  z-index: 1;
`;

export const ChecklistTrophy = styled(ChecklistCircle)`
  border: none;
  background-color: transparent;
`;

export const ChecklistCircleTrophy = styled(ChecklistCircle)`
  z-index: 0;
  transform: translate(0px, -12px);
  width: 48px;
  height: 48px;
  padding-top: 7px;
`;

export const ChecklistItemTitle = styled.div`
  color: ${highlightTextColor};
  text-transform: uppercase;
  font-weight: bold;
  height: 50px;
  vertical-align: top;
`;

export const ChecklistItemText = styled.div`
  margin-top: 100px;
`;

export const ChecklistThermometerFrame = styled.div`
  position: absolute;
  border: solid ${highlightTextColor} 2px;
  width: calc(100% - 120px);
  height: 10px;
  border-radius: 5px;
  transform: translate(0px, -40px);
  margin-left: 60px;
  margin-right: 60px;
  z-index: 0;
`;

export const ChecklistThermometer = styled.div`
  background-color: ${highlightTextColor};
  height: 100%;
  border-radius: 5px 0px 0px 5px;
`;

export const ChecklistThermometerEmpty = styled(ChecklistThermometer)`
  position: absolute;
  background-color: ${secondaryHighlightColor};

  border-radius: 0px 5px 5px 0px;
  z-index: 2;
  right: 0;
  transform: translate(0px, -6px);
`;

export const TableHist = styled.table``;

export const MonthDataContainer = styled.div``;

export const CardMonthData = styled.div`
  width: 375px;
  height: 120px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

export const ModalMensal = styled(Modal)``;

export const MonthCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  overflow-x: auto;
  direction: rtl;
  padding-bottom: "5px";
  height: 145px;
`;

export const CadastrosItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  text-decoration: none;
  border: 1px solid #ececec;
  height: 140px !important;
  border-radius: 8px;
  background-color: #eaeaea;

  @media (max-width: 768px) {
    span {
      position: relative;
      left: -28px;
      font-size: 13px;
    }
  }
`;

export const PerfilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: 0;
  margin-top: 35px;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 35px;
`;

export const CadastrosItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  gap: 15px;
`;

export const CadastrosItem1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CadastrosItem2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 12px;
`;

export const CadastrosItem3 = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CadastrosItem4 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
  position: relative;
  right: 10px;

  a:hover {
    text-decoration: none;
  }
`;

export const AgentResultTeste = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  // &:hover {
  //   background-color: lightblue;
  // }
`;
