import React from "react";
import NavBar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer";
import "./index.css";

const PolicyPrivacyPage = () => {
  return (
    <div>
      <NavBar changeColor="true" />
      <div className="privacy-policy">
        <h1>Política de Privacidade</h1>

        <section className="policy-section">
          <h2>INTRODUÇÃO</h2>
          <p>
            A privacidade dos usuários de nossa plataforma é prioridade, sendo
            assim, esclarecemos nessa Política de Privacidade como serão
            tratadas suas informações. Ao acessar o site da Merx Energia
            (www.merxenergia.com.br), o usuário deve estar ciente de que o mesmo
            poderá conter links para outros endereços eletrônicos, sendo que
            esses poderão possuir políticas de privacidade próprias, diferentes
            das aplicadas pela Merx Energia.
          </p>
        </section>

        <section className="policy-section">
          <h2>COMO ENTRAR EM CONTATO</h2>
          <p>
            Caso tenha alguma pergunta relacionada a esta Política de
            Privacidade ou deseje entrar em contato por outro motivo, entre em
            contato com o suporte pelo e-mail plataforma@merxenergia.com.br.
          </p>
        </section>

        <section className="policy-section">
          <h2>INFORMAÇÕES QUE COLETAMOS</h2>
          <p>
            Você não é obrigado a fornecer qualquer informação pessoal a fim de
            visitar nosso website e/ ou visualizar certos recursos disponíveis
            no mesmo. Embora não seja obrigatório que o usuário faça o cadastro
            para navegar em nossa página, certos conteúdos e plataformas somente
            estarão disponíveis aos usuários cadastrados e ativos. O processo de
            criação de conta exige que você forneça uma série de dados pessoais,
            tais como nome, email, o número de seu telefone, CPF e uma senha. Em
            outras ocasiões, também é possível que você tenha de fornecer dados
            adicionais, como CNPJ, razão social, a fim de receber mais
            informações e customizações no site sobre nossos serviços. Para
            usuários cadastrados, salvamos as faturas enviadas em nosso
            simulador em um banco de dados, com o propósito de estudo e melhoria
            de nossos serviços em geral. Além dessas informações, poderão ser
            solicitadas aos usuários outras as quais possibilitem o
            aprimoramento do conteúdo da Merx Energia.
          </p>
        </section>

        <section className="policy-section">
          <h2>POLÍTICA DE COLETA AUTOMÁTICA DE DADOS</h2>
          <p>
            Tendo ou não se cadastrado no Website, a Merx Energia
            (www.merxenergia.com.br) poderá coletar dados enquanto você navega
            pelo site, dados esses que não te identificam pessoalmente, como
            velocidade de conexão, endereço de IP, localização geográfica, etc.
          </p>
        </section>

        <section className="policy-section">
          <h2>POLÍTICA DE COOKIES</h2>
          <p>
            Ao utilizar nosso Website, poderemos utilizar uma tecnologia comum
            no setor, chamada “cookies”, armazenando algumas informações em seu
            computador, as quais possibilitam uma navegação personalizada ao
            usuário, ou, até mesmo, facilitando sua navegação. A maioria dos
            navegadores permitem que o usuário apague esses arquivos de seu
            computador, no entanto, caso o usuário configure seu navegador para
            bloquear os cookies, a experiência de navegação poderá ficar
            prejudicada.
          </p>
        </section>

        <section className="policy-section">
          <h2>DEPOIMENTOS</h2>
          <p>
            A Merx Energia (www.merxenergia.com.br) pode postar em seu Website
            ou redes sociais depoimentos ou conteúdos os quais podem conter
            informações sobre nossos usuários. Ao concordar com os Termos de
            Serviço aplicáveis ao site, você está dando seu consentimento à Merx
            Energia (www.merxenergia.com.br), a publicar qualquer depoimento,
            post, comentário, nome e qualquer outro tipo de representação.
          </p>
        </section>

        <section className="policy-section">
          <h2>USO DE SUAS INFORMAÇÕES</h2>
          <p>
            Em geral, utilizaremos suas informações para aprimorar os nossos
            serviços e possibilitar que os nossos usuários desfrutem ao máximo
            de todos os conteúdos disponibilizados. A Merx Energia
            (www.merxenergia.com.br) poderá compartilhar essas informações com
            fornecedores de conteúdo do serviço, associados, parceiros,
            patrocinadores, coligadas, filiais e outras entidades, de forma
            não-pessoal, a fim de mapear dados demográficos, preferências gerais
            entre os usuários ou para outros fins. Esta informação pode incluir
            dados de uso e demográficos, informações pessoais (como seu endereço
            de e-mail), etc. A fim de mantê-lo informado e atualizado, poderemos
            enviar e-mails e anúncios que são necessários para a administração
            de nosso Website e Serviço. Trabalhamos com terceiros que prestam
            serviços, que podem incluir serviços de natureza administrativa como
            hospedagem do website, processamento de pedidos, liberação de cartão
            de crédito, análise de dados, etc. Algumas informações do usuário
            poderão ser compartilhadas com estes terceiros, a fim de viabilizar
            o serviço a ser prestado. A Merx Energia (www.merxenergia.com.br)
            procura impor obrigações de confidencialidade aos mesmos, a fim de
            proteger as informações de seus usuários. Caso o usuário deseje
            desativar sua conta, a Merx Energia (www.merxenergia.com.br) poderá
            reter alguma informação associada com as informações do perfil por
            um tempo comercialmente razoável para backup, arquivamento e/ou fins
            de auditoria.
          </p>
        </section>

        <section className="policy-section">
          <h2>EXCLUSÃO DE DADOS</h2>
          <p>
            Você pode solicitar a exclusão dos seus dados através do
            cancelamento da sua parceria com a Merx Energia
            (www.merxenergia.com.br) e ou de outros cadastros da Merx Energia
            (www.merxenergia.com.br) que você tenha (conforme aplicável) e a
            Merx Energia (www.merxenergia.com.br) fará a exclusão definitiva de
            seus dados em até 180 dias. Após a exclusão, a Merx Energia
            (www.merxenergia.com.br) poderá manter os seus dados somente:
            <br />
            <br />
            (a) pelo prazo necessário para fins de processos judiciais,
            administrativos e arbitrais;
            <br />
            <br />
            (b) para cumprimento de obrigação legal e/ou regulatória;
            <br />
            <br />
            (c) para o exercício regular de direitos, como, por exemplo, fazer
            valer os direitos da Merx Energia (www.merxenergia.com.br) com base
            no contrato de assinatura e/ou Termos de Uso aplicáveis;
            <br />
            <br />
            (d) em formato anonimizado;
            <br />
            <br />
            Você pode solicitar a remoção dos seus dados através do e-mail:
            plataforma@merxenergia.com.br
          </p>
        </section>

        <section className="policy-section">
          <h2>SEGURANÇA</h2>
          <p>
            A segurança de dados pessoais do usuário é muito importante para a
            Merx Energia (www.merxenergia.com.br). Assim, serão utilizadas as
            melhores práticas disponíveis no mercado para proteger as
            informações pessoais coletadas. Quando forem fornecidas informações
            sensíveis, como número de conta ou cartão de crédito, a Merx Energia
            (www.merxenergia.com.br) tomará as medidas comercialmente razoáveis
            para protegê-las, por meio de uma conexão segura com o seu navegador
            web. É indispensável que o usuário faça sua parte para ajudar a Merx
            Energia (www.merxenergia.com.br) a proteger suas informações.
            Mantenha sua senha em segurança e, se possível, não utilize dados
            pessoais na mesma. Não divulgue ela a ninguém.
          </p>
        </section>

        <section className="policy-section">
          <h2>LINKS PARA OUTROS SITES</h2>
          <p>
            O site da Merx Energia (www.merxenergia.com.br) poderá conter links
            para outros sites. A Merx Energia (www.merxenergia.com.br) procura
            utilizar-se dos mais diversos meios para proteção das informações do
            usuário. Contudo, a partir do momento que o usuário navegue por
            outros sites, o usuário estará por sua conta em risco, tendo em
            vista que a Merx Energia (www.merxenergia.com.br) não se
            responsabiliza pelas informações e conteúdos disponibilizados nos
            mesmos. Ainda, é primordial que os usuários tomem conhecimento das
            políticas adotadas pelos mesmos.
          </p>
        </section>

        <section className="policy-section">
          <h2>BANCO DE DADOS</h2>
          <p>
            A Merx Energia (www.merxenergia.com.br) poderá hospedar seu banco de
            dados em outro país. Sendo assim, ao submeter suas informações à
            nossa plataforma, o usuário está ciente que as mesmas poderão ser
            armazenadas em bancos de dados fora do Brasil. Ao utilizar nosso
            website, o usuário manifesta sua concordância com os termos dessa
            Política de Privacidade. Caso não concorde, não utilize nosso
            Website ou serviços nele estabelecidos. A presente política poderá
            ser alterada ao longo do tempo. O uso continuado do website após as
            alterações significa que o usuário aceita e concorda com as mudanças
            realizadas, as quais passarão a vigorar em substituição à anterior,
            inclusive, para as informações fornecidas pelo usuário anteriores às
            mudanças.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default PolicyPrivacyPage;
