import React, { useState, useEffect } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { shortMesesExt } from "../../utils/utilityFunctions";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";

function AnaliseGeracao({ monthlyData }: { monthlyData: any[] }) {
  const dataGeneration = monthlyData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.generation;
      return [x, y ? y : 0.0];
    });

  const months = dataGeneration.map((e) => e[0]);

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: { type: "column", marginTop: 50 },
    title: { text: "" },

    xAxis: {
      categories: months,
      crosshair: true,
    },

    yAxis: {
      title: {
        text: "",
      },
      labels: {
        format: "{value} MWh",
      },
    },

    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}</b><br/>",
    },

    series: [
      {
        name: "Energia gerada",
        color: "green",
        zIndex: 1,
        data: dataGeneration,
        tooltip: {
          valueSuffix: " MWh",
        },
        lineWidth: 2,
        marker: {
          radius: 4,
          symbol: "circle",
        },
        dataLabels: {
          enabled: true,
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.3f}", // Exibe o valor do ponto de dados
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
    ],
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      {dataGeneration.some((item) => !!item[1] && item[1] !== 0) && (
        <>
          <div
            style={{
              margin: "32px 0 16px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <span
              className="clientDashboard-text"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              Análise de geração líquida
            </span>
          </div>
          <ContentCard>
            <GraphContainer>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </GraphContainer>
          </ContentCard>
        </>
      )}
    </>
  );
}

export default AnaliseGeracao;
