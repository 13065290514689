import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import ImageUm from "../../Assets/imgCarrousel-min.png";
import ImageDois from "../../Assets/bgCarrosel1-min.png";
import ImageTres from "../../Assets/bgCarrossel2-min.png";
import ImageQuatro from "../../Assets/bgCarrossel3-min.png";
import ImageCinco from "../../Assets/bgCarrossel4-min.png";
import ImageSeis from "../../Assets/bgCarrossel5-min.png";
export default function Header() {
  const listRef = useRef(null);
  const elementoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [largura, setLargura] = useState(0);
  const [isIndexClicked, setIsIndexClicked] = useState(false);

  const images = [
    ImageDois,
    ImageUm,
    ImageTres,
    ImageQuatro,
    ImageCinco,
    ImageSeis,
  ];

  useEffect(() => {
    if (elementoRef.current) {
      setLargura(elementoRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (elementoRef.current) {
        setLargura(elementoRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const changeCarrouselImage = (index) => {
    setIsIndexClicked(true);

    if (listRef.current) {
      listRef.current.scrollTo({
        left: largura * index,
        behavior: "smooth",
      });
    }
    setActiveIndex(index);
  };

  setTimeout(() => {
    if (listRef.current && !isIndexClicked) {
      const nextIndex = activeIndex + 1 < images.length ? activeIndex + 1 : 0;

      listRef.current.scrollTo({
        left: largura * nextIndex,
        behavior: "smooth",
      });

      setActiveIndex(nextIndex);
    }
  }, 5000);

  return (
    <div className="carrosel">
      <div className="images" ref={listRef}>
        {images.map((image, index) => (
          <img
            src={image}
            key={index}
            ref={elementoRef}
            alt="imagem da equipe merx Trabalhando"
          />
        ))}
      </div>

      <ul className="listIcons">
        {images.map((image, index) => (
          <li>
            <button
              className={activeIndex === index && "active"}
              onClick={() => changeCarrouselImage(index)}
            ></button>
          </li>
        ))}
      </ul>
    </div>
  );
}
