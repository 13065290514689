import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { economyVPL } from "../../pages/ClientDashboard/interfaces";

function SimulatorVPL({
  data,
  simulationType,
}: {
  data: economyVPL;
  simulationType: string;
}) {
  const simulationCategories = () => {
    if (simulationType === "ML" || simulationType === "GDXAPE")
      return [
        "Ano 1",
        "Ano 2",
        "Ano 3",
        "Ano 4",
        "Ano 5",
        "Ano 6",
        "Ano 7",
        "Ano 8",
        "Ano 9",
        "Ano 10",
      ];
    else if (simulationType === "MLXAPE")
      return [
        "Ano 1",
        "Ano 2",
        "Ano 3",
        "Ano 4",
        "Ano 5",
        "Ano 6",
        "Ano 7",
        "Ano 8",
        "Ano 9",
        "Ano 10",
        "Ano 11",
        "Ano 12",
        "Ano 13",
        "Ano 14",
        "Ano 15",
        "Ano 16",
        "Ano 17",
        "Ano 18",
        "Ano 19",
        "Ano 20",
        "Ano 21",
        "Ano 22",
        "Ano 23",
        "Ano 24",
        "Ano 25",
      ];
    else
      return [
        "Ano 1",
        "Ano 2",
        "Ano 3",
        "Ano 4",
        "Ano 5",
        "Ano 6",
        "Ano 7",
        "Ano 8",
        "Ano 9",
        "Ano 10",
      ];
  };

  const simulationArray = () => {
    if (simulationType === "ML" || simulationType === "GDXAPE")
      return data.economyAnual;
    else if (simulationType === "MLXAPE") return data.vplAnual.slice(1);
    else return data.vplAnual.slice(1, 11);
  };

  const chartOptions = {
    chart: { type: "column", backgroundColor: "#FAFAFA", borderRadius: "8px" },
    title: { text: "", align: "left" },
    xAxis: {
      categories: simulationCategories(),
    },
    yAxis: { title: { text: "Valor em R$" }, gridLineWidth: 0 },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: "0%",
      },
    },
    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        return `<b>${this.x}<br>${this.y.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}</b>`;
      },
    },
    series: {
      label: { enabled: true },
      data: simulationArray(),
      zones: [
        {
          value: 0,
          color: "#f91010",
        },
        {
          color: "#1fa824",
        },
      ],
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}

export default SimulatorVPL;
