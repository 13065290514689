import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface dataProps {
  compare: boolean;
  dailyConsumeReturn: string;
  dailyGenerationReturn: string;
  compareDailyConsumeReturn: string;
  compareDailyGenerationReturn: string;
}

interface dailyConsumeReturn {
  name: string;
  dailyConsume: number;
  date: string;
  isDayProjected: boolean;
  measurePoint: string;
}

interface dailyGenerationReturn {
  name: string;
  dailyGeneration: number;
  date: string;
  isDayProjected: boolean;
  measurePoint: string;
}

export function ProjectionChart({ data }: { data: dataProps }) {
  const {
    dailyConsumeReturn,
    dailyGenerationReturn,
    compare,
    compareDailyConsumeReturn,
    compareDailyGenerationReturn,
  } = data;

  let compareConsumeData: dailyConsumeReturn[] = [];
  if (typeof compareDailyConsumeReturn === "string")
    compareConsumeData = JSON.parse(compareDailyConsumeReturn);
  else compareConsumeData = compareDailyConsumeReturn;

  let compareGenerationData: dailyGenerationReturn[] = [];
  if (typeof compareDailyGenerationReturn === "string")
    compareGenerationData = JSON.parse(compareDailyGenerationReturn);
  else compareGenerationData = compareDailyGenerationReturn;

  let consumeData: dailyConsumeReturn[] = [];
  if (typeof dailyConsumeReturn === "string")
    consumeData = JSON.parse(dailyConsumeReturn);
  else consumeData = dailyConsumeReturn;

  let generationData: dailyGenerationReturn[] = [];
  if (typeof dailyGenerationReturn === "string")
    generationData = JSON.parse(dailyGenerationReturn);
  else generationData = dailyGenerationReturn;

  let projectedStart;
  

  if (Array.isArray(consumeData)) {
    projectedStart = consumeData?.findIndex(
      (value) => value.isDayProjected === true
    );
  } else {
    console.error("consumeData não é um array", consumeData);
  }

  const x: any[] = [];
  const y: any[] = [];

  consumeData?.filter((value) => {
    if (!value?.isDayProjected) {
      x.push(value?.dailyConsume);
      y.push(null);
    } else {
      x.push(null);
      y.push(value?.dailyConsume);
    }
  });

  const w: any[] = [];
  const z: any[] = [];

  generationData?.filter((value) => {
    if (!value?.isDayProjected) {
      w.push(value?.dailyGeneration);
      z.push(null);
    } else {
      w.push(null);
      z.push(value?.dailyGeneration);
    }
  });

  const xCompare: any[] = [];
  const yCompare: any[] = [];
  compareConsumeData?.forEach((value) => {
    if (!value.isDayProjected) {
      xCompare.push(value.dailyConsume);
      yCompare.push(null);
    } else {
      xCompare.push(null);
      yCompare.push(value.dailyConsume);
    }
  });

  const wCompare: any[] = [];
  const zCompare: any[] = [];
  compareGenerationData?.forEach((value) => {
    if (!value.isDayProjected) {
      wCompare.push(value.dailyGeneration);
      zCompare.push(null);
    } else {
      wCompare.push(null);
      zCompare.push(value.dailyGeneration);
    }
  });

  projectedStart &&
    projectedStart !== -1 &&
    y.splice(projectedStart - 1, 1, x[projectedStart - 1]);
  projectedStart &&
    projectedStart !== -1 &&
    z.splice(projectedStart - 1, 1, w[projectedStart - 1]);

  if (w.length < 2) {
    w.splice(0, 999);
    z.splice(0, 999);
  }

  const series: any[] = [
    {
      name: "Consumo medido",
      label: { enabled: false },
      data: x,
      lineWidth: 2,
      marker: {
        radius: 4,
        symbol: "circle",
      },
    },

    {
      name: "Consumo projetado",
      label: { enabled: false },
      data: y,
      zIndex: -1,
      lineWidth: 1,
      marker: {
        radius: 4,
        symbol: "circle",
      },
      dashStyle: "dot",
    },
  ];

  if (w.some((item) => !!item && item !== 0)) {
    series.push(
      {
        name: "Geração medida",
        label: { enabled: false },
        data: w,
        lineWidth: 2,
        marker: {
          radius: 4,
          symbol: "square",
        },
      },
      {
        name: "Geração projetada",
        label: { enabled: false },
        data: z,
        lineWidth: 1,
        zIndex: -1,
        marker: {
          radius: 4,
          symbol: "square",
        },
        dashStyle: "dot",
      }
    );
  }

  if (compare && xCompare) {
    series.push({
      name: "Consumo Comparado",
      color: "#0000ff",
      label: { enabled: false },
      data: xCompare,
      lineWidth: 2,
      zIndex: -1,
      marker: {
        radius: 4,
        symbol: "circle",
      },
      dashStyle: "dot",
    });

    if (wCompare.some((item) => !!item && item !== 0)) {
      series.push({
        name: "Geração Comparada",
        color: "#c9cf23",
        label: { enabled: false },
        data: wCompare,
        lineWidth: 2,
        zIndex: -1,
        marker: {
          radius: 4,
          symbol: "square",
        },
        dashStyle: "dot",
      });
    }
  }

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    title: {
      text: "",
    },

    yAxis: {
      gridLineWidth: 1,
      title: {
        text: "Montante (MWh)",
      },
    },

    xAxis: {
      tickInterval: 1,
      gridLineWidth: 0,
      title: { text: "Dia" },
      accessibility: {
        rangeDescription: "Dias do mês",
      },
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 1,
      },
    },

    tooltip: {
      headerFormat: "<b>Dia {point.x} - {series.name}</b><br/>",
      pointFormat: "{point.y} MWh",
      align: "center",
    },

    credits: {
      enabled: false,
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    series,
  };

  return (
    <div style={{ width: "100%" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}
