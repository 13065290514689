import React from "react";

const APEXLivreTable = ({
  livreData,
  apeData,
  economia,
}: {
  livreData: {
    demandaForaPonta: number;
    demandaPonta: number;
    parcelaDemandaForaPonta: number;
    parcelaDemandaPonta: number;
    custoBeneficioTarifario: number;
    gestao: number;
    consumoPonta: number;
    parcelaConsumoPonta: number;
    consumoForaPonta: number;
    parcelaConsumoForaPonta: number;
    custoEnergia: number;
    custoConexao: number;
    custoTotal: number;
    encargos: number;
  };
  apeData: {
    demandaForaPonta: number;
    demandaPonta: number;
    consumoForaPonta: number;
    consumoPonta: number;
    consumoAGP: number;
    demandaUsina: number;
    parcelaDemandaForaPonta: number;
    parcelaDemandaPonta: number;
    parcelaConsumoForaPonta: number;
    parcelaConsumoPonta: number;
    parcelaDemandaUsina: number;
    parcelaConsumoForaPontaAGP: number;
    energiaFaturavel: number;
    energiaExcedente: number;
    custoBeneficioTarifario: number;
    taxCovid: number;
    taxScarcity: number;
    encargoSetorial: number;
    gestao: number;
    encargos: number;
    custoEnergiaComplementar: number;
    custoEnergiaExcedente: number;
    custoConexao: number;
    custoEnergia: number;
    custoTotal: number;
  };
  economia: {
    dadosApeXml: {
      ano: string;
      ape: number;
      livre: number;
      economiaAnual: number;
      economiaMensal: number;
      economiaPct: number;
    }[];
    payBackAnual: number;
    payBackMensal: number;
    tir: number;
    vplAnual: number[];
  };
}) => {
  return (
    <>
      <div className="containerResSimulMaster">
        <div className="containerResSimul">
          <p className="containerResSimulTitle">Consumidor livre</p>
          <div className="ResSimulTableTitle">
            <p className="ResSimulTable" style={{ textAlign: "left" }}>
              Grandeza Elétrica
            </p>
            <p className="ResSimulTable">Grandezas Faturadas</p>
            <p className="ResSimulTable">Faturado</p>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada FP
            </p>
            <p className="ResSimulTableContentC">
              {livreData.demandaForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaDemandaForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada P
            </p>
            <p className="ResSimulTableContentC">
              {livreData.demandaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaDemandaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {livreData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaConsumoForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {livreData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaConsumoPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Total energia ACL
            </p>
            <p className="ResSimulTableContentC">
              {(
                livreData.consumoForaPonta + livreData.consumoPonta
              ).toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.custoEnergia.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Benefício tarifário
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.custoBeneficioTarifario.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Encargos
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.encargos.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{ textAlign: "left" }}
            ></p>
            <p
              className="ResSimulTableContentD"
              style={{ textAlign: "center", lineHeight: "34px" }}
            ></p>
            <div className="separarNum">
              <p className="ResSimulTableContentB"></p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{ textAlign: "left" }}
            ></p>
            <p
              className="ResSimulTableContentD"
              style={{ textAlign: "center", lineHeight: "34px" }}
            ></p>
            <div className="separarNum">
              <p className="ResSimulTableContentB"></p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{ textAlign: "left" }}
            ></p>
            <p
              className="ResSimulTableContentD"
              style={{ textAlign: "center", lineHeight: "34px" }}
            ></p>
            <div className="separarNum">
              <p className="ResSimulTableContentB"></p>
            </div>
          </div>

          <hr />

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Total
            </p>
            <p className="ResSimulTableContent"></p>
            <div className="separarNum">
              <p
                className="ResSimulTableContentB"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {livreData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="containerResSimul">
          <p className="containerResSimulTitle">Autoprodutor de energia</p>
          <div className="ResSimulTableTitle">
            <p className="ResSimulTable" style={{ textAlign: "left" }}>
              Grandeza Elétrica
            </p>
            <p className="ResSimulTable">Grandezas Faturadas</p>
            <p className="ResSimulTable">Faturado</p>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada FP
            </p>
            <p className="ResSimulTableContentC">
              {apeData.demandaForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaDemandaForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada P
            </p>
            <p className="ResSimulTableContentC">
              {apeData.demandaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaDemandaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda usina
            </p>
            <p className="ResSimulTableContentC">
              {apeData.demandaUsina.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaDemandaUsina.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP APE
            </p>
            <p className="ResSimulTableContentC">
              {apeData.consumoAGP.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.parcelaConsumoForaPontaAGP.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia complementar
            </p>
            <p className="ResSimulTableContentC">
              {apeData.energiaFaturavel.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.custoEnergiaComplementar.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Energia excedente
            </p>
            <p className="ResSimulTableContentC">
              {apeData.energiaExcedente.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.custoEnergiaExcedente.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Benefício tarifário
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.custoBeneficioTarifario.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Encargos
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {apeData.encargos.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <hr />

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Total
            </p>
            <p className="ResSimulTableContent"></p>
            <div className="separarNum">
              <p
                className="ResSimulTableContentB"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {apeData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="resultadoSimulacaoContainer">
        <div className="economia-card">
          <h1>Custo Livre</h1>
          <h2>
            {livreData.custoTotal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </h2>
        </div>

        <div className="economia-card">
          <h1>Custo Autoprodutor</h1>
          <h2>
            {apeData.custoTotal.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </h2>
        </div>

        <div className="economia-card">
          <h1>Economia mensal</h1>
          <div className="economia-value">
            <h2>
              {(livreData.custoTotal - apeData.custoTotal).toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </h2>
            <h3
              className={
                livreData.custoTotal - apeData.custoTotal >= 0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (livreData.custoTotal - apeData.custoTotal) /
                livreData?.custoTotal
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default APEXLivreTable;
