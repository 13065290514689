import React, { useState, useEffect } from "react";
import {
  ContentContainer,
  CardCost,
  TitleCardCost,
  ContainerCardCostValue,
  CardCostRs,
  CardCostValue,
} from "../../pages/ClientDashboard/styled";
import { IMonthlyData } from "../../pages/ClientDashboard/interfaces";

function formatCurrency(n: number | undefined) {
  if (n) {
    return new Intl.NumberFormat("pt-BR", {}).format(n);
  }
}

function CustoEconomiaUnidadeConsumidora(props: {
  totalCativo: number;
  totalLivre: number;
  economy: number;
}) {
  return (
    <div className="economia-container">
      <div className="economia-card" style={{ backgroundColor: "#EAEAEA" }}>
        <h1>Custo cativo</h1>
        <h2>
          {props?.totalCativo?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
      </div>
      <div className="economia-card" style={{ backgroundColor: "#EAEAEA" }}>
        <h1>Custo livre</h1>
        <h2>
          {props?.totalLivre?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
      </div>
      <div className="economia-card" style={{ backgroundColor: "#EAEAEA" }}>
        <h1>Economia</h1>
        <div className="economia-value">
          <h2>
            {props?.economy?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </h2>
          <h3
            className={
              props?.totalCativo - props?.totalLivre >= 0
                ? "economia-positivo"
                : "economia-negativo"
            }
          >
            {(Math.abs(props?.totalCativo) > 0
              ? (props?.totalCativo - props?.totalLivre) / props?.totalCativo
              : 0
            ).toLocaleString("pt-BR", { style: "percent" })}
          </h3>
        </div>
      </div>
    </div>
  );
}

export default CustoEconomiaUnidadeConsumidora;
