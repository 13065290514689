import React from "react";
import { Component } from "react";

import CSVReader from "react-csv-reader";

import { Form, Button } from "react-bootstrap";

import BlackLogo from "../../Assets/blackLogo.png";

import "./style.css";

import faradayApi from "../../Services/faradayApi";

class ConsumoInput extends Component {
  constructor() {
    super();

    this.state = {
      Empresas: [],
      data: [],
      days: 0,
      sum_day: [],
      show: false,
      previsao: [],
      nome_mes: "",
      dias_mes: 0,
      CNPJ: "03.906.649/0001-64",
    };
  }

  async componentDidMount() {
    try {
      const response = await faradayApi.get("company");
      const handleCompany = response.data;
      var Empresas = [];
      for (let i = 0; i < handleCompany.length; i++) {
        Empresas.push({
          Nome: handleCompany[i].Nome,
          CNPJ: handleCompany[i].CNPJ,
          _id: handleCompany[i]._id,
        });
      }

      this.setState({ Empresas });
    } catch (e) {
      alert("erro");
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = this.state;

    var handle_data = [];

    var month_line = data[2];

    var month = parseFloat(month_line[0][20] + month_line[0][21]);

    var dias_mes = 0;
    var nome_mes = "";

    switch (month) {
      case 1:
        nome_mes = "Janeiro";
        dias_mes = 31;
        break;
      case 2:
        nome_mes = "Fevereiro";
        dias_mes = 28;
        break;
      case 3:
        nome_mes = "Março";
        dias_mes = 31;
        break;
      case 4:
        nome_mes = "Abril";
        dias_mes = 30;
        break;
      case 5:
        nome_mes = "Maio";
        dias_mes = 31;
        break;
      case 6:
        nome_mes = "Junho";
        dias_mes = 30;
        break;
      case 7:
        nome_mes = "Julho";
        dias_mes = 31;
        break;
      case 8:
        nome_mes = "Agosto";
        dias_mes = 31;
        break;
      case 9:
        nome_mes = "Setembro";
        dias_mes = 30;
        break;
      case 10:
        nome_mes = "Outubro";
        dias_mes = 31;
        break;
      case 11:
        nome_mes = "Novembro";
        dias_mes = 30;
        break;
      case 12:
        nome_mes = "Dezembro";
        dias_mes = 31;
        break;
      default:
        nome_mes = "ñ identificado";
        dias_mes = 30;
        break;
    }

    this.setState({ nome_mes });
    this.setState({ dias_mes });

    for (let i = 4; i < data.length; i++) {
      handle_data.push(String(data[i]));
    }

    for (let i = 0; i < handle_data.length; i++) {
      //console.log(handle_data[i])
      const parts = handle_data[i].split(";");
      if (parts.length >= 5) {
        const consumo = parseFloat(parts[5].replace(",", "."));
        // console.log(consumo);
      }
    }

    var getConsume = []; // Armazena os consumos de cada hora

    for (let i = 0; i < data.length; i++) {
      var handled = 0;
      var consume_number = "";

      if (handle_data[i] !== undefined && handle_data[i] !== "") {
        for (let j = 0; j < handle_data[i].length; j++) {
          if (
            handle_data[i][j] === ";" ||
            (handle_data[i][j] === "," && handled !== 5)
          ) {
            handled++;
          } else if (handle_data[i][j] === ",") {
            consume_number = consume_number + ",";
          } else if (
            handled === 5 &&
            (handle_data[i][j] !== ";" || handle_data[i][j] !== ",")
          ) {
            consume_number = consume_number + handle_data[i][j];
          } else if (handle_data[i][j] === ",") {
            break;
          }
        }
        getConsume.push(consume_number);
      }
    }

    for (let i = 0; i < handle_data.length; i++) {
      if (getConsume[i] !== "" && getConsume[i] !== undefined) {
        getConsume[i] = getConsume[i].substr(0, getConsume[i].length - 1);
        getConsume[i] = getConsume[i].replace(",", ".");
        //console.log(getConsume[i])
      }
    }

    for (let i = 0; i < handle_data.length; i++) {
      if (getConsume[i] === "") {
        getConsume[i] = 0;
      }
    }

    const days = getConsume.length / 24;

    var sum_day = [];

    for (let i = 0; i < days; i++) {
      var soma = 0;
      for (let j = i * 24; j < i * 24 + 24; j++) {
        soma = soma + parseFloat(getConsume[j]);
      }
      sum_day.push(soma);
    }

    var soma_atual = 0;

    for (let i = 0; i < sum_day.length; i++) {
      soma_atual = soma_atual + parseFloat(sum_day[i]);
    }

    const media = soma_atual / sum_day.length;

    var previsao = [];

    for (let i = 0; i < sum_day.length; i++) {
      previsao.push({
        dia: i + 1,
        previsao: sum_day[i],
      });
    }

    for (let i = previsao.length; i < dias_mes; i++) {
      if (i - 7 < 0) {
        previsao.push({
          dia: i + 1,
          previsao: media,
        });
      } else if (i - 14 < 0) {
        previsao.push({
          dia: i + 1,
          previsao: previsao[i - 7].previsao,
        });
      } else if (i - 21 < 0) {
        previsao.push({
          dia: i + 1,
          previsao: (previsao[i - 7].previsao + previsao[i - 14].previsao) / 2,
        });
      } else {
        previsao.push({
          dia: i + 1,
          previsao:
            (previsao[i - 7].previsao +
              previsao[i - 14].previsao +
              previsao[i - 21].previsao) /
            3,
        });
      }
    }

    this.setState({ previsao });
    this.setState({ sum_day });
    this.setState({ days });
    this.setState({ show: true });
  };

  handleCadastroConsumo = async (e) => {
    e.preventDefault();

    const { CNPJ, days, previsao } = this.state;

    try {
      const response = await faradayApi.post("previsao", {
        CNPJ,
        Dias_Computados: days,
        Previsao: previsao,
      });
      //   console.log(response);
      window.location.reload();
    } catch (e) {
      alert("erro ao cadastrar");
    }
  };

  render() {
    const { Empresas, days, show, previsao, dias_mes, nome_mes } = this.state;

    return (
      <div className="Consumo-input-container-master">
        <div className="Consumo-input-container">
          <img src={BlackLogo} alt="logo" className="logo-consumo-input" />
          <p className="title-excel-input">Consumo Input</p>

          <Form
            onSubmit={this.handleSubmit}
            className="Consumo-input-container-content"
          >
            <div className="input-container-excel">
              <p className="input-excel-label">Selecione a empresa:</p>
              <select
                name="empresas"
                onChange={(e) => this.setState({ CNPJ: e.target.value })}
              >
                {Empresas.map((empresa) => (
                  <option key={empresa._id} value={empresa.CNPJ}>
                    {empresa.Nome}
                  </option>
                ))}
              </select>
            </div>

            <div className="input-container-excel">
              <CSVReader
                onFileLoaded={(data, fileInfo) => this.setState({ data })}
              />
            </div>

            <div className="input-container-excel">
              <Button type="submit" className="button-excel">
                Ver previsões
              </Button>
            </div>
          </Form>

          {show && (
            <div>
              <p className="input-excel-label">
                Você está cadastrando o mês de {nome_mes}, esse mês tem{" "}
                {dias_mes} dias.
              </p>
              <p className="input-excel-label">
                Dias computados de consumo: {days}
              </p>

              {previsao.map((day) => (
                <div key={day.dia}>
                  {day.dia <= days && (
                    <div className="dias-computados-match" key={day.dia}>
                      <p className="dias-computados-dia-1">Dia {day.dia}:</p>
                      <p className="dias-computados-dia">
                        {day.previsao.toFixed(2)}
                      </p>
                    </div>
                  )}
                  {day.dia > days && (
                    <div className="dias-computados" key={day.dia}>
                      <p className="dias-computados-dia-1">Dia {day.dia}:</p>
                      <p className="dias-computados-dia">
                        {day.previsao.toFixed(2)}
                      </p>
                    </div>
                  )}
                </div>
              ))}

              <Button
                onClick={this.handleCadastroConsumo}
                className="button-excel"
              >
                Confirmar Cadastro
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConsumoInput;
