import React, { useState } from "react"; // Importe o React corretamente
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function SendModal({ show, onHide, setShow, error, setError }) {
  const handleClick = () => {
    setError(false);
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              {!error ? "Documentos enviados!" : "Erro ao enviar documentos!"}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ boxShadow: "0px 0px 0px", padding: "8px" }}>
          {!error
            ? "Seus documentos foram enviados ao time comercial e passarão por uma análise. Te devolveremos uma resposta em até 48h úteis!"
            : "Houve algum erro durante o envio dos seu(s) documento(s), se certifique que você esteja enviando um PDF, ou tente novamente mais tarde!"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant={!error ? "primary" : "danger"} onClick={handleClick}>
            Entendi
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendModal;
