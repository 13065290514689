import axios from "axios";

import { getToken } from "./auth";

const faradayApi = axios.create({
  baseURL: "https://faraday.merxenergia.com.br/",
  //baseURL: "https://faraday-merxenergia.up.railway.app/",
  //baseURL: "http://localhost:8080/",
  // baseURL: "https://faraday-dev-staging.up.railway.app/",
});

faradayApi.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default faradayApi;
