import React, { useEffect, useRef, useState } from "react";
import CardContent from "../CardContent/index";
import "./style.css";
import { mesesExt } from "../../utils/utilityFunctions";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";

const CardsGrid = ({ monthData }) => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(monthData);
  }, [monthData]);
  const filteredData = monthData
    .filter((item) => item.isMonthRetroactive === false)
    .sort((a, b) => {
      if (a.year !== b.year) {
        return (a.year - b.year) * -1;
      }

      return (a.month - b.month) * -1;
    });

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const carousel = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);
  }, []);

  return (
    <div className="container-all">
      {/* {isMobile ?
      (
          <motion.div className="container-carousel" whileTap={{cursor: 'grabbing'}} ref={carousel} drag="x"
          dragConstraints={{right: 0, left: -width}}
          initial={{x: 100}}
          animate={{x: 0}}
          transition={{duration: 0.8}}>
            {sortedDates.map((item) => (
              <motion.div>
              <CardContent
                key={item._id}
                date={item.Mes}
                status={item.Status}
                fullWidth="false"
                info={item}
                style={{
                  margin: "5px",
                  paddingRight: "5px",
                  marginBottom: "5px",
                }}
              />
              </motion.div>
            ))}
          </motion.div>
      )
      : */}

      <div className="cards-container">
        {filteredData.map((item) => (
          <CardContent
            key={item.id}
            date={item.month}
            status={item.status}
            costCative
            fullWidth="false"
            info={item}
            style={{
              marginRight: "5px",
              paddingRight: "5px",
              marginBottom: "5px",
            }}
          />
        ))}
      </div>

      {/* } */}
    </div>
  );
};

export default CardsGrid;
