import React, { useState, useEffect } from "react";
import { getToken, getUserId, logout } from "../../Services/auth";

import Navbar from "../../Components/Navbar/index";
import FormSimulacao from "../../Components/FormSimulacao/index";
import Footer from "../../Components/Footer/index";

import "./style.css";

export default function Simulacao() {
  return (
    <div className="container-simulacao-master">
      <Navbar changeColor="true" />
      <FormSimulacao />
      <Footer />
    </div>
  );
}
