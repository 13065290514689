import React, { Component } from "react";
import "./style.css";

import CardContentModal from "../../Components/cardContentModal/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faCheck,
  faTimes,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

class CardContent extends Component {
  constructor(props) {
    super();
    var date = props.date;
    var fullWidth = props.fullWidth;
    var text = "";
    var status = props.status;
    var widthContent;
    var borderColor;

    switch (fullWidth) {
      case "true":
        widthContent = "100%";
        break;
      case "false":
        widthContent = "375px";
        break;
      default:
      // console.log('lixo');
    }

    if (props.status === "NAO_FINALIZADO") {
      borderColor = "#cf3217";
    } else if (
      props.status === "EM_CURTO_PRAZO" ||
      props.info.costCativo === 0
    ) {
      borderColor = "#FF9C27";
    } else if (props.status === "FINALIZADO") {
      borderColor = "green";
    } else {
      borderColor = "#222";
    }

    // if (props.status === "FINALIZADO") {
    //   borderColor = "green";
    // } else if (props.status === "EM_CURTO_PRAZO") {
    //   borderColor = "#FF9C27";
    // } else if (props.status === "3") {
    //   borderColor = "red";
    // } else {
    //   borderColor = "#222";
    // }

    this.state = {
      date,
      text,
      borderColor,
      widthContent,
      status,
      info: props.info,
    };
  }

  loginModalRef = (obj) => {
    this.showModal = obj && obj.handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  render() {
    const divStyle = {
      borderLeftColor: this.state.borderColor,
    };

    const chooseRender = () => {
      if (this.props.status === "NAO_FINALIZADO") {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faClock}
              className="verticalIcon"
              style={{ color: "#cf3217" }}
            />
            <p style={{ fontSize: "10px", marginLeft: 12 }}>Em projeção</p>
          </div>
        );
      } else if (
        this.props.status === "EM_CURTO_PRAZO" ||
        this.props.info.costCativo === 0
      ) {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faExclamation}
              className="verticalIcon"
              style={{ color: "#FF9C27", marginLeft: 12 }}
            />
            <p style={{ fontSize: "10px" }}>Em operação</p>
          </div>
        );
      } else if (this.props.status === "FINALIZADO") {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faCheck}
              className="verticalIcon"
              style={{ color: "green", marginLeft: 8 }}
            />
            <p style={{ fontSize: "10px" }}>Completo</p>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faExclamation}
              className="verticalIcon"
              style={{ color: "#222", marginLeft: 7 }}
            />
            <p style={{ fontSize: "10px" }}>ERRO</p>
          </div>
        );
      }
    };

    function getMonthName(month, year) {
      switch (month) {
        case 1:
          return "Janeiro de " + year;
        case 2:
          return "Fevereiro de " + year;
        case 3:
          return "Março de " + year;
        case 4:
          return "Abril de " + year;
        case 5:
          return "Maio de " + year;
        case 6:
          return "Junho de " + year;
        case 7:
          return "Julho de " + year;
        case 8:
          return "Agosto de " + year;
        case 9:
          return "Setembro de " + year;
        case 10:
          return "Outubro de " + year;
        case 11:
          return "Novembro de " + year;
        case 12:
          return "Dezembro de " + year;
        default:
          return "Mês inválido";
      }
    }

    return (
      <div>
        <CardContentModal
          date={getMonthName(this.state.info.month, this.state.info.year)}
          content={this.state.text}
          status={this.state.status}
          fullWidth="true"
          ref={this.loginModalRef}
          info={this.state.info}
        ></CardContentModal>

        <div
          className="cardContent-container"
          style={{
            borderLeftColor: this.state.borderColor,
            display: `${
              this.state.info.is_month_retroactive ? "none" : "flex"
            }`,
          }}
          onClick={this.onLoginClick}
        >
          <div className="cardContent-header">
            <p className="cardContent-title">
              {getMonthName(this.state.info.month, this.state.info.year)}
            </p>

            <div className="icon-box-card">{chooseRender()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardContent;
