import React from "react";
import "./styles.css";
import NavBar from "../../Components/Navbar/index";
import Header from "../../Components/Header/index";
import HeaderFooter from "../../Components/HeaderFooter/index";
import Compare from "../../Components/Compare/index";
import About from "../../Components/About/index";

import Footer from "../../Components/Footer/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import ManifestMerx from "../../Components/ManifestMerx";
import Advantages from "../../Components/Advantages";
import PartnerAdvantages from "../../Components/PartnerAdvantages";
import Revolution from "../../Components/Revolution";
import SelfProductionBenefits from "../../Components/SelfProductionBenefits";
export default function Main() {
  return (
    <div className="master-container">
      <a href="https://wa.me/5581982136133" className="float">
        <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
      </a>
      <NavBar changeColor="true" />
      <Header />
      <HeaderFooter />
      <Compare />
      <About />
      {/*  Comentado enquanto não temos o vídeo 
      <ManifestMerx />
       */}
      <Advantages />
      <PartnerAdvantages />
      <Revolution />
      <SelfProductionBenefits />
      <Footer />
      <a href="https://wa.me/5581982136133" className="float">
        <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
      </a>
    </div>
  );
}
