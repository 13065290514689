import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

import faradayApi from "../../Services/faradayApi";

class LoginModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      Titulo: "Convencional",
      Valor: "-",
      Regiao: "",
      DataInicio: "",
      DataFim: "",
      Preco: "",
      defVolume: false,
      error: "",
      waiting: false,
    };
  }

  handleShow() {
    // console.log(this.state)
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleSignIn = async (e) => {
    e.preventDefault();
    const { Titulo, Valor, Regiao, DataInicio, DataFim, Preco } = this.state;

    const response = await faradayApi.post("cardMarket", {
      Titulo,
      Valor,
      Regiao,
      DataInicio,
      DataFim,
      Preco,
    });

    // console.log(response)
    window.location.reload();
  };

  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogClassName="custom-dialog"
          centered
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Modal.Body className="modal-body">
            <div className="container-modal">
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={this.handleClose}
              />
              <Form onSubmit={this.handleSignIn}>
                <Form.Group controlId="formGroupHeader">
                  <Form.Label
                    className="text-center loginTitle"
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Cadastrar nova oferta
                  </Form.Label>
                </Form.Group>

                <Form.Group controlId="formGroupEmail">
                  <Form.Control
                    as="select"
                    onChange={(e) => this.setState({ Titulo: e.target.value })}
                    required
                    className="text-center center-input"
                    style={{ width: "268px" }}
                  >
                    <option value="Convencional" className="text-center">
                      Convencional
                    </option>
                    <option value="Incentivada 50%" className="text-center">
                      Incentivada 50%
                    </option>
                    <option value="Incentivada 100%" className="text-center">
                      Incentivada 100%
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formGroupEmail">
                  <Form.Control
                    as="select"
                    onChange={(e) =>
                      this.setState({ defVolume: e.target.value })
                    }
                    required
                    className="text-center center-input"
                    style={{ width: "268px" }}
                  >
                    <option value="nao" className="text-center">
                      Não definir volume
                    </option>
                    <option value="sim" className="text-center">
                      Definir volume
                    </option>
                  </Form.Control>
                </Form.Group>

                {this.state.defVolume === "sim" && (
                  <Form.Group controlId="formGroupPassword">
                    <Form.Control
                      className="text-center center-input"
                      type="text"
                      placeholder="Volume (MWm)"
                      style={{ width: "268px" }}
                      onChange={(e) => this.setState({ Valor: e.target.value })}
                    />
                  </Form.Group>
                )}

                <Form.Group controlId="formGroupPassword">
                  <Form.Control
                    className="text-center center-input"
                    type="text"
                    placeholder="Regiao"
                    style={{ width: "268px" }}
                    onChange={(e) => this.setState({ Regiao: e.target.value })}
                  />
                </Form.Group>

                <Form.Group controlId="formGroupPassword">
                  <Form.Control
                    className="text-center center-input"
                    type="text"
                    placeholder="Data Início"
                    style={{ width: "268px" }}
                    onChange={(e) =>
                      this.setState({ DataInicio: e.target.value })
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formGroupPassword">
                  <Form.Control
                    className="text-center center-input"
                    type="text"
                    placeholder="Data fim"
                    style={{ width: "268px" }}
                    onChange={(e) => this.setState({ DataFim: e.target.value })}
                  />
                </Form.Group>

                <Form.Group controlId="formGroupPassword">
                  <Form.Control
                    className="text-center center-input"
                    type="text"
                    placeholder="Preço"
                    style={{ width: "268px" }}
                    onChange={(e) => this.setState({ Preco: e.target.value })}
                  />
                </Form.Group>

                <button
                  className="fb-button color-change buffer-space-login-2"
                  type="submit"
                >
                  Cadastrar
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default LoginModal;
