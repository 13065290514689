import React from "react";

const CativoTable = ({
  livreData,
  cativoData,
  economia,
}: {
  livreData: {
    demandaForaPonta: number;
    demandaPonta: number;
    parcelaDemandaForaPonta: number;
    parcelaDemandaPonta: number;
    custoBeneficioTarifario: number;
    gestao: number;
    consumoPonta: number;
    parcelaConsumoPonta: number;
    consumoForaPonta: number;
    parcelaConsumoForaPonta: number;
    custoEnergia: number;
    custoConexao: number;
    custoTotal: number;
    encargos: number;
  };
  cativoData: {
    bandeiras: {
      custoCativoAmarela: number;
      custoCativoVermelhaP1: number;
      custoCativoVermelhaP2: number;
    };
    gerador: {
      consumoGerador: number;
      custoDiesel: number;
      custoGerador: number;
      custoTotalGerador: number;
    };
    demandaForaPonta: number;
    parcelaDemandaForaPonta: number;
    demandaPonta: number;
    parcelaDemandaPonta: number;
    demandaForaPontaUltrapassagem: number;
    parcelaDemandaForaPontaUltrapassagem: number;
    demandaPontaUltrapassagem: number;
    parcelaDemandaPontaUltrapassagem: number;
    consumoPonta: number;
    parcelaConsumoPonta: number;
    parcelaTUSDPonta: number;
    parcelaTEPonta: number;
    consumoForaPonta: number;
    parcelaConsumoForaPonta: number;
    parcelaTUSDForaPonta: number;
    parcelaTEForaPonta: number;
    custoConexao: number;
    custoEnergia: number;
    custoTotal: number;
  };
  economia: number;
}) => {
  return (
    <>
      <div className="containerResSimulMaster">
        <div className="containerResSimul">
          <p className="containerResSimulTitle">Consumidor cativo</p>
          <div className="ResSimulTableTitle">
            <p className="ResSimulTable" style={{ textAlign: "left" }}>
              Grandeza Elétrica
            </p>
            <p className="ResSimulTable">Grandezas Faturadas</p>
            <p className="ResSimulTable">Faturado</p>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada FP
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.demandaForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaDemandaForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada P
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.demandaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaDemandaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Ultrapassagem FP
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.demandaForaPontaUltrapassagem.toLocaleString(
                "pt-BR",
                {
                  style: "decimal",
                }
              )}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaDemandaForaPontaUltrapassagem.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Ultrapassagem P
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.demandaPontaUltrapassagem.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaDemandaPontaUltrapassagem.toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaTUSDForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaTUSDPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TE FP
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaTEForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TE P
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.parcelaTEPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Gerador
            </p>
            <p className="ResSimulTableContentC">
              {cativoData.gerador.consumoGerador.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {cativoData.gerador.custoTotalGerador.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <hr />

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Total
            </p>
            <p className="ResSimulTableContent"></p>
            <div className="separarNum">
              <p
                className="ResSimulTableContentB"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {cativoData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="containerResSimul">
          <p className="containerResSimulTitle">Consumidor livre</p>
          <div className="ResSimulTableTitle">
            <p className="ResSimulTable" style={{ textAlign: "left" }}>
              Grandeza Elétrica
            </p>
            <p className="ResSimulTable">Grandezas Faturadas</p>
            <p className="ResSimulTable">Faturado</p>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada FP
            </p>
            <p className="ResSimulTableContentC">
              {livreData.demandaForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaDemandaForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Demanda Contratada P
            </p>
            <p className="ResSimulTableContentC">
              {livreData.demandaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kW
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaDemandaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD FP
            </p>
            <p className="ResSimulTableContentC">
              {livreData.consumoForaPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaConsumoForaPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              TUSD P
            </p>
            <p className="ResSimulTableContentC">
              {livreData.consumoPonta.toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>

            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.parcelaConsumoPonta.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Total energia ACL
            </p>
            <p className="ResSimulTableContentC">
              {(
                livreData.consumoForaPonta + livreData.consumoPonta
              ).toLocaleString("pt-BR", {
                style: "decimal",
              })}{" "}
              kWh
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.custoEnergia.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Benefício tarifário
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.custoBeneficioTarifario.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Encargos
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.encargos.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p className="ResSimulTableContent" style={{ textAlign: "left" }}>
              Custo Operacional e CCEE
            </p>
            <p
              className="ResSimulTableContentC"
              style={{ textAlign: "center" }}
            >
              -
            </p>
            <div className="separarNum">
              <p className="ResSimulTableContentB">
                {livreData.gestao.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{ textAlign: "left" }}
            ></p>
            <p
              className="ResSimulTableContentD"
              style={{ textAlign: "center", lineHeight: "34px" }}
            ></p>
            <div className="separarNum">
              <p className="ResSimulTableContentB"></p>
            </div>
          </div>

          <hr />

          <div className="ResSimulTableTitle">
            <p
              className="ResSimulTableContent"
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Total
            </p>
            <p className="ResSimulTableContent"></p>
            <div className="separarNum">
              <p
                className="ResSimulTableContentB"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {livreData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "16px" }}>
        <span className="simulacao-form-header">Bandeiras</span>
        <hr />
      </div>

      <div className="resultadoSimulacaoContainer">
        <div className="bandeirasDiv">
          <span className="bandeirasTitle">Custo cativo</span>
          <div className="economia-card">
            <h1 style={{ display: "flex", justifyContent: "space-between" }}>
              Verde
              <span style={{ fontWeight: "bold" }}>
                {cativoData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </h1>
            <div className="bandeiraVerde" />
          </div>
        </div>

        <div className="bandeirasDiv">
          <span className="bandeirasTitle">Custo livre</span>
          <div className="economia-card">
            <h1 style={{ display: "flex", justifyContent: "space-between" }}>
              Livre
              <span style={{ fontWeight: "bold" }}>
                {livreData.custoTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </span>
            </h1>
            <div className="bandeiraAzul" />
          </div>
        </div>

        <div className="bandeirasDiv">
          <span className="bandeirasTitle">Economia Mensal</span>
          <div className="economia-card">
            <h1 style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontWeight: "bold" }}>
                {(cativoData.custoTotal - livreData.custoTotal).toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </span>
              <span
                style={{ fontWeight: "bold" }}
                className={
                  cativoData.custoTotal - livreData.custoTotal >= 0
                    ? "economia-positivo"
                    : "economia-negativo"
                }
              >
                {(
                  (cativoData.custoTotal - livreData.custoTotal) /
                  cativoData.custoTotal
                ).toLocaleString("pt-BR", {
                  style: "percent",
                })}
              </span>
            </h1>
            <div className="bandeiraCinza" />
          </div>
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Amarela
            <span style={{ fontWeight: "bold" }}>
              {cativoData.bandeiras.custoCativoAmarela.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAmarela" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Livre
            <span style={{ fontWeight: "bold" }}>
              {livreData.custoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAzul" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>
              {(
                cativoData.bandeiras.custoCativoAmarela - livreData.custoTotal
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <span
              style={{ fontWeight: "bold" }}
              className={
                cativoData.bandeiras.custoCativoAmarela -
                  livreData.custoTotal >=
                0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (cativoData.bandeiras.custoCativoAmarela -
                  livreData.custoTotal) /
                cativoData.bandeiras.custoCativoAmarela
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </span>
          </h1>
          <div className="bandeiraCinza" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Vermelha P1
            <span style={{ fontWeight: "bold" }}>
              {cativoData.bandeiras.custoCativoVermelhaP1.toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </span>
          </h1>
          <div className="bandeiraVermelhaP1" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Livre
            <span style={{ fontWeight: "bold" }}>
              {livreData.custoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAzul" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>
              {(
                cativoData.bandeiras.custoCativoVermelhaP1 -
                livreData.custoTotal
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <span
              style={{ fontWeight: "bold" }}
              className={
                cativoData.bandeiras.custoCativoVermelhaP1 -
                  livreData.custoTotal >=
                0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (cativoData.bandeiras.custoCativoVermelhaP1 -
                  livreData.custoTotal) /
                cativoData.bandeiras.custoCativoVermelhaP1
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </span>
          </h1>
          <div className="bandeiraCinza" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Vermelha P2
            <span style={{ fontWeight: "bold" }}>
              {cativoData.bandeiras.custoCativoVermelhaP2.toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </span>
          </h1>
          <div className="bandeiraVermelhaP2" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            Livre
            <span style={{ fontWeight: "bold" }}>
              {livreData.custoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </h1>
          <div className="bandeiraAzul" />
        </div>

        <div className="economia-card">
          <h1 style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "bold" }}>
              {(
                cativoData.bandeiras.custoCativoVermelhaP2 -
                livreData.custoTotal
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <span
              style={{ fontWeight: "bold" }}
              className={
                cativoData.bandeiras.custoCativoVermelhaP2 -
                  livreData.custoTotal >=
                0
                  ? "economia-positivo"
                  : "economia-negativo"
              }
            >
              {(
                (cativoData.bandeiras.custoCativoVermelhaP2 -
                  livreData.custoTotal) /
                cativoData.bandeiras.custoCativoVermelhaP2
              ).toLocaleString("pt-BR", {
                style: "percent",
              })}
            </span>
          </h1>
          <div className="bandeiraCinza" />
        </div>
      </div>
    </>
  );
};

export default CativoTable;
