import { StyleSheet, Font } from "@react-pdf/renderer";
import Arial from "../../Fonts/ArielFont/ARIAL.TTF";
import ArialBolden from "../../Fonts/ArielFont/ARIALBD1.TTF";

Font.register({
  family: "Arial",
  src: Arial,
});

Font.register({
  family: "ArialBold",
  src: ArialBolden,
});
export const firstPageStyles = StyleSheet.create({
  FirstPageTexts: {
    backgroundColor: "rgb(0, 43, 99)",
    color: "white",
    marginTop: 200,
    padding: 40,
  },
  SolarFortlev: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 10,
    width: "15%",
  },
  SolarFortlevText: {
    width: 100,
    marginLeft: 15,
  },
  TitleFirstPage: {
    fontSize: 40,
    paddingHorizontal: 10,
    textDecoration: "underline",
    textAlign: "center",
    fontFamily: "ArialBold",
  },
  SubtitleFirstPage: {
    marginTop: 5,
    fontSize: 30,
    textAlign: "center",
    fontFamily: "ArialBold",
  },
  ViewLogos: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop: 20,
    family: "Arial",
  },
  ImgLogo: {
    width: "15%",
  },
  ImgLogoFortlev: {
    width: "100%",
  },
  textSimple: {
    marginTop: 20,
    color: "rgb(0, 43, 99)",
    textAlign: "center",
  },
});
export const SecondPageStyles = StyleSheet.create({
  TotalPage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  PageT: {
    paddingHorizontal: "5%",
    width: "50%",
    height: "100%",
  },
  ValueMWp: {
    fontSize: 16,
    fontWeight: 600,
    width: 400,
    color: "#878787",
    fontFamily: "ArialBold",
    marginTop: 40,
  },

  Bar: {
    width: 150,
  },
  TitleTable: {
    marginTop: 20,
    fontSize: 20,
    backgroundColor: "rgb(0, 43, 99)",
    padding: 5,
    color: "#FFFFFF",
    textAlign: "center",
    borderRadius: 5,
    width: 400,
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#e1e1e1",
    width: 400,
    textAlign: "center",
    borderRadius: 5,
    border: "1px solid #c6c6c6",
    fontFamily: "ArialBold",
  },
  Column: {
    width: 200,
    borderLeft: "1px solid #c6c6c6",
    padding: 10,
    fontSize: 12,
  },
  ColumnTwo: {
    width: 200,
    borderLeft: "1px solid #c6c6c6",
    padding: 10,
    fontSize: 12,
  },
  OrangeRow: {
    backgroundColor: "#EE7528",
    width: 400,
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    padding: 10,
    fontSize: 15,
    marginTop: 10,
    borderRadius: 5,
  },
  DataTitle: { fontFamily: "Arial" },
  DataValue: { fontFamily: "ArialBold" },
  UsinPhoto: {
    display: "flex",
    marginTop: 40,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  ImageSolarOne: {
    maxWidth: 300,
    position: "relative",
    borderRadius: 5,
  },
  ImageSolarTwo: {
    height: 200,
    width: 200,
    position: "relative",
    marginTop: 50,
    borderRadius: 5,
    objectFit: "cover",
  },

  MwpPhoto: {
    position: "absolute",
    top: 10,
    left: 10,
    backgroundColor: "#EE7528",
    color: "white",
    fontFamily: "ArialBold",
    padding: 5,
    borderRadius: 5,
  },
  MwpPhotoTwo: {
    position: "absolute",
    bottom: 10,
    left: 10,
    backgroundColor: "#EE7528",
    color: "white",
    fontFamily: "ArialBold",
    padding: 5,
    borderRadius: 5,
  },
});
export const TermPageStyles = StyleSheet.create({
  TermPage: {
    paddingHorizontal: "5%",
  },
  TermAndLogo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 40,
    marginTop: 40,
  },
  Accept: {
    fontSize: 30,
    fontWeight: 600,
    color: "#878787",
    fontFamily: "ArialBold",
  },
  FortlevLogo: {
    width: 150,
  },
  TextsAccept: {
    marginBottom: 20,
  },
  TextsAcceptBold: {
    marginBottom: 20,
    fontFamily: "ArialBold",
  },
  TextData: {
    textAlign: "right",
    marginTop: 40,
    marginBottom: 60,
    fontFamily: "ArialBold",
  },
  DocSign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    family: "Arial",
  },
  SignFortlev: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  AssignCamp: {
    paddingBottom: 5,
  },
  TextCenter: {
    marginTop: 5,
  },
});
export const ContactPagesStyle = StyleSheet.create({
  AllContent: {
    padding: 20,
  },
  TermAndLogo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 40,
  },
  Contact: {
    fontSize: 30,
    fontWeight: 600,
    color: "#878787",
    fontFamily: "ArialBold",
  },
  Bar: {
    width: 150,
  },
  ContactBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  ImageContact: {
    width: 200,
    height: 200,
    borderRadius: "100%",
  },
  BoxInfosContact: {
    marginLeft: 20,
  },
  BlueFortlevText: {
    color: "rgb(0, 43, 99)",
    fontSize: 20,
  },
  EmailLink: {
    color: "#003dd4",
    fontSize: 15,
    textDecoration: "underline",
    marginTop: 10,
  },
  ContactNumber: {
    fontSize: 15,
    color: "rgb(0, 43, 99)",
  },
});

export const ViabilityPagesStyle = StyleSheet.create({
  Page: {
    display: "flex",
    flexDirection: "row",
  },
  Side: {
    width: 400,
    fontFamily: "Arial",
    paddingLeft: 20,
    paddingTop: 20,
    height: "100%",
  },
  TitlePage: {
    fontSize: 30,
    fontWeight: 600,
    color: "#878787",
    fontFamily: "ArialBold",
  },
  Bar: {
    width: 150,
  },
  Graphs: {
    marginTop: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  GraphCativMarket: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 120,
  },
  TitleGraph: {
    fontSize: 15,
    fontFamily: "ArialBold",
    textAlign: "center",
  },
  GraphCativ: {
    marginTop: 5,
    width: 120,
    height: 390,
    backgroundColor: "#002B63",
    display: "block",
    borderRadius: 5,
    border: "2px solid #C7C5C4",
  },
  TitleCativConsumer: {
    width: 120,
    textAlign: "center",
    marginTop: 5,
    fontFamily: "ArialBold",
    fontSize: 15,
  },
  TitleFreeConsumer: {
    width: 100,
    textAlign: "center",
    marginTop: 5,
    fontFamily: "ArialBold",
    fontSize: 15,
  },
  GraphFreeMarket: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    width: 120,
    marginLeft: 40,
  },
  TitleGraphFreeMarket: {
    fontFamily: "ArialBold",
    fontSize: 14,
    paddingBottom: 5,
    width: 100,
    color: "#34C759",
    textAlign: "center",
  },
  GraphFreeMarketTotalGraph: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 5,
  },

  Charges: {
    width: 100,
    height: 40,
    backgroundColor: "#F1C40F",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    border: "2px solid #C7C5C4",
    borderBottom: "none !important",
  },
  Energy: {
    width: 100,
    height: 90,
    backgroundColor: "#1ABC9C",
    border: "2px solid #C7C5C4",
    borderBottom: "none !important",
    borderTop: "none !important",
  },
  Distributor: {
    width: 100,
    height: 110,
    backgroundColor: "#F24E1E",
    border: "2px solid #C7C5C4",
    borderBottom: "none !important",
    borderTop: "none !important",
  },
  Merx: {
    width: 100,
    height: 20,
    backgroundColor: "#1ACA6F",
    border: "2px solid #C7C5C4",
    borderTop: "none !important",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  GraphPartAndTitleGraph: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  TitleCharges: {
    color: "#F1C40F",
    textDecoration: "underline",
    marginLeft: 10,
    fontSize: 13,
  },

  TitleEnergy: {
    color: "#12A7D0",
    textDecoration: "underline",
    marginLeft: 10,
    fontSize: 13,
  },
  TitleDistributor: {
    color: "#F24E1E",
    textDecoration: "underline",
    marginLeft: 10,
    fontSize: 13,
  },
  TitleMerx: {
    color: "#1ACA6F",
    textDecoration: "underline",
    marginLeft: 10,
    fontSize: 13,
  },
  TotalCost: {
    display: "flex",
    flexDirection: "row",
  },
  Comparative: {
    height: "50%",
    marginTop: 20,
    marginLeft: 20,

    justifyContent: "center",
  },
  ComparativeTitle: {
    fontFamily: "ArialBold",
    fontSize: 13,
    textAlign: "center",
  },
  CativeCost: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  FreeCost: {
    marginTop: 20,
    marginLeft: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  MonthlyEconomy: {
    marginTop: 20,
    marginLeft: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  CativeCostTitle: {
    fontFamily: "ArialBold",
    width: 135,
    textAlign: "center",
  },
  Flag: {
    width: 135,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#cfcfcf",
    padding: 5,
    borderRadius: 4,
    marginTop: 10,
  },
  TextFlag: {
    fontFamily: "ArialBold",
    fontSize: 12,
    color: "black",
  },
  TextFlagPercent: {
    fontFamily: "ArialBold",
    fontSize: 12,
    color: "black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 110,
  },
  FlagGreen: {
    width: 110,
    height: 5,
    borderRadius: 1,
    display: "block",
    backgroundColor: "#1FA824",
  },
  Flag0: {
    width: 110,
    height: 5,
    borderRadius: 1,
    display: "block",
    backgroundColor: "#EAC52E",
  },
  Flag1: {
    width: 110,
    height: 5,
    borderRadius: 1,
    display: "block",
    backgroundColor: "#DF2D2D",
  },
  Flag2: {
    width: 110,
    height: 5,
    borderRadius: 1,
    display: "block",
    backgroundColor: "#AA1818",
  },
  FlagFree: {
    width: 100,
    height: 5,
    borderRadius: 1,
    display: "block",
    backgroundColor: "#368CFC",
  },
  InvoiceDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 20,
  },
  TitleInvoice: {
    fontFamily: "ArialBold",
    marginTop: 20,
    marginLeft: 20,
  },
  InvoiceContent: {
    width: 400,
    marginTop: 10,
    marginLeft: 20,
  },
  HeadInvoice: {
    backgroundColor: "#AFAFAF",
    paddingHorizontal: 5,
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  TextHeadInvoice: {
    fontFamily: "ArialBold",
    width: 150,
    fontSize: 15,
    textAlign: "center",
  },
  RowImparInvoice: {
    backgroundColor: "#E5E5E5",
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  TextRowImparInvoice: {
    fontFamily: "ArialBold",
    width: 150,
    fontSize: 15,
    textAlign: "center",
  },
  TextTitleInvoice: {
    fontFamily: "Arial",
    width: 150,
    fontSize: 15,
    textAlign: "center",
  },
  RowParInvoice: {
    backgroundColor: "#CBCBCB",
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  TextRowParInvoice: {
    fontFamily: "ArialBold",
    width: 150,
    fontSize: 15,
    textAlign: "center",
  },
  BottomInvoice: {
    backgroundColor: "#AFAFAF",
    paddingHorizontal: 5,
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
});

export const FeasibilityAnalysis = StyleSheet.create({
  TitleBar: {
    padding: 20,
    fontFamily: "ArialBold",
  },
  TitlePage: {
    fontSize: 25,
    fontWeight: 600,
    color: "#878787",
  },
  Bar: {
    width: 150,
  },
  ImageGraph: {
    width: 800,
    paddingVertical: 20,
    marginLeft: 20,
    height: 300,
    marginTop: 10,
  },
  TableViability: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TableHead: {
    color: "#FFF",
    backgroundColor: "#002B63",
    textAlign: "center",
    padding: 5,
    width: 400,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    fontFamily: "ArialBold",
    fontSize: 13,
  },
  TableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#e1e1e1",
    width: 400,
    borderBottom: "1px solid #c6c6c6",
    fontFamily: "ArialBold",
  },

  TextTable: {
    width: 200,
    textAlign: "center",
    paddingVertical: 5,
    fontSize: 13,
  },
  ViewImageGraph: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  TableFooter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#e1e1e1",
    width: 400,
    borderBottom: "1px solid #c6c6c6",
    fontFamily: "ArialBold",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  TextTableTitle: {
    width: 200,
    textAlign: "center",
    fontSize: 13,
    paddingVertical: 5,
    borderRight: "1px solid #c6c6c6",
  },
  GraphDescription: {
    marginTop: 10,
    textAlign: "center",
    fontFamily: "ArialBold",
  },
});

export const EstimatedCapex = StyleSheet.create({
  Page: {
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  TitleBar: {
    fontFamily: "ArialBold",
  },
  TitlePage: {
    fontSize: 25,
    fontWeight: 600,
    color: "#878787",
  },
  Bar: {
    width: 150,
  },
  FullText: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 10,
  },
  TableViability: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 40,
  },
  OBS: {
    marginTop: 20,
  },
  Bold: {
    fontFamily: "ArialBold",
  },
  TableHead: {
    color: "#FFF",
    backgroundColor: "#002B63",
    textAlign: "center",
    width: 800,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    fontFamily: "ArialBold",
    display: "flex",
    flexDirection: "row",
  },
  TableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#e1e1e1",
    width: 800,
    borderBottom: "1px solid #c6c6c6",
  },

  TextTableTitle: {
    width: 400,
    marginLeft: 20,
    paddingVertical: 5,
    borderRight: "1px solid #c6c6c6",
    fontSize: 13,
    fontFamily: "ArialBold",
  },

  TextTableHead: {
    width: 127,
    textAlign: "center",
    paddingVertical: 5,
    fontSize: 13,
    borderRight: "1px solid #c6c6c6",
    fontFamily: "ArialBold",
  },
  TextTableMainInfos: {
    width: 400,
    textAlign: "center",
    paddingVertical: 5,
    marginLeft: 20,
    fontSize: 13,
    borderRight: "1px solid #c6c6c6",
    fontFamily: "ArialBold",
  },
  TextTable: {
    width: 127,
    textAlign: "center",
    paddingVertical: 5,
    fontSize: 13,
    borderRight: "1px solid #c6c6c6",
    fontFamily: "Arial",
  },
  TableFooter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#e1e1e1",
    width: 800,
    textAlign: "center",
    borderBottom: "1px solid #c6c6c6",
    fontFamily: "ArialBold",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
});
