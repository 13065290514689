import React from "react";

export default function ConsumeGraph(props) {
  const info = props.info;

  return (
    <div
      className="AnaliseTable-container"
      style={{ justifyContent: "flex-start" }}
    >
      <div
        className="consume-header"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p className="consume-title-analise">Balanço mensal</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "14px",
          marginTop: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            Contrato
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.volumeToInvoice && !isNaN(info.volumeToInvoice)
              ? parseFloat(info.volumeToInvoice)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            Consumo
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(info.relativeConsume && !isNaN(info.relativeConsume)
              ? parseFloat(info.relativeConsume)
              : 0.0
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 20px",
            fontSize: "14px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              width: "50%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {parseFloat(info.volumeToInvoice) -
              parseFloat(info.relativeConsume) >
            0
              ? "Sobra de energia"
              : parseFloat(info.volumeToInvoice) -
                  parseFloat(info.relativeConsume) <
                0
              ? "Déficit de energia"
              : "Sem sobra/déficit"}
          </p>
          <p
            style={{
              textAlign: "center",
              width: "50%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {(
              parseFloat(info.volumeToInvoice) -
              parseFloat(info.relativeConsume)
            ).toLocaleString("pt-BR", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            MWh
          </p>
        </div>
        {/* <div style={{display: "flex", justifyContent: "center", width: "100%", fontSize: "14px"}}>
            <b style={{textAlign: "center"}}>Subcontratado</b>
        </div> */}
      </div>
    </div>
  );
}
