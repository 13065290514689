import React, { Component } from "react";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faCheck,
  faTimes,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

class CardContent extends Component {
  constructor(props) {
    super();
    var date = props.date;
    var text = props.content;
    var fullWidth = props.fullWidth;
    var status = props.status;
    var widthContent;
    var borderColor;

    switch (fullWidth) {
      case "true":
        widthContent = "100%";
        break;
      case "false":
        widthContent = "375px";
        break;
      default:
      // console.log('lixo');
    }

    if (props.status === "NAO_FINALIZADO") {
      borderColor = "#cf3217";
    } else if (
      props.status === "EM_CURTO_PRAZO" ||
      props.info.costCativo === 0
    ) {
      borderColor = "#FF9C27";
    } else if (props.status === "FINALIZADO") {
      borderColor = "green";
    } else {
      borderColor = "#222";
    }

    this.state = {
      date,
      text,
      borderColor,
      widthContent,
      status,
      info: props.info,
    };
  }

  render() {
    const divStyle = {
      borderLeftColor: this.state.borderColor,
      width: this.state.widthContent,
    };

    const chooseRender = () => {
      if (this.props.status === "NAO_FINALIZADO") {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faClock}
              className="verticalIcon"
              style={{ color: "#cf3217" }}
            />
            <p style={{ fontSize: "10px", marginLeft: "8px" }}>Em projeção</p>
          </div>
        );
      } else if (
        this.props.status === "EM_CURTO_PRAZO" ||
        this.props.info.costCativo === 0
      ) {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faExclamation}
              className="verticalIcon"
              style={{ color: "#FF9C27" }}
            />
            <p style={{ fontSize: "10px", marginLeft: "8px" }}>Em operação</p>
          </div>
        );
      } else if (this.props.status === "FINALIZADO") {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faCheck}
              className="verticalIcon"
              style={{ color: "green" }}
            />
            <p style={{ fontSize: "10px", marginLeft: "8px" }}>Completo</p>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            <FontAwesomeIcon
              icon={faTimes}
              className="verticalIcon"
              style={{ color: "red" }}
            />
            <p style={{ fontSize: "10px", marginLeft: "8px" }}>Erro</p>
          </div>
        );
      }
    };

    return (
      <div>
        <div className="cardContent-container-inside" style={divStyle}>
          <div className="cardContent-header2">
            <p className="cardContent-title" style={{ width: "50%" }}>
              {this.state.date}
            </p>

            <div className="icon-box-card" style={{ width: "50%" }}>
              {chooseRender()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardContent;
