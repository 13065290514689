import React, { useEffect, useState } from "react";
import ConsumeGraph from "../ConsumeGraph/index";
import ConsumeTable from "../ConsumeTable";
import PrevisaoConsumo from "../../Components/Dashboard/PrevisaoConsumo";
import "./style.css";
import { getToken } from "../../Services/auth";
import faradayApi from "../../Services/faradayApi";
import { ProjectionChart } from "../Dashboard/PrevisaoGrafico";
import CardsGrid from "../../Components/CardsGrid/index";
import EconomiaMensalMeasuringPoint from "../../Components/Dashboard/EconomiaMensalMeasuringPoint";
import { DemandaMeasuringPoint } from "../../Components/Dashboard/DemandaMeasuringPoint";
import { AnaliseConsumo } from "../Dashboard/AnaliseConsumo";
import { ConsumoReativoMeasuringPoint } from "../../Components/Dashboard/ConsumoReativoMeasuringPoint";
import { DemandaForaPontaMeasuringPoint } from "../../Components/Dashboard/DemandaForaPontaMeasuringPoint";
import CustoEconomiaUnidadeConsumidora from "../../Components/Dashboard/CustoEconomiaUnidadeConsumidora";
import { PerfilConsumo } from "../Dashboard/PerfilConsumo";
import { ComparativoCativoLivre } from "../Dashboard/ComparativoCativoLivre";
import AnaliseGeracao from "../Dashboard/AnaliseGeracao";
import {
  formatCNPJ,
  convertDate,
  parseRegiao,
  capitalize,
} from "../../utils/utilityFunctions";
import StatusMigration from "../StatusMigration";
import iconAtivo from "./../../Assets/cadAtivoOk.png";
import iconInativo from "./../../Assets/inativoOk.png";
import { StatusMigrationAreaComponent } from "../../pages/ClientDashboard/StatusMigrationAreaComponent";
import { Spinner } from "react-bootstrap";
import { FiBarChart } from "react-icons/fi";
import { isArray } from "highcharts";

import "../CadastrosAtivos/style.css";

export default function ConsumeLine({ monthlyData, profiles }) {
  const [activeMeasuringPoint, setActiveMeasuringPoint] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(" ");
  const [compareSelectedMonth, setCompareSelectedMonth] = useState("Novembro");
  const [measuringPointData, setMeasuringPointData] = useState(null);
  const [monthData, setMonthData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [compare, setCompare] = useState(false);
  const YearSelector = () => {
    return new Date().getFullYear();
  };
  //Gera o mês atual
  const getCurrentMonthIndex = () => {
    const now = new Date();
    return now.getMonth() + 1; // Mês atual (0-11)
  };
  const getMonthNumber = (monthName) => {
    switch (monthName) {
      case "Janeiro":
        return 1;
      case "Fevereiro":
        return 2;
      case "Março":
        return 3;
      case "Abril":
        return 4;
      case "Maio":
        return 5;
      case "Junho":
        return 6;
      case "Julho":
        return 7;
      case "Agosto":
        return 8;
      case "Setembro":
        return 9;
      case "Outubro":
        return 10;
      case "Novembro":
        return 11;
      case "Dezembro":
        return 12;
      default:
        throw new Error("Mês inválido");
    }
  };
  const getMonthName = (monthNumber) => {
    switch (monthNumber) {
      case 1:
        return "Janeiro";
      case 2:
        return "Fevereiro";
      case 3:
        return "Março";
      case 4:
        return "Abril";
      case 5:
        return "Maio";
      case 6:
        return "Junho";
      case 7:
        return "Julho";
      case 8:
        return "Agosto";
      case 9:
        return "Setembro";
      case 10:
        return "Outubro";
      case 11:
        return "Novembro";
      case 12:
        return "Dezembro";
      default:
        throw new Error("Número de mês inválido");
    }
  };
  const [selectedMonthUnical, setSelectedMonthUnical] = useState(
    getMonthName(getCurrentMonthIndex())
  );
  const [selectedYearUnical, setSelectedYearUnical] = useState(YearSelector());
  const [compareSelectedMonthUnical, compareSetSelectedMonthUnical] = useState(
    getMonthName(getCurrentMonthIndex())
  );
  const [compareSelectedYearUnical, compareSetSelectedYearUnical] = useState(
    YearSelector()
  );

  const handleSelectChange = (e) => {
    const [month, year] = e.target.value.split("-");
    setSelectedMonthUnical(month); // Armazena o mês
    setSelectedYearUnical(year); // Armazena o ano
  };
  const handleSelectChangeCompare = (e) => {
    const [month, year] = e.target.value.split("-");
    compareSetSelectedMonthUnical(month); // Armazena o mês
    compareSetSelectedYearUnical(year); // Armazena o ano
  };

  const mesInicio = new Date(measuringPointData?.start_acl).getMonth() + 1;
  const anoInicio = new Date(measuringPointData?.start_acl).getFullYear();

  const [selectedDate, setSelectedDate] = useState("");

  const generateYears = (startYear, endYear) => {
    const years = [];
    if (startYear < 2023) {
      startYear = 2023;
    }
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const yearsArrays = generateYears(anoInicio, YearSelector());

  const generateMonthYearOptions = (years) => {
    const options = [];
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    if (!!measuringPointData?.invoices) {
      measuringPointData.invoices.forEach((invoice) => {
        if (invoice.isMonthRetroactive === false) {
          options.push({
            value: `${months[invoice.month - 1]}-${invoice.year}`,
            label: `${months[invoice.month - 1]} de ${invoice.year}`,
          });
        }
      });
    }

    /*  // yearsArrays.forEach((year) => {
    //   months.forEach((month, index) => {
    //     let monthIndex = getMonthNumber(month); // Obtém o índice do mês

    //     // Limitar os meses até o mês e ano atual (incluindo o mês atual)
    //     if (year === YearSelector() && monthIndex > getCurrentMonthIndex()) {
    //       return;
    //     }

    //     // Se o cliente começou antes de novembro de 2023, mostrar novembro e dezembro de 2023 e todos os meses do ano atual até o mês atual
    //     if (anoInicio < 2023) {
    //       if (year === 2023 && (month === "Novembro" || month === "Dezembro")) {
    //         options.push({
    //           value: `${month}-${year}`,
    //           label: `${month} de ${year}`,
    //         });
    //       } else if (
    //         year === YearSelector() &&
    //         monthIndex <= getCurrentMonthIndex()
    //       ) {
    //         options.push({
    //           value: `${month}-${year}`,
    //           label: `${month} de ${year}`,
    //         });
    //       }
    //     }

    //     // Se o cliente começou em 2023, mostrar desde o início do ano até o mês atual
    //     if (anoInicio === 2023) {
    //       if (year === 2023 && monthIndex >= getMonthNumber("Janeiro")) {
    //         options.push({
    //           value: `${month}-${year}`,
    //           label: `${month} de ${year}`,
    //         });
    //       } else if (
    //         year === YearSelector() &&
    //         monthIndex <= getCurrentMonthIndex()
    //       ) {
    //         options.push({
    //           value: `${month}-${year}`,
    //           label: `${month} de ${year}`,
    //         });
    //       }
    //     }

    //     // Se o cliente começou em 2024, mostrar a partir do mês de início até o mês atual
    //     if (anoInicio >= 2024) {
    //       if (
    //         year === YearSelector() &&
    //         monthIndex >= mesInicio &&
    //         monthIndex <= getCurrentMonthIndex()
    //       ) {
    //         options.push({
    //           value: `${month}-${year}`,
    //           label: `${month} de ${year}`,
    //         });
    //       }
    //     }
    //   });
     }); */

    return options;
  };
  const monthYearOptions = generateMonthYearOptions(anoInicio);
  // Função para gerar todas as combinações de meses e anos

  //Obtém o mês passado para só poder mostrar do mês atual até os anteriores nas opções do select
  const getPreviousMonth = () => {
    const now = new Date();
    const currentMonth = now.getMonth(); // Mês atual (0-11)
    const previousMonthIndex = (currentMonth - 1 + 12) % 12; // Cálculo do índice do mês passado
    return months[previousMonthIndex];
  };

  const getActualMonth = () => {
    const now = new Date();
    const currentMonth = now.getMonth(); // Mês atual (0-11)
    const previousMonthIndex = (currentMonth + 12) % 12; // Cálculo do índice do mês passado
    return months[previousMonthIndex];
  };

  //Obtém o ano

  //Gera os anos a partir do primeiro ano de geração livre com a Merx

  //Pega o primeiro array dos invoices e verifica o primeiro ano para uso posterior do select

  //Gera o array de anos a partir do primeiro ano de geração livre com a Merx

  useEffect(() => {
    setSelectedMonth(getActualMonth());
  }, []);
  useEffect(() => {
    setCompareSelectedMonth(getPreviousMonth());
  }, []);

  //
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    setIsLoading(true);
    if (profiles && activeMeasuringPoint === "") {
      setActiveMeasuringPoint(profiles[0].id);
    }

    const fetchData = async () => {
      if (activeMeasuringPoint) {
        try {
          const response = await faradayApi.get(
            `measuring-point/${activeMeasuringPoint}`
          );

          if (!!response) {
            setMeasuringPointData(response.data);
            let reports = await faradayApi.get(
              `measuring-point/report/${activeMeasuringPoint}`
            );
            setMonthData(reports.data);
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err.message);
        }
      }
    };

    fetchData();
  }, [activeMeasuringPoint, profiles]);

  const changeData = async (e) => {
    const value = e.target.value;

    faradayApi.get(`measuring-point/report/${value}`).then((response) => {
      setMonthData(response.data);
      const chosenProfile = profiles.find((p) => value === p.id);
      // aqui profiles e measuring.id e measuring.name
      setActiveMeasuringPoint(chosenProfile.id); // active measuring é um id
    });
  };

  const lastData = measuringPointData?.invoices?.slice(-1)[0]?.year;
  const mes = lastData?.month;
  const ano = lastData?.year;
  const dataT = measuringPointData?.invoices;

  const filteredDataUnical = dataT?.find(
    (item) =>
      item.year === parseInt(selectedYearUnical) &&
      item.month === getMonthNumber(selectedMonthUnical)
  );

  let dailyConsumoFirst = filteredDataUnical
    ? filteredDataUnical.dailyConsumeReturn
    : undefined;

  let dailyGenerationFirst = filteredDataUnical
    ? filteredDataUnical.dailyGenerationReturn
    : undefined;

  if (typeof dailyConsumoFirst === "string")
    dailyConsumoFirst = JSON.parse(dailyConsumoFirst);

  if (typeof dailyGenerationFirst === "string")
    dailyGenerationFirst = JSON.parse(dailyGenerationFirst);

  let dailyConsumeReturnQtdConsumida = 0;
  let dailyConsumeReturnQtdProjetada = 0;

  let dailyGenerationReturnQtdConsumida = 0;
  let dailyGenerationReturnQtdProjetada = 0;

  dailyConsumoFirst &&
    isArray(dailyConsumoFirst) &&
    dailyConsumoFirst.map((consumo) =>
      consumo.isDayProjected
        ? (dailyConsumeReturnQtdProjetada += consumo.dailyConsume)
        : (dailyConsumeReturnQtdConsumida += consumo.dailyConsume)
    );

  dailyGenerationFirst &&
    isArray(dailyConsumoFirst) &&
    dailyGenerationFirst.map((consumo) =>
      consumo.isDayProjected
        ? (dailyGenerationReturnQtdProjetada += consumo.dailyGeneration)
        : (dailyGenerationReturnQtdConsumida += consumo.dailyGeneration)
    );

  //Filtra os itens para mostrar a comparação no gráfico de acordo com o mes e ano selecionado
  const compareFirstItemDataNew = dataT?.find(
    (item) =>
      item.year === parseInt(compareSelectedYearUnical) &&
      item.month === getMonthNumber(compareSelectedMonthUnical)
  );

  let dailyConsumoSecond =
    compareFirstItemDataNew && compareFirstItemDataNew.dailyConsumeReturn;

  let dailyGenerationSecond =
    compareFirstItemDataNew && compareFirstItemDataNew.dailyGenerationReturn;

  if (typeof dailyConsumoSecond === "string")
    dailyConsumoSecond = JSON.parse(dailyConsumoSecond);

  if (typeof dailyGenerationSecond === "string")
    dailyGenerationSecond = JSON.parse(dailyGenerationSecond);

  let dailyConsumeReturnQtdConsumidaComparada = 0;
  let dailyConsumeReturnQtdProjetadaComparada = 0;

  let dailyGenerationReturnQtdConsumidaComparada = 0;
  let dailyGenerationReturnQtdProjetadaComparada = 0;

  dailyConsumoSecond &&
    isArray(dailyConsumoSecond) &&
    dailyConsumoSecond.map((consumo) =>
      consumo.isDayProjected
        ? (dailyConsumeReturnQtdProjetadaComparada += consumo.dailyConsume)
        : (dailyConsumeReturnQtdConsumidaComparada += consumo.dailyConsume)
    );

  dailyGenerationSecond &&
    isArray(dailyGenerationSecond) &&
    dailyGenerationSecond.map((consumo) =>
      consumo.isDayProjected
        ? (dailyGenerationReturnQtdProjetadaComparada +=
            consumo.dailyGeneration)
        : (dailyGenerationReturnQtdConsumidaComparada +=
            consumo.dailyGeneration)
    );

  // Função para verificar se uma data é um dia útil
  const isWeekday = (date) => {
    const day = date.getDay();
    return day >= 1 && day <= 5; // Segunda a sexta-feira
  };

  // Função para calcular os dias úteis no mês até a data atual
  const getWeekdaysUntilNow = (year, month) => {
    const today = new Date();
    const startOfMonth = new Date(year, month - 1, 1);

    let weekdayCount = 0;

    // Começa a partir do primeiro dia do mês até o dia atual
    for (
      let day = new Date(startOfMonth);
      day <= today;
      day.setDate(day.getDate() + 1)
    ) {
      if (isWeekday(day)) {
        weekdayCount += 1;
      }
    }
    return weekdayCount;
  };

  const date = new Date(ano, mes - 1, 1);

  const orderedProfiles = profiles?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <div className="4-container-master">
      <div className="cadastros-container-section">
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>
            Escolha a unidade consumidora para visualizar suas informações
          </h1>
          <select
            // value={chartValue}
            // onChange={selectedChart}
            value={activeMeasuringPoint}
            name="charts"
            id="charts"
            className="select-chart"
            form="chartsform"
            tabIndex={0}
            onChange={(e) => changeData(e)}
          >
            {orderedProfiles?.map((profile) => (
              <option key={profile.id} value={profile.id}>
                {profile.name.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
      </div>

      {isLoading ? (
        <div
          style={{
            marginTop: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div className="consumeLine-container-right 2">
            <div style={{ width: "100%" }}>
              <div style={{ backgroundColor: "#f5f5f5", padding: "30px 25px" }}>
                <>
                  {measuringPointData ? (
                    monthlyData.length > 0 && (
                      <>
                        <div className="cadastros-container-section">
                          <h1>Informações sobre a unidade consumidora</h1>

                          <div className="mp-info-container">
                            <div className="mp-info-texts">
                              <div className="mp-info-text">
                                {/* <div className="mp-name-info">
                                <h2 className="gray-text">Nome</h2>
                                <span className="bold-text">
                                  {measuringPointData?.name}
                                </span>
                              </div> */}
                                <div className="mp-cnpj-info">
                                  <h2 className="gray-text">CNPJ</h2>
                                  <span className="bold-text">
                                    {formatCNPJ(measuringPointData?.cnpj)}
                                  </span>
                                </div>
                                <div className="mp-code-ccee-info">
                                  <h2 className="gray-text">Código CCEE</h2>
                                  <span className="bold-text">
                                    {measuringPointData?.code_ccee}
                                  </span>
                                </div>
                                <div className="mp-status-info">
                                  <h2 className="gray-text">Status</h2>
                                  <span className="bold-text">
                                    {measuringPointData?.status ===
                                    "EM_ADESAO" ? (
                                      <>
                                        <img
                                          src={iconInativo}
                                          alt={"migração em progresso"}
                                          width={10}
                                          style={{ marginTop: -4 }}
                                        />{" "}
                                        Migração em progresso
                                      </>
                                    ) : measuringPointData?.status ===
                                      "ATIVO" ? (
                                      <>
                                        <img
                                          src={iconAtivo}
                                          alt={"migração completa"}
                                          width={10}
                                          style={{ marginTop: -4 }}
                                        />{" "}
                                        Migração completa
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="mp-info-text">
                                <div className="mp-distribuidora name">
                                  <h2 className="gray-text">Distribuidora</h2>
                                  <span className="bold-text">
                                    {measuringPointData?.distributor_data.name}
                                  </span>
                                </div>
                                <div className="mp-distribuidora group">
                                  <h2 className="gray-text">Grupo</h2>
                                  <span className="bold-text">
                                    {measuringPointData?.distributor_data.group}
                                  </span>
                                </div>
                                <div className="mp-distribuidora ths">
                                  <h2 className="gray-text">THS</h2>
                                  <span className="bold-text">
                                    {capitalize(
                                      measuringPointData?.distributor_data.ths
                                    )}
                                  </span>
                                </div>
                              </div>

                              <div className="mp-info-text">
                                <div className="mp-inicio-livre-info">
                                  <h2 className="gray-text">Início LIVRE</h2>
                                  <span className="bold-text">
                                    {convertDate(measuringPointData?.start_acl)}
                                  </span>
                                </div>
                                <div className="mp-inicio-gestao-info">
                                  <h2 className="gray-text">Início GESTÃO</h2>
                                  <span className="bold-text">
                                    {convertDate(
                                      measuringPointData?.start_date_management
                                    )}
                                  </span>
                                </div>
                                <div className="mp-classe-info">
                                  <h2 className="gray-text">Classe</h2>
                                  <span className="bold-text">
                                    {capitalize(measuringPointData?.class)}
                                  </span>
                                </div>
                                <div className="mp-submercado-info">
                                  <h2 className="gray-text">Submercado</h2>
                                  <span className="bold-text">
                                    {parseRegiao(measuringPointData?.submarket)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {measuringPointData?.status === "EM_ADESAO" && (
                          <StatusMigrationAreaComponent
                            companyDataCheck={measuringPointData?.check}
                            type="measuring-point"
                          />
                        )}
                        {measuringPointData?.status !== "ATIVO" ? (
                          <div className="right-text-center">
                            <h1>
                              Histórico e gráficos serão disponibilizados após a
                              migração ser concluída
                            </h1>
                          </div>
                        ) : (
                          <>
                            {measuringPointData?.status === "EM_ADESAO" && (
                              <StatusMigration
                                check={measuringPointData?.check}
                              />
                            )}

                            <div className="cadastros-container-section">
                              <h1>Histórico</h1>
                              <h2>
                                Acompanhe o desempenho dos últimos 12 meses das
                                unidades unidades consumidoras associadas aos
                                seus perfis
                              </h2>
                            </div>
                            <div className="consumeLine-container-left">
                              <div
                                className="consumeLine-container"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {measuringPointData?.invoices.length > 0 && (
                                  <ConsumeTable
                                    monthlyData={measuringPointData?.invoices}
                                  />
                                )}
                              </div>
                            </div>
                            <hr />
                            <div className="consumeLine-container-right">
                              <>
                                <div
                                  className="container-title-grafico"
                                  style={{ margin: "15px" }}
                                >
                                  <span className="previsaoConsumoParagrafo">
                                    Previsão de consumo para
                                  </span>

                                  <select
                                    value={selectedDate}
                                    name="selectedDate"
                                    id="selectedDate"
                                    className="select-chart"
                                    onChange={handleSelectChange}
                                  >
                                    <option value="">
                                      {selectedMonthUnical && selectedYearUnical
                                        ? selectedMonthUnical +
                                          " de " +
                                          selectedYearUnical
                                        : "Selecione mês e ano"}
                                    </option>
                                    {monthYearOptions
                                      .filter(
                                        (option) =>
                                          option.label !==
                                          selectedMonthUnical +
                                            " de " +
                                            selectedYearUnical
                                      ) // Filtra o valor selecionado
                                      .map((option, index) => (
                                        <option
                                          key={index}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                  </select>

                                  <button
                                    type="button"
                                    class="btn btn-primary btn-lg compareBtn"
                                    style={{ marginLeft: 10 }}
                                    onClick={(e) => setCompare(!compare)}
                                  >
                                    Compare
                                  </button>

                                  {compare && (
                                    <div className="compare">
                                      <select
                                        value={selectedDate}
                                        name="selectedDate"
                                        id="selectedDate"
                                        className="select-chart"
                                        onChange={handleSelectChangeCompare}
                                      >
                                        <option value="">
                                          {compareSelectedMonthUnical &&
                                          compareSelectedYearUnical
                                            ? compareSelectedMonthUnical +
                                              " de " +
                                              compareSelectedYearUnical
                                            : "Selecione mês e ano"}
                                        </option>
                                        {monthYearOptions
                                          .filter(
                                            (option) =>
                                              option.label !==
                                              compareSelectedMonthUnical +
                                                " de " +
                                                compareSelectedYearUnical
                                          ) // Filtra o valor selecionado
                                          .map((option, index) => (
                                            <option
                                              key={index}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  )}
                                </div>
                                {/* Verifica se o mês escolhido é menor que o atual */}
                                {filteredDataUnical &&
                                filteredDataUnical.dailyConsumeReturn ? (
                                  //Caso positivo: Mostra o gráfico
                                  <ProjectionChart
                                    data={{
                                      compare: compare, // ou false, dependendo do caso
                                      dailyConsumeReturn:
                                        filteredDataUnical.dailyConsumeReturn,
                                      // Certifique-se de que isso é uma string JSON válida
                                      dailyGenerationReturn:
                                        filteredDataUnical.dailyGenerationReturn,
                                      compareDailyConsumeReturn: compareFirstItemDataNew?.dailyConsumeReturn
                                        ? compareFirstItemDataNew.dailyConsumeReturn
                                        : undefined, // Se não houver dados, enviar um array vazio em formato JSON,
                                      compareDailyGenerationReturn: compareFirstItemDataNew?.dailyGenerationReturn
                                        ? compareFirstItemDataNew.dailyGenerationReturn
                                        : undefined, // Se não houver dados, enviar um array vazio em formato JSON,
                                    }}
                                  />
                                ) : (
                                  //Caso negativo: Vai para as outras opções
                                  <div className="right-text-center">
                                    {/*Verifica se o ano é menor que 2023 e se o mês é menor do que novembro, pois os dados de medição so foram dados a partir desse data e também verifica se ainda estamos no 7 dia util para mostrar a 2ª mesnagem*/}

                                    {getMonthNumber(selectedMonth) <=
                                      getCurrentMonthIndex() + 1 &&
                                    getWeekdaysUntilNow(
                                      selectedYearUnical,
                                      getMonthNumber(selectedMonth)
                                    ) >= 6 ? (
                                      <h1
                                        style={{ fontSize: 19, marginLeft: 5 }}
                                      >
                                        A medição está com horas faltantes ou
                                        ainda não foi disponibilizada para este
                                        mês
                                      </h1>
                                    ) : (
                                      <h1 style={{ fontSize: 19 }}>
                                        A previsão será disponibilizada após o
                                        7° dia útil do mês
                                      </h1>
                                    )}
                                  </div>
                                )}
                              </>
                            </div>

                            {dailyGenerationFirst && dailyConsumoFirst && (
                              <div className="dadosConsumo">
                                <h2>Dados de consumo e projeção</h2>
                                <div className="Infos">
                                  {(dailyGenerationReturnQtdConsumida > 0 ||
                                    dailyGenerationReturnQtdProjetada > 0) && (
                                    <>
                                      <div>
                                        <p>Geração não projetada</p>
                                        <p>
                                          {dailyGenerationReturnQtdConsumida
                                            .toFixed(2)
                                            .replace(".", ",")}{" "}
                                          MWh
                                        </p>
                                      </div>
                                      <div>
                                        <p>Geração projetada</p>
                                        <p>
                                          {dailyGenerationReturnQtdProjetada
                                            .toFixed(2)
                                            .replace(".", ",")}{" "}
                                          MWh
                                        </p>
                                      </div>
                                      <div>
                                        <p>Geração Total</p>
                                        <p>
                                          {(
                                            dailyGenerationReturnQtdProjetada +
                                            dailyGenerationReturnQtdConsumida
                                          )
                                            .toFixed(2)
                                            .replace(".", ",")}{" "}
                                          MWh
                                        </p>
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    <p>Consumo não projetado</p>
                                    <p>
                                      {dailyConsumeReturnQtdConsumida
                                        .toFixed(2)
                                        .replace(".", ",")}{" "}
                                      MWh
                                    </p>
                                  </div>
                                  <div>
                                    <p>Consumo projetado</p>
                                    <p>
                                      {dailyConsumeReturnQtdProjetada
                                        .toFixed(2)
                                        .replace(".", ",")}{" "}
                                      MWh
                                    </p>
                                  </div>
                                  <div>
                                    <p>Consumo Total</p>
                                    <p>
                                      {(
                                        dailyConsumeReturnQtdProjetada +
                                        dailyConsumeReturnQtdConsumida
                                      )
                                        .toFixed(2)
                                        .replace(".", ",")}{" "}
                                      MWh
                                    </p>
                                  </div>
                                </div>

                                <span className="line"></span>

                                {/* Verificação da variável `compare` */}
                                {compare && (
                                  <div className="Infos comparacao">
                                    {(dailyGenerationReturnQtdConsumidaComparada >
                                      0 ||
                                      dailyGenerationReturnQtdProjetadaComparada >
                                        0) && (
                                      <>
                                        <div>
                                          <p>Geração não projetada</p>
                                          <p>(comparada)</p>
                                          <p>
                                            {dailyGenerationReturnQtdConsumidaComparada
                                              .toFixed(2)
                                              .replace(".", ",")}{" "}
                                            MWh
                                          </p>
                                        </div>
                                        <div>
                                          <p>Geração projetada</p>
                                          <p>(comparada)</p>
                                          <p>
                                            {dailyGenerationReturnQtdProjetadaComparada
                                              .toFixed(2)
                                              .replace(".", ",")}{" "}
                                            MWh
                                          </p>
                                        </div>
                                        <div>
                                          <p>Geração Total</p>
                                          <p>(comparada)</p>
                                          <p>
                                            {(
                                              dailyGenerationReturnQtdProjetadaComparada +
                                              dailyGenerationReturnQtdConsumidaComparada
                                            )
                                              .toFixed(2)
                                              .replace(".", ",")}{" "}
                                            MWh
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    <div>
                                      <p>Consumo não projetado </p>
                                      <p>(comparada)</p>
                                      <p>
                                        {dailyConsumeReturnQtdConsumidaComparada
                                          .toFixed(2)
                                          .replace(".", ",")}{" "}
                                        MWh
                                      </p>
                                    </div>
                                    <div>
                                      <p>Consumo projetado</p>
                                      <p>(comparada)</p>
                                      <p>
                                        {dailyConsumeReturnQtdProjetadaComparada
                                          .toFixed(2)
                                          .replace(".", ",")}{" "}
                                        MWh
                                      </p>
                                    </div>
                                    <div>
                                      <p>Consumo Total</p>
                                      <p>(comparada)</p>
                                      <p>
                                        {(
                                          dailyConsumeReturnQtdProjetadaComparada +
                                          dailyConsumeReturnQtdConsumidaComparada
                                        )
                                          .toFixed(2)
                                          .replace(".", ",")}{" "}
                                        MWh
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="cadastros-container-section">
                              <h1>Detalhamento</h1>
                              <h2>
                                Acompanhe o desempenho econômico, relatório de
                                faturas e contribuição associativa CCEE da
                                unidade consumidora{" "}
                                {measuringPointData.name.toUpperCase()}
                              </h2>
                            </div>

                            {/* <div style={{ position: "relative", top: "65px" }}>
                        <span className="clientDashboard-text">
                          Custo mensal acumulado
                        </span>
                      </div> */}

                            {
                              <CustoEconomiaUnidadeConsumidora
                                totalCativo={measuringPointData.totalCostCativo}
                                totalLivre={measuringPointData.totalCostLivre}
                                economy={measuringPointData.accumulatedEconomy}
                              />
                            }

                            <hr />
                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Análise de consumo
                              </span>
                            </div>
                            <div className="clientDashboard-container">
                              <AnaliseConsumo
                                monthData={measuringPointData?.invoices}
                              />
                            </div>

                            <hr />

                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Comparativo Cativo X Livre
                              </span>
                            </div>
                            <div className="clientDashboard-container">
                              <ComparativoCativoLivre
                                monthData={measuringPointData?.invoices}
                              />
                            </div>

                            <hr />

                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Economia mensal
                              </span>
                            </div>

                            <div className="clientDashboard-container">
                              <EconomiaMensalMeasuringPoint
                                monthData={measuringPointData?.invoices}
                              />
                            </div>

                            <hr />

                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Análise temporal da demanda fora ponta
                              </span>
                            </div>

                            <div className="clientDashboard-container">
                              <DemandaForaPontaMeasuringPoint
                                monthData={measuringPointData?.invoices}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  color: "red",
                                  margin: "5px 0 0",
                                }}
                              >
                                OBS: Valores vazios significam que não recebemos
                                a fatura do mês referente.
                              </span>
                            </div>
                            <hr />

                            {measuringPointData?.invoices.every(
                              (invoice) => invoice.contractedPeakDemand === 0
                            ) ? null : (
                              <>
                                <div className="clientDashboard-container">
                                  <DemandaMeasuringPoint
                                    monthData={measuringPointData?.invoices}
                                  />
                                </div>
                              </>
                            )}

                            <hr />

                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Análise temporal do consumo reativo
                              </span>
                            </div>

                            <div className="clientDashboard-container">
                              <ConsumoReativoMeasuringPoint
                                monthData={measuringPointData?.invoices}
                              />
                            </div>

                            <hr />

                            <div
                              style={{
                                margin: "32px 0 16px 0",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="clientDashboard-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Perfil de consumo
                              </span>
                            </div>
                            <div className="clientDashboard-container">
                              <PerfilConsumo
                                monthData={measuringPointData?.invoices}
                              />
                            </div>
                            <hr />

                            <div className="clientDashboard-container">
                              <AnaliseGeracao
                                monthlyData={measuringPointData?.invoices}
                              />
                            </div>

                            <span className="clientDashboard-text">
                              Faturas mensais e contribuição associativa CCEE
                            </span>

                            <CardsGrid
                              monthData={measuringPointData?.invoices}
                            />
                          </>
                        )}
                      </>
                    )
                  ) : (
                    <div className="right-text-center">
                      <h1>
                        Histórico e gráficos serão disponibilizados após a
                        migração ser concluída
                      </h1>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
