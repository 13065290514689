import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";
import { useState,useEffect } from "react";

export function DemandaMeasuringPoint({ monthData }: { monthData: any[] }) {
  const monthsData = monthData;

  const contractedPeakDemand = monthsData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.contractedPeakDemand;
      return [x, y];
    });

  const consumedPeakDemand = monthsData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.consumedPeakDemand;
      return [x, y];
    });

  const months = contractedPeakDemand.map((e) => {
    return e[0];
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: { type: "line" },
    title: {
      text: "",
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    xAxis: {
      // title: {
      //   text: "Mês",
      // },
      categories: months,
    },

    // yAxis: {
    //   title: {
    //     text: "Demanda (kW)",
    //   },
    //   labels: {
    //     format: "{value} kW",
    //   },
    // },
    yAxis: {
      labels: {
        format: "{value:,.0f} kW",
      },
      gridLineWidth: 0.5,
      gridLineColor: "#838f7f",
      zIndex: -100,
      title: {
        text: "",
      },
    },

    plotOptions: {
      column: {
        pointWidth: getPointWidth(), // Ajuste este valor conforme necessário
      },
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      shared: true,
      // headerFormat: "<b>{point.x}</b><br/>",
    },

    series: [
      {
        name: "Demanda contratada ponta",
        data: contractedPeakDemand,
        type: "column",
        color: "#007bff",
        zones: [
          {
            color: "#007bff",
          },
        ],
        borderWidth: 0,
        tooltip: {
          valueSuffix: " kW",
        },
        pointPlacement: -0.1,
        dataLabels: {
          enabled: getBarLegend(),
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.0f}", // Exibe o valor do ponto de dados
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
      {
        name: "Demanda utilizada ponta",
        data: consumedPeakDemand,
        type: "column",
        color: "#D75413",
        zones: [
          {
            color: "#D75413",
          },
        ],
        borderWidth: 0,
        tooltip: {
          valueSuffix: " kW",
        },
        pointPlacement: 0,
        dataLabels: {
          enabled: getBarLegend(),
          inside: false, // Define se os rótulos ficarão dentro ou fora das colunas
          format: "{point.y:,.0f}", // Exibe o valor do ponto de dados
          zIndex: -10,
          style: {
            fontWeight: "bold", // Especifique o peso da fonte (por exemplo, normal, bold, bolder, lighter, 100, 200, ... 900)
            fontSize: "14px", // Especifique o tamanho da fonte
            textOutline: "none",
          },
        },
      },
    ],
  };

  const [pointWidth, setPointWidth] = useState(getPointWidth());
  const [barLegend, setBarLegend] = useState((getBarLegend()));
  function getPointWidth() {
    if(window.innerWidth >= 950){
      return 40;
    }
    else if(window.innerWidth <= 950 && window.innerWidth > 805 ){
      return 30;
    }
    else if (window.innerWidth <= 805 && window.innerWidth > 640){
      return 20;
    }
    else if (window.innerWidth <= 640 && window.innerWidth > 510 ){
      return 15;
    }
    else if (window.innerWidth <= 510 && window.innerWidth > 400){
      return 10;
    }
    else{
      return 5;
    }
  }

  function getBarLegend() {
    return window.innerWidth < 920 ? false : true;
  }
  useEffect(() => {
    const handleResize = () => {
      setPointWidth(getPointWidth());
      setBarLegend(getBarLegend());
    };

    // Adiciona o ouvinte de evento de redimensionamento
    window.addEventListener('resize', handleResize);

    // Executa a função imediatamente para definir o valor inicial
    handleResize();

    // Remove o ouvinte de evento quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <>
      {contractedPeakDemand.some((item) => !!item[1] && item[1] !== 0) && (
        <>
          <div
            style={{
              margin: "32px 0 16px 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <span
              className="clientDashboard-text"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              Análise temporal da demanda ponta
            </span>
          </div>

          <ContentCard>
            <GraphContainer>
              <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            </GraphContainer>
          </ContentCard>
        </>
      )}
    </>
  );
}
