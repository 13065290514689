import React from "react";
import logoMerxVerde from "../../Assets/logoMerxVerde.svg";
import { useState, useEffect } from "react";
import closedEye from "../../Assets/closedeye.svg";
import openEye from "../../Assets/openEye.svg";
import iconPassword from "../../Assets/icoPassword.svg";
import awaitIcon from "../../Assets/await.svg";
import arrowBackHome from "../../Assets/arrowBackHome.svg";
import { useLocation, useParams } from "react-router-dom";
import "./style.css";
import faradayApi from "../../Services/faradayApi";

export default function RecuperarSenha(second) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [hideEye, setHideEye] = useState("");
  const [hideEyeConfirm, setHideEyeConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const tokenParam = params.get("token");

    if (tokenParam) {
      setToken(tokenParam);
    }
  }, [location.search]);

  const handleSendNewPassword = async () => {
    setLoading(true);
    if (
      password.length < 10 ||
      confirmPassword < 10 ||
      password !== confirmPassword
    ) {
      setLoading(false);
      return;
    } else {
      setLoading(true);
      await faradayApi
        .post("auth/new-password", { password, token })
        .then((response) => {
          setMessage("Senha alterada com sucesso!");
          setLoading(false);
        })
        .catch((response) => {
          setMessage(response.response?.data.message);
          setLoading(false);
        });
      setLoading(false);
    }
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length > 0 && e.target.value.length <= 9) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };
  const handleChangeNewPassword = (e) => {
    setconfirmPassword(e.target.value);
  };

  const showAndHidePassword = () => {
    setHideEye(!hideEye);
  };
  const showAndHidePasswordConfirm = () => {
    setHideEyeConfirm(!hideEyeConfirm);
  };
  return (
    <div className="mainComponent">
      <section className={`ladoEsquerdo`}>
        <img src={logoMerxVerde}></img>
        <div className="meio">
          <h1>Conecte-se e impulsione seus negócios</h1>
          <p>
            Acesse sua conta e aproveite todas as vantagens de ser um parceiro
            MERX. Faça simulações no Mercado Livre de Energia, pesquise
            oportunidades e receba atualizações sobre os clientes que você
            indicou.
          </p>
        </div>
        <p>
          <b>⭐5.0 Confiança </b>de mais de 1.500 integradores e parceiros.
        </p>
      </section>
      <section className={`ladoDireito recuperarSenhaLado`}>
        <div className="login">
          <h3>Troca de senha</h3>
          <p className="insertLogin">Insira seus novos dados</p>

          <section className="loginInputs">
            <label for="senha" className="senhaLabel">
              <p>Nova Senha</p>
            </label>
            <div className="inputContainer">
              <img src={iconPassword} alt="icon password" />
              <input
                type={hideEye ? "text" : "password"}
                placeholder="Sua senha"
                value={password}
                onChange={(e) => handleChangePassword(e)}
              ></input>
              <img
                src={openEye}
                alt="olhosAbertos "
                className={`eyeIcon enable${hideEye}`}
                onClick={showAndHidePassword}
              />
              <img
                src={closedEye}
                alt="olhosFechados"
                className={`eyeIcon enable${!hideEye}`}
                onClick={showAndHidePassword}
              />
            </div>

            {passwordError && (
              <p className="error">As senha tem que possuir 10 caracteres</p>
            )}

            <label for="senha" className="senhaLabel">
              <p>Confirme a Nova senha</p>
            </label>
            <div className="inputContainer">
              <img src={iconPassword} alt="icon password" />
              <input
                type={hideEyeConfirm ? "text" : "password"}
                placeholder="Sua senha"
                value={confirmPassword}
                onChange={(e) => handleChangeNewPassword(e)}
              ></input>
              <img
                src={openEye}
                alt="olhosAbertos "
                className={`eyeIcon enable${hideEyeConfirm}`}
                onClick={showAndHidePasswordConfirm}
              />
              <img
                src={closedEye}
                alt="olhosFechados"
                className={`eyeIcon enable${!hideEyeConfirm}`}
                onClick={showAndHidePasswordConfirm}
              />
            </div>
            {password !== confirmPassword && confirmPassword.length > 0 && (
              <p className="error">As senhas precisam ser iguais</p>
            )}
          </section>
          {message && (
            <p
              className={`msg handleSucess${
                message == "Senha alterada com sucesso!"
              }`}
            >
              {message}
            </p>
          )}
          <button
            className={`entrar loading${loading}`}
            onClick={(e) => handleSendNewPassword()}
            type="submit"
            disabled={loading || message == "Senha alterada com sucesso!"}
          >
            {loading ? (
              <img src={awaitIcon} alt="loadingIcon" className="awaitIcon" />
            ) : (
              "Trocar senha"
            )}
          </button>
          <a href="/login" className="backHome">
            <img src={arrowBackHome} alt="icone de voltar para home"></img>
            <button>Página de login</button>
          </a>
        </div>
        <p className="direitos">© 2024 MERX energia. All Rights Reserved.</p>
      </section>
    </div>
  );
}
