import React, { Component } from "react";
import { DocumentLink } from "./DocumentLink";

class DownFiles extends Component {
  constructor(props) {
    super();

    this.state = {
      CNPJ: props.info.CNPJ,
      Mes: props.info.Mes,
      Ano: props.info.Ano,
      RelatorioDesempenho: "",
      NotaDeEnergia: "",
      NotaDeGestao: "",
      FaturaDistribuidora: "",
    };
  }

  render() {
    const relatorioDesempenho = this.props.info.reports.filter(
      (obj) => obj.type === "RELATORIO_DESEMPENHO"
    )[0];
    const notaEnergiaCompra = this.props.info.reports.filter(
      (obj) => obj.type === "NOTA_ENERGIA_COMPRA"
    );
    const analiseFaturaDistribuidora = this.props.info.reports.filter(
      (obj) => obj.type === "ANALISE_FATURA_DISTRIBUIDORA"
    )[0];
    const faturaDistribuidora = this.props.info.reports.filter(
      (obj) => obj.type === "FATURA_DISTRIBUIDORA"
    )[0];
    // const medicao = this.props.info.reports.filter(
    //   (obj) =>obj.type === "MEDICAO"
    // )[0];
    const planilhaMedicao = this.props.info.reports.filter(
      (obj) => obj.type === "PLANILHA_MEDICAO"
    )[0];
    return (
      <div style={{ width: "100%", fontFamily: "Encode Sans", padding: "8px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.2)",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            color: "#000",
            marginTop: "20px",
          }}
        >
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: "10px auto",
              borderBottom: "2px solid #EBEFF2",
              width: "100%",
              padding: "15px 0",
            }}
          >
            Documentos Importantes
          </h2>

          <DocumentLink
            title={"Relatório de desempenho"}
            url={relatorioDesempenho ? relatorioDesempenho : null}
          />

          <DocumentLink
            title={"Análise da fatura da distribuidora"}
            url={analiseFaturaDistribuidora ? analiseFaturaDistribuidora : null}
          />
          <DocumentLink
            title={"Fatura da distribuidora"}
            url={faturaDistribuidora ? faturaDistribuidora : null}
          />
          {/* <DocumentLink
            title={"Medição"}
            url={medicao ? medicao : null}
          /> */}
          <DocumentLink
            title={"Arquivo de medição"}
            url={planilhaMedicao ? planilhaMedicao : null}
          />

          {notaEnergiaCompra.length > 0 ? (
            notaEnergiaCompra.map((nota, index) => (
              <DocumentLink
                key={index}
                title={"Nota de energia " + (index + 1)}
                url={nota}
              />
            ))
          ) : (
            <DocumentLink title={"Nota de energia"} url={null} />
          )}

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <p style={{ color: "gray", fontSize: "12px" }}>
              Para fazer o download do arquivo, clique no ícone correspondente.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default DownFiles;
