import React, { useState } from "react";
import "./style.css";
import ClientesIndicados from "../../Components/ClientesIndicados/index";
interface Company {
  initials: string;
  migration: boolean;
  cnpj: string;
  migrationData?: Date;
}

interface DataProps {
  companies: Company[];
}

export default function CompanyInfos({ companies }: DataProps) {
  const [valueInput, setValueInput] = useState<string>("");

  const filteredCompanies = companies.filter((company) => {
    if (valueInput) {
      return company.initials.toLowerCase().includes(valueInput.toLowerCase());
    } else {
      return companies;
    }
  }).sort((a,b)=>{
    return a.initials.toLowerCase().localeCompare(b.initials.toLowerCase());
  });

  return (
    <div className="infosEmpresas" style={{ paddingBottom: "10px" }}>
      {" "}
      <h2 className="pesquisaH2">Pesquise a empresa desejada</h2>
      <p>Busque e veja detalhes dos clientes</p>
      <input
        type="text"
        placeholder="Nome da empresa"
        className="procuraEmpresa"
        value={valueInput}
        onChange={(e) => setValueInput(e.target.value)}
      ></input>
      <ClientesIndicados
        Affiliates={filteredCompanies}
        economiaTotal={0}
        qtdAffiliates={0}
      />
    </div>
  );
}
