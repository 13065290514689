import React from "react";
import NavBar from "../../Components/Navbar/index";
import Footer from "../../Components/Footer";

const TermsOfUsePage = () => {
  return (
    <div>
      <NavBar changeColor="true" />
      PolicyPrivacyPage
      <Footer />
    </div>
  );
};

export default TermsOfUsePage;
