import React from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { economyVPL } from "../../pages/ClientDashboard/interfaces";

function SimulatorVPLAccumulated({
  data,
  simulationType,
}: {
  data: economyVPL;
  simulationType: string;
}) {
  const simulationCategories = () => {
    if (simulationType === "ML")
      return [
        "Ano 1",
        "Ano 2",
        "Ano 3",
        "Ano 4",
        "Ano 5",
        "Ano 6",
        "Ano 7",
        "Ano 8",
        "Ano 9",
        "Ano 10",
      ];
    else
      return [
        "Ano 1",
        "Ano 2",
        "Ano 3",
        "Ano 4",
        "Ano 5",
        "Ano 6",
        "Ano 7",
        "Ano 8",
        "Ano 9",
        "Ano 10",
      ];
  };

  const simulationArray = () => {
    if (simulationType === "ML") return data.economyAccumulated;
    else return data.vplAnual.slice(1);
  };

  const chartOptions = {
    chart: { type: "column", backgroundColor: "#FAFAFA", borderRadius: "8px" },
    title: { text: "", align: "left" },
    xAxis: {
      categories: simulationCategories(),
    },
    yAxis: { title: { text: "Valor em R$" }, gridLineWidth: 0 },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: "0%",
      },
    },
    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        return `<b>${this.x}<br>${this.y.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}</b>`;
      },
    },
    series: {
      label: { enabled: true },
      data: simulationArray(),
      zones: [
        {
          value: 0,
          color: "#f91010",
        },
        {
          color: "#1fa824",
        },
      ],
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}

export default SimulatorVPLAccumulated;
