import React from "react";
import styles from "./style.module.css";
import Image from "../../Assets/freeMarketAdvantage.png";
import Image1 from "../../Assets/freeMarketAdvantage1.png";
import Image2 from "../../Assets/freeMarketAdvantage2.png";
export default function Advantages() {
  const advantages = [
    {
      title: "Sustentabilidade e Economia",
      text:
        "Além de reduzir custos, o mercado livre de energia incentiva o uso de fontes renováveis, como solar e eólica.",
      image: Image,
      alt: "Imagem de uma placa solar",
    },
    {
      title: "Liberdade de Escolha",
      text:
        "No mercado livre de energia, consumidores podem escolher seu fornecedor, negociando preços e condições que atendam melhor às suas necessidades.",
      image: Image1,
      alt: "Icone de um raio",
    },
    {
      title: "Previsibilidade e Planejamento",
      text:
        "Com contratos personalizados e preços previsíveis, o mercado livre oferece uma vantagem para quem busca estabilidade no orçamento. ",
      image: Image2,
      alt: "Icone de uma geradora de energia eólica",
    },
  ];

  return (
    <section className={styles.allContent} id="advantages">
      <div className={styles.content}>
        <h2>Vantagens do mercado livre</h2>
        <div className={styles.advantages}>
          {advantages.map((advantage) => (
            <div className={styles.advantage}>
              <img src={advantage.image} alt={advantage.alt}></img>
              <h3>{advantage.title}</h3>
              <p>{advantage.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
