import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  ContentCard,
  GraphContainer,
} from "../../pages/ClientDashboard/styled";
import { shortMesesExt } from "../../utils/utilityFunctions";

export function EvolucaoConsumoMeasuringPoint({
  monthData,
}: {
  monthData: any[];
}) {
  const dataConsume = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.relativeConsume;
      return [x, y];
    });

  const dataContractedEnergy = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = e.volumeToInvoice;
      return [x, y];
    });

  const dataBallast = monthData
    .filter(
      (item) =>
        item.status === "FINALIZADO" && item.isMonthRetroactive === false
    )
    .slice(-12)
    .map((e) => {
      const date = new Date(e.year, e.month - 1);
      const x = shortMesesExt[date.getMonth()] + " " + date.getFullYear();
      const y = +(e.volumeToInvoice - e.relativeConsume).toFixed(2);
      return [x, y];
    });

  const months = dataConsume.map((e) => {
    return e[0];
  });

  Highcharts.setOptions({
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
  });

  const chartOptions = {
    chart: {
      type: "column",
    },

    title: {
      text: "",
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },

    credits: {
      enabled: false,
    },

    xAxis: {
      title: {
        text: "Mês",
      },
      categories: months,
    },

    yAxis: {
      title: {
        text: "Energia (MWh)",
      },
      labels: {
        format: "{value} MWh",
      },
    },

    tooltip: {
      shared: true,
      headerFormat: "<b>{point.x}</b><br/>",
    },

    series: [
      {
        name: "Contrato",
        data: dataContractedEnergy,
        color: "#444",
        zones: [
          {
            color: "#444",
          },
        ],
        tooltip: {
          valueSuffix: " MWh",
        },
      },
      {
        name: "Consumo",
        data: dataConsume,
        color: "#1898E0",
        zones: [
          {
            color: "#1898E0",
          },
        ],
        tooltip: {
          valueSuffix: " MWh",
        },
      },
      {
        name: "Ajuste",
        data: dataBallast,
        color: "#02C028",
        zones: [
          {
            value: 0,
            color: "#f91010",
          },
          {
            color: "#1fa824",
          },
        ],
        tooltip: {
          valueSuffix: " MWh",
        },
      },
    ],
  };

  return (
    <ContentCard>
      <GraphContainer>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </GraphContainer>
    </ContentCard>
  );
}
